import React from 'react';
import { connect } from 'react-redux';
import '../styles/common.scss';
import invalidLink from '../../../assets/images/invalid-link.png';

const ErrorPage = ({ brandName }) => (
  <React.Fragment>
    <div>
      <div class="wrapper">
        <div class="container text-center">
          <div class="invalid-img">
            <img class="img-fluid" src={invalidLink} />
          </div>
          <div class="page-content">
            <span class="content-title">The link you
            clicked on isn't valid anymore.</span>
            <div class="description-container">
              <p class="description mb-0">
                You may have been removed from the topic
                or responses are no longer being collected for this topic.
                If you believe
                this to be incorrect, please
                contact your {brandName} administrator.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </React.Fragment>
);
const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
});


export default connect(
  mapStateToProps,
  null,
)(ErrorPage);
