import React from 'react';
import { connect } from 'react-redux';
import Cryptr from 'cryptr';
import YourSatisfactionInactive from './components/YourSatisfactionInactive';
import SatisfactionComparesInActive from './components/SatisfactionComparesInActive';
import SatisfactionComparesActive from './components/SatisfactionComparesActive';
import YourSatisfactionActive from './components/YourSatisfactionActive';
import { FooterContent } from '../../../ui-components/PageContent';
import SideDrawer from './components/SideDrawer';
import OverlayBackdrop from '../../common/containers/OverlayBackdrop/OverlayBackdrop';
import { showHideOverlay } from '../../common/overlay/actions/overlay';
import { MobileViewLoader } from '../../../ui-components/Loader';
import HamburgerSvg from './components/svg/HamburgerSvg';
import RenderIf from '../../../ui-components/Common';
import {
  setProjectInfo,
  getProjectDashboardData,
  isProjectsHasUnasweredQuestions,
} from '../../project/actions/project';
import accountConstant from '../../account/actions/constant';
import commonConstant from '../../../constants/common';
import notify from '../../common/notification/actions';
import './components/persona-dashboard.css';
import InsightDisabledSvg from './components/svg/InsightDisabledSvg';

const cryptr = new Cryptr(__CONFIG__.encryptSecret);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function replaceAll(map, str) {
  map.forEach((url) => {
    str = str.replace(url, `<a href='${url}'>${url}</a>`);
  });
  return str;
}

class PersonalDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reponseAvailable: true,
      // isDrawerOpen: false,
      projects: [],
      isLoading: true,
      showInsightScreen: false,
      isEmail: false,
      emailText: '',
      overAllScoreColor: '#fff',
      userScoreColor: '#fff',
      userPerformanceByScore: '',
      overAllScore: 0,
      userScore: 0,
      userScorePosition: 0,
      overallSameMargin: 0,
      userScoreLeftPercentage: 0,
      overAllRightRadius: 0,
      userSameMargin: 0,
      userScoreWidth: 5,
      overallConfidenceTextStyle: {},
      userConfidenceTextStyle: {},
      domainConfidenceSummary: [],
      showAllDomains: true,
      trendData: [],
      trendDataSet: [],
      trendStartDate: '',
      trendDifference: 0,
      trendPerformancePositive: true,
      showTrendDifference: false,
      appActive: true,
      selectedProjectName: '',
      selectedProjectMessage: '',
      selectedProjectMeasuring: '',
      projectColor: '',
      showTooltip: false,
      isSwiperScroll: true,
      isShowSwiperPagination: true,
      expandContentIndex: null,
      isShowSelectedProjectDashboardMobile: true,
      isAuthenticated: false,
      periodLength: 0,
      showTrendLines: false,
      isProjectsAvailable: true,
    };
  }

  componentDidMount() {
    if (this.props.isDrawerListFetched && this.props.isDrawerListFetched) {
      this.getProjectDashboardData(this.props.selectedProject);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isDrawerListFetched !== this.props.isDrawerListFetched
      && this.props.isDrawerListFetched
    ) {
      this.getProjectDashboardData(this.props.selectedProject);
    }
  }


  getProjectDashboardData = (selectedProject) => {
        if (this.props.sideDrawerProjects && this.props.sideDrawerProjects.length) {
          this.setState({
            isLoading: true,
            isProjectsAvailable: true,
          });
          const selectedProjectData = this.props.sideDrawerProjects.findIndex(data => (
            parseInt(selectedProject, 10) === data.ProjectId
          ));
          if (selectedProjectData === -1) {
            this.props.history.push(accountConstant.homePage);
          }

          this.props.isProjectsHasUnasweredQuestions(selectedProject)
            .then(async (response) => {
              if (response.data === true) {
                this.setState({
                  isLoading: false,
                });
                const projectId = cryptr.encrypt(selectedProject);
                this.props.history.push(`/home-screen/${projectId}`);
              } else {
                 this.props.getProjectDashboardData(selectedProject)
                  .then((dashboardResponse) => {
                    if (dashboardResponse && dashboardResponse.data) {
                      this.setState({
                        isLoading: false,
                      });
                      this.onDataReceive(dashboardResponse.data);
                    } else if (dashboardResponse
                       && dashboardResponse.response && dashboardResponse.response.status === 401) {
                      this.props.show401Error();
                    }
                  })
                  .catch(() => {
                    this.setState({ isLoading: false });
                  });
              }
            })
            .catch(() => {
              this.setState({ isLoading: false });
            });
        } else {
          this.setState({ isLoading: false, isProjectsAvailable: false });
        }
  }

  setConfidenceBarAndTextStyles(userScore, overAllScore) {
    const overallConfidenceTextStyle = this.getOverAllConfidenceTextStyle(
      overAllScore,
    );
    const userConfidenceTextStyle = this.getUserConfidenceTextStyle(userScore);
    this.setState({
      overAllScore,
      userScore,
      overallConfidenceTextStyle,
      userConfidenceTextStyle,
      userScoreLeftPercentage: userScore === 100 ? userScore - 2 : userScore,
      overAllRightRadius: overAllScore === 100 ? 7 : 0,
      userScoreRightRadius: userScore === 100 ? 7 : 0,
      userZeroMargin: userScore === 0 ? 5 : 0,
      userScoreLeftRadius: userScore === 0 ? 7 : 0,
      userScorePosition: userScore === 100 ? 199 / 2 : userScore,
      userScoreWidth: userScore === 100 ? 6 : 5,
      overallSameMargin: userScore === overAllScore ? -6 : -9,
      userSameMargin: userScore === overAllScore ? -3 : -5,
      isSwiperScroll: userScore >= 0,
      isShowSwiperPagination: userScore >= 0,
    });
  }

  getOverAllConfidenceTextStyle(overAllScore) {
    const marginLeft = overAllScore < 60 ? `${overAllScore + 5}%` : 'auto';
    const marginRight = overAllScore < 60 ? null : `${100 - overAllScore + 5}%`;
    const overallConfidenceTextStyle = {
      marginLeft,
      marginRight,
    };
    return overallConfidenceTextStyle;
  }

  getUserConfidenceTextStyle(userScore) {
    const right = userScore > 60 ? `${100 - userScore + 5}%` : null;
    const left = userScore > 60 ? null : `${userScore + 5}%`;
    const userConfidenceTextStyle = {
      right,
      left,
    };
    return userConfidenceTextStyle;
  }

  onDataReceive(responseData) {
    this.handleDrawerClose();
    const { ok, noQuestionBacklogError } = commonConstant.questionStatus;
    if (responseData) {
      const { questionResponse } = responseData;
      if (this.props.isSkippedAllQuestions) {
        // this.props.setIsSkippedAllQuestions(false);
        this.setState({
          showInsightScreen: true,
          userScore: questionResponse.data.personalScore,
          isSwiperScroll: false,
          isShowSwiperPagination: false,
          selectedProjectMeasuring: questionResponse.data.selectedProjectMeasuring,
        });
        return;
      }
      if (questionResponse.status === ok) {
        const {
          domainConfidenceSummary,
          overAllScore,
          personalScore,
          isAnonymityCreteriaMet,
          trendData,
          trendDays,
          selectedProjectTitle,
          selectedProjectColor,
          selectedProjectMessage,
          selectedProjectMeasuring,
          isShowSelectedProjectDashboardMobile,
          overAllScoreColor,
          userScoreColor,
          userPerformanceByScore,
          periodLength,
          showTrendLines,
        } = questionResponse.data;

        const urlsInMessage = selectedProjectMessage ? selectedProjectMessage.match(/(https?:\/\/[^\s]+)/g) : '';
        const message = urlsInMessage
          ? replaceAll(urlsInMessage, selectedProjectMessage)
          : selectedProjectMessage;

        this.setState({
          selectedProjectName: selectedProjectTitle,
          projectColor: selectedProjectColor,
          selectedProjectMessage: message,
          selectedProjectMeasuring,
          isShowSelectedProjectDashboardMobile,
          overAllScoreColor,
          userScoreColor,
          userPerformanceByScore,
          trendData: [],
          trendDataSet: [],
          showTrendLines: false,
        });
        this.setConfidenceBarAndTextStyles(personalScore, overAllScore);
        if (trendData.length > 0) {
          const trendDataSet = [];

          trendData.map((data) => {
            trendDataSet.push(data.value);
            return data;
          });

          const startDate = new Date(trendData[0].date);
          const trendStartDate = `${startDate.getDate()} ${months[startDate.getMonth()]
          } ${startDate.getFullYear()}`;
          const trendLastRecord = trendData[trendData.length - 1];
          const trendSecondLastRecord = trendData[trendData.length - 2];

          let trendDifference = 0;
          let showTrendDifference = false;
          let trendPerformancePositive = true;
          if (trendSecondLastRecord) {
            showTrendDifference = true;
            // calculate performance difference between last two trend datas
            trendDifference = Math.abs(
              trendLastRecord.value - trendSecondLastRecord.value,
            );
            trendPerformancePositive = trendLastRecord.value - trendSecondLastRecord.value > 0;
          }
          this.setState({
            trendData,
            trendDataSet,
            trendStartDate,
            showTrendDifference,
            trendDifference,
            trendPerformancePositive,
            trendDays,
            periodLength,
            showTrendLines,
          });
        }
        this.setState({
          domainConfidenceSummary,
          showInsightScreen: !isAnonymityCreteriaMet,
        });
        return;
      }
      if (questionResponse.status === noQuestionBacklogError) {
        this.setState({ showInsightScreen: true });
      }
    }
  }

    handleDrawerToggle = () => {
      this.props.showHideOverlay(true);
    };

    handleDrawerClose = () => {
      this.props.showHideOverlay(false);
    };

    render() {
      const {
        showInsightScreen,
        selectedProjectName,
        selectedProjectMessage,
        projectColor,
        isShowSelectedProjectDashboardMobile,
        isLoading,
      } = this.state;

      const { isDrawerOpen, selectedProject } = this.props;
      return (
        <React.Fragment>
          <OverlayBackdrop />
          <SideDrawer
            isOpen={isDrawerOpen}
            getProjectDashboardData = {this.getProjectDashboardData}
          />
          <RenderIf showComponent={isLoading}>
            <MobileViewLoader isLoading = {isLoading} />
          </RenderIf>
          <RenderIf showComponent={!isLoading}>
            <div class="d-block overflow-hidden">
              <div class="bg-orange-img">
                <div className="drawer">
                  <span className={`hamburger ${isDrawerOpen ? 'open' : ''}`} onClick={this.handleDrawerToggle}>
                    <HamburgerSvg />
                  </span>
                  <span class="bg-title bg-auth-title">Great work - you’ve answered all of your questions!</span>
                </div> <span className='sp-label' style={{ background: `${projectColor}` }}>{selectedProjectName}</span>
              </div>
              <RenderIf showComponent={!isLoading}>
                {isShowSelectedProjectDashboardMobile && selectedProject && (<React.Fragment>
                  <div className='main-content content-height'>
                    <div className='content-wrap' style={{ marginBottom: '140px' }}>
                      {selectedProjectMessage !== '' && selectedProjectMessage !== undefined
                        && <div style={{
                          width: '100%',
                          textAlign: 'center',
                          margin: '0 auto 0',
                          maxWidth: '100%',
                          padding: '0 20px',
                        }}>
                          <div class="info-title box-title mt-0 mb-0">{selectedProjectMessage}</div>
                        </div>}
                        <div className='graph-box-content'>
                          <div className='container'>
                            <div className='row justify-content-center'>
                              <div className='col-lg-4 col-md-12'>
                                {(!this.state.showTrendLines && !this.state.userScore)
                                && <YourSatisfactionInactive
                                  projectData = {this.state}
                                />}
                                { (this.state.showTrendLines || !!this.state.userScore)
                                && <YourSatisfactionActive
                                  projectData = {this.state}
                                />}
                              </div>
                              <div className='col-lg-5 col-md-12'>
                                {showInsightScreen && <SatisfactionComparesInActive
                                  projectData = {this.state}
                                />}
                                { !showInsightScreen && <SatisfactionComparesActive
                                  projectData = {this.state}
                                />}
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </React.Fragment>)}
                {(!isShowSelectedProjectDashboardMobile || !selectedProject)
                && (<React.Fragment>
                  <div className='text-center insights-disabled'>
                    <div className='content-height main-content align-items-start'>
                      <div>
                        {selectedProjectMessage !== '' && selectedProjectMessage !== undefined
                          && <div style={{
                            width: '100%',
                            textAlign: 'center',
                            margin: '0 auto 0',
                            maxWidth: '100%',
                            padding: '0 20px',
                          }}>
                     <div class="info-title box-title mt-0 mb-0">{selectedProjectMessage}</div>
                          </div>}
                            <div className='graph-box-content'>
                            <div className='center-insight-info '>
                              <div className='insight-info'>
                                <InsightDisabledSvg />
                                <h3>Thank you for sharing your insights!</h3>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>)}
              </RenderIf>
            </div>
          </RenderIf>
          <footer class="footer-btm">
            <FooterContent></FooterContent>
          </footer>
        </React.Fragment>
      );
    }
}

const mapDispatchToProps = {
  showHideOverlay,
  setProjectInfo,
  getProjectDashboardData,
  isProjectsHasUnasweredQuestions,
  show401Error: notify.show401Error,
};
const mapStateToProps = state => ({
  showOverlay: state.overlay.showOverlay,
  isDrawerOpen: state.overlay.isDrawerOpen,
  selectedProject: state.project.selectedProject,
  completionMessage: state.questionnaire.completionMessage,
  sideDrawerProjects: state.project.sideDrawerProjects,
  isDrawerListFetched: state.project.isDrawerListFetched,
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDashboard);
