import actionType from './actionType';

// Configured initial state for axios
const initialState = {
  notificationData: { showError: false, errorMessage: '', status: '' },
};

// Creating axios reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
  case actionType.SHOW_NOTIFICATION:
    return {
      ...state,
      notificationData: {
        ...state.notificationData,
        showError: true,
        errorMessage: action.errorMessage,
      },
    };
  case actionType.HIDE_NOTIFICATION:
    return {
      ...state,
      notificationData: {
        ...state.notificationData,
        showError: false,
        errorMessage: '',
      },
    };
  case actionType.UPDATE_401_NOTIFICATION:
    return {
      ...state,
      notificationData: {
        ...state.notificationData,
        showError: false,
        errorMessage: action.errorMessage,
        status: '401',
      },
    };
  case actionType.SHOW_401_NOTIFICATION:
    return {
      ...state,
      notificationData: {
        ...state.notificationData,
        showError: true,
      },
    };
  default:
    return state;
  }
};
