import React from 'react';
import '../styles/common.scss';
import ErrorPage from './ErrorPage.jsx';
import FooterContent from './FooterContent.jsx';

const PageNotFound = () => (
  <React.Fragment>
    <ErrorPage></ErrorPage>
    <footer class="footer-btm">
      <FooterContent></FooterContent>
    </footer>
  </React.Fragment>
);
export default PageNotFound;
