import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  axiosData: state.axios.axiosData,
});

const mapDispatchToProps = {};

class Loader extends Component {
  render() {
    const { axiosData } = this.props;
    return (
      <div>
        {axiosData.isLoading && (
          <div className="loading-modal">
            <div className="global-loader-div darker">
              <div className="loading-title">Loading...</div>
              <div className="global-loader" />
              <div className="loading-end">Please wait!</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Loader);
