import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

// import required modules
import { Pagination } from 'swiper';
 // eslint-disable-next-line
import 'swiper/scss';
// eslint-disable-next-line
import 'swiper/scss/pagination';

const styles = {
  noResultsLine: {
    width: '24px',
    height: '2px',
    backgroundColor: '#888888',
    position: 'absolute',
    top: '50%',
    marginTop: '-1px',
    marginLeft: '-12px',

  },
  noResults: {
    height: '150px',
  },
  svgCircleMainValueInfo: {
    marginTop: '10px',
    fontSize: '15px',
  },
  trendlineText: { color: '#adadad', fontWeight: 'bold', fontSize: '13px' },
  trendlineTextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
};

class YourSatisfactionInactive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      trendDays,
      userScore,
      userScoreColor,
      selectedProjectMeasuring,
      userPerformanceByScore,
    } = this.props.projectData;
    return (
      <div className='content graph-content'>
        <h2 className='name'>Your {selectedProjectMeasuring}</h2>

        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            {trendDays !== undefined && trendDays === 0 && <div>
              Today
            </div>
            }
            {trendDays !== undefined && trendDays === 1 && <div>
              over the past {trendDays} weekday
            </div>
            }
            {trendDays !== undefined && trendDays > 1 && <div>
              over the past {trendDays} weekdays
            </div>
            }
            {userScore >= 0 && <div className='circular-bar'>
                  <CircularProgressbar
                    value={userScore}
                    text={userScore === 0 ? '0' : userScore}
                    styles={buildStyles({

                      /* Whether to use rounded or flat corners
                      on the ends - can use 'butt' or 'round' */
                      strokeLinecap: 'butt',

                      // Text size
                      textSize: '16px',

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      pathTransition: 'none',

                      // Colors
                      pathColor: userScoreColor,
                      textColor: userScoreColor,
                      trailColor: '#ffffff',
                      backgroundColor: '#ffffff',
                    })}
                  />
                </div>}
            {userScore < 0
                && <div style={{ ...styles.noResults }}>
                  <div style={{ ...styles.noResultsLine }}>

                  </div>
                </div>
            }
            {userScore >= 0 && <p
              style={{ ...styles.svgCircleMainValueInfo, color: userScoreColor }}
            >
              {userPerformanceByScore}
            </p>
            }
          </SwiperSlide>
        </Swiper>
      </div>
    );
  }
}

export default YourSatisfactionInactive;
