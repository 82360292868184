import actionType from './actionType';
import projectConstant from '../../../modules/project/constant/constant';

// Configured initial state for home screen
const initialState = {
  selectedProject: sessionStorage.getItem(projectConstant.selectedProjectId),
  selectedProjectName: '',
  projectColor: '',
  isSkippedAllQuestions: false,
  projects: [],
  userProjectsCount: 0,
  sideDrawerProjects: [],
  isDrawerListFetched: false,
  isUserAbleToChangeMode: false,
};

// Creating home reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SELECT_PROJECT:
      return {
        ...state,
        selectedProject: action.project.selectedProjectId,
        selectedProjectName: action.project.selectedProjectName,
        projectColor: action.project.projectColor,
      };
    case actionType.SET_PROJECT_INFO:
      return {
        ...state,
        projects: action.projects,
      };
    case actionType.SET_IS_SKIPPED_ALL_QUESTIONS:
      return {
        ...state,
        isSkippedAllQuestions: action.isSkippedAllQuestions,
      };
    case actionType.SET_SELECTED_PROJECT_TITLE:
      return {
        ...state,
        selectedProjectName: action.selectedProjectName,
      };
    case actionType.SET_SIDE_DRAWER_PROJECT_LIST:
      return {
        ...state,
        sideDrawerProjects: action.projects,
        isDrawerListFetched: true,
      };
    case actionType.USER_ACCESSIBLE_ROLE:
      return {
        ...state,
        isUserAbleToChangeMode: action.data,
      };
    default:
      return state;
  }
};
