import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deviceType } from 'react-device-detect';
import Cryptr from 'cryptr';
import { MobileViewLoader } from '../../../ui-components/Loader';
import { OrientationError } from '../../../ui-components/PageContent';
import setOrientationName from '../../OrientationWrapper/actions/orientationwrapper';
import HomeScreen from './HomeScreen';
import { getUserQuestions } from '../../project/actions/project';

const cryptr = new Cryptr(__CONFIG__.encryptSecret);
class WrapperHomeComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSpinnerLoading: true,
    };
  }

  componentDidMount() {
    /* Code for fetching the questions and checking the loading state */
    let projectId = cryptr.decrypt(this.props.match.params.projectId);
    projectId = projectId === 'all-topic' ? null : projectId;
    this.getUserQuestions(projectId);

    if (deviceType === 'browser') {
      const orientation = 'true';
      this.props.setOrientationName(orientation);
    } else if (window.innerHeight > window.innerWidth) {
      const orientation = 'true';
      this.props.setOrientationName(orientation);
    } else {
      const orientation = 'false';
      this.props.setOrientationName(orientation);
    }
  }

  getUserQuestions = async (selectedProject) => {
    this.props.getUserQuestions(selectedProject)
      .then(async () => {
        setTimeout(() => {
          this.setState({ isSpinnerLoading: false });
        }, 2000);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        // this.setState({ isLoading: false, isResizeFinished: true });
      });
  }

  render() {
    const { isSpinnerLoading } = this.state;
    if (isSpinnerLoading && (this.props.orientationname !== 'false' || this.props.orientationname !== false)) {
      return <MobileViewLoader isLoading={isSpinnerLoading}></MobileViewLoader>;
    }
    if (this.props.orientationname === 'false' || this.props.orientationname === false) {
      return <OrientationError />;
    }
    return <HomeScreen isSpinnerLoading = {isSpinnerLoading} />;
  }
}

const mapStateToProps = state => ({
  orientationname: state.orientationwrapper.orientationname,
});

const mapDispatchToProps = {
  setOrientationName,
  getUserQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WrapperHomeComponent));
