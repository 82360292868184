import { actionType } from '../../../redux/modules/questionnaire';
import questionnaireService from '../../../redux/services/questionnaireService';
import projectConstant from '../../project/constant/constant';

function getUserProjectQuestionnaire(guid) {
  return dispatch => questionnaireService
    .getUserProjectQuestions(guid)
    .then((res) => {
      const {
        allQuestions, isValidGuid, isNewUser, completionMessage,
        isMobileDashboardEnabled, measuringMessage, isWebDashboardEnabled, projectId, userResponse,
      } = res.data;
      const questionsData = {
        allQuestions,
        isValidGuid,
        isNewUser,
        currentCardIndex: 1,
        totalCards: res.data.allQuestions.length,
        completionMessage,
        isMobileDashboardEnabled,
        measuringMessage,
        isWebDashboardEnabled,
        userResponse,
        guid,
      };
      sessionStorage.setItem(projectConstant.selectedProjectId, projectId);
      dispatch({ type: actionType.SET_USER_PROJECT_QUESTIONNAIRE, questionsData });
    });
}

function respondUserProjectQuestionnaire(data, props) {
  return dispatch => questionnaireService
    .respondUserProjectQuestion(data)
    .then((res) => {
      let questions = [];
      if (res.data) {
        questions = props.userQuestionsData.filter(item => item.id !== data.questionUserId);
        const questionsData = {
          allQuestions: questions,
          currentCardIndex: props.currentCardIndex + 1,
        };
        dispatch({ type: actionType.RESPOND_USER_PROJECT_QUESTIONNAIRE, questionsData });
      } else {
        window.location.reload(true);
      }
    });
}

function setFirstTimeUserFalse(data) {
  return dispatch => questionnaireService
    .setFirstTimeUserFalse(data)
    .then((res) => {
      let isNewUser = true;
      if (res.data.response) {
        isNewUser = false;
      }
      dispatch({ type: actionType.SET_FIRST_TIME_USER_FALSE, isNewUser });
    });
}

function undoResponseQuestion(data, props, lastCard, lastQuestionType) {
  return dispatch => questionnaireService
    .undoResponseQuestion(data)
    .then((res) => {
      let questions = [];
      if (res.data) {
        questions = props.userQuestionsData;
        const questionsData = {
          allQuestions: questions,
          currentCardIndex: props.currentCardIndex !== 1
            ? props.currentCardIndex - 1
            : props.currentCardIndex,
          lastCard,
          lastQuestionType,

        };
        dispatch({ type: actionType.UNDO_RESPONSE_QUESTION, questionsData });
      } else {
        window.location.reload(true);
      }
    });
}

function updateLastQuestionCardId(lastCardId, lastCard, lastQuestionType) {
  return dispatch => dispatch({
    type: actionType.UPDATE_LAST_QUESTION_CARD_ID,
    lastCardId,
    lastCard,
    lastQuestionType,
  });
}

export {
  getUserProjectQuestionnaire,
  respondUserProjectQuestionnaire,
  setFirstTimeUserFalse, undoResponseQuestion,
  updateLastQuestionCardId,
};
