const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const SHOW_RECORD_PROCESSING = 'SHOW_RECORD_PROCESSING';
const HIDE_RECORD_PROCESSING = 'HIDE_RECORD_PROCESSING';
const UPDATE_401_NOTIFICATION = 'UPDATE_401_NOTIFICATION';
const SHOW_401_NOTIFICATION = 'SHOW_401_NOTIFICATION';

export default {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SHOW_RECORD_PROCESSING,
  HIDE_RECORD_PROCESSING,
  SHOW_401_NOTIFICATION,
  UPDATE_401_NOTIFICATION,
};
