const questionnaireConstant = {
  YES_NO_QUESTION_TYPE: 1,
  OPEN_TEXT_QUESTION_TYPE: 2,
  BUTTON_TYPE: {
    YES: 'yes', NO: 'no', SKIP: 'skip', SUBMIT_OPEN_TEXT: 'submit_open_text', UNDO: 'undo',
  },
  WARNING_MESSAGE: 'Character limit exceeded.',
  OPTIONAL_COMMENT_LIMIT: 250,
  MAX_HEIGHT_TO_TOGGLE_CONTAINER: 500,
  optionalComment: {
    skip: 'skip',
    cardIndexIncrementValue: 1,
  },
  deleteModel: {
    title: 'Delete User',
    message:
      'By continuing, you will lose access to all projects. Are you sure you want to continue?',
  },
};

export default questionnaireConstant;
