import React, { Component } from 'react';

class SpinnerButton extends Component {
  render() {
    const {
      type, isLoading, label, ...restProps
    } = this.props;
    const buttonType = type || 'primary';
    const buttonText = label || 'Submit';
    const iconClass = buttonType === 'primary' ? 'a-loading-one' : 'a-loading-one a-primary';
    const submitType = restProps.onClick ? 'button' : 'submit';


    return (
      <React.Fragment>
        {isLoading && (
          <button disabled className={`a-btn a-btn-lg a-btn-${buttonType} a-no-interaction loader-button`}>
            <i className={iconClass} />
          </button>
        )}

        {!isLoading && (
          <button type={submitType} className={`a-btn a-btn-${buttonType} a-btn-lg`} {...restProps}>
            {buttonText}
          </button>
        )}
      </React.Fragment>
    );
  }
}

export default SpinnerButton;
