// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 14px 10px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #dedede;

}

.progress-bar-card-container {
  width: 68px;
  height: 68px;
}

.card-title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #2D2D2D;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 34px;
  margin-bottom: 10px;
  line-height: 20px;
}

.card-status {
  background: #e9e9e9;
  padding: 4px 8px;
  color: #7d7d7d;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.card-topic-container {
  margin: 12px auto;
  width: 300px;
}

.card-status svg {
  margin-right: 4px;
}

@media (max-width: 320px) {
  .card-topic-container {
    width: 250px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/modules/questionaire/containers/components/ProgressCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;;AAE3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,uBAAuB;EACvB,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".progress-card {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  width: 100%;\r\n  padding: 14px 10px;\r\n  background-color: #fff;\r\n  border-radius: 8px;\r\n  margin-bottom: 10px;\r\n  cursor: pointer;\r\n  border: 1px solid #dedede;\r\n\r\n}\r\n\r\n.progress-bar-card-container {\r\n  width: 68px;\r\n  height: 68px;\r\n}\r\n\r\n.card-title {\r\n  font-size: 16px;\r\n  font-weight: 500;\r\n  text-align: left;\r\n  color: #2D2D2D;\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 2;\r\n  -webkit-box-orient: vertical;\r\n  min-height: 34px;\r\n  margin-bottom: 10px;\r\n  line-height: 20px;\r\n}\r\n\r\n.card-status {\r\n  background: #e9e9e9;\r\n  padding: 4px 8px;\r\n  color: #7d7d7d;\r\n  font-size: 14px;\r\n  display: flex;\r\n  align-items: center;\r\n  width: fit-content;\r\n}\r\n\r\n.card-topic-container {\r\n  margin: 12px auto;\r\n  width: 300px;\r\n}\r\n\r\n.card-status svg {\r\n  margin-right: 4px;\r\n}\r\n\r\n@media (max-width: 320px) {\r\n  .card-topic-container {\r\n    width: 250px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
