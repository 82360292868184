import React from 'react';
import { connect } from 'react-redux';
import '../styles/common.scss';
import landcspe from '../../../assets/images/landcspe.png';

const OrientationError = () => (
  <React.Fragment>
    <div>
      <div class="wrapperorientation" style={{ backgroundcolor: '#000' }}>
        <div class="container text-center">
          <div class="invalid-img">
            <img class="img-fluid" style={{}} src={landcspe} />
          </div>
          <div class="page-content">
            <span class="landscape-title">Oh no! We can't fit everything on your screen.</span>
            <div class="landscape-container">
              <p class="description mb-0">
                Please rotate your device.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </React.Fragment>
);
const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
});


export default connect(
  mapStateToProps,
  null,
)(OrientationError);
