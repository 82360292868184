import React from 'react';
import './InstructionalOverlay.css';

class InstructionalOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isOpen } = this.props;
    return (
      <>
        {isOpen && (
          <div className="instructionalOverlay-focus">
            <div className="instructionalOverlay-background" onClick={this.props.onClose}></div>
            <div className="instructionalOverlay-container">
              <div className="instructionalOverlay-controls">
                {/* <div class="swipe-text d-flex right">
                  Swipe right if you agree
                  <MaterialIcon icon="eastt"
                    size={36}
                    color={'#ffffff'}/>
                </div>
                <div class="swipe-text d-flex left">
                  <MaterialIcon icon="west"
                    size={36}
                    color={'#ffffff'}/>
                  Swipe left if you disagree
                </div>
                <div class="swipe-text comment">
                  <MaterialIcon icon="north_west"
                    size={36}
                    color={'#ffffff'}/>
                  Respond with comment
                </div>
                <div class="swipe-text question">
                  No. of question(s) remaining
                  <MaterialIcon icon="call_received"
                    size={36}
                    color={'#ffffff'}/>
                </div> */}
                <div className='d-flex align-items-end justify-content-center' style={{ width: '100%', height: '100vh' }}>
                <button onClick={
                    () => this.props.toggleInstructionOverlay(false)
                  }
                  className='a-btn a-btn-primary a-btn-lg got-btn'>
                  Got it
                </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default InstructionalOverlay;
