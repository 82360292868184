// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import setBrandName from '../actions/brandwrapper';

const BrandWrapper = (props) => {
  useEffect(() => {
    const domainName = window.location.hostname;
    const providedBrand = __CONFIG__[domainName];
    if (providedBrand) {
      props.setBrandName(providedBrand);
    }
  }, []);
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.brandName}</title>
    </Helmet>
  );
};

const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
});

const mapDispatchToProps = {
  setBrandName,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrandWrapper);
