import React from 'react';
import { connect } from 'react-redux';
import '../styles/common.scss';
import getStarted from '../../../assets/images/get-started.jpg';

class GettingStarted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTablet: props.isTablet,
    };
    this.handleClick = this.handleClick.bind(this);
    this.submitButton = React.createRef();
  }

  componentDidMount() {
    this.submitButton.current.focus();
  }

  handleClick() {
    this.props.gettingStarted();
  }

  render() {
    const { brandName } = this.props;
    return (
      <React.Fragment>

     <div class="wrapper fade-in-getting-started get-start-content">
            <div class="container text-center">
              <div class="get-started-img">
                <img class="img-fluid" src={getStarted} />
              </div>
              <div class="page-content">
                <span class="content-title">Welcome to {brandName}!</span>
                <div class="description-container">
                  <p class="description mb-0">A series of quick fire questions will capture your insights and how they compare to the team around you. </p>  </div>
                <a ref={this.submitButton} href="javascript:void(0)" tabIndex="0" onClick={() => this.handleClick()} class="get-started-btn" >Get Started</a>
              </div>
            </div>

          </div>


      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
});


const mapDispatchToProps = {
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GettingStarted);
