import React from 'react';
import './ErrorAlertModel.css';

class ErrorAlertModel extends React.Component {
  render() {
    return (
      <div class="error-alert-modal">
        <div class="error-alert-modal-content">
          <span class="error-alert-close" onClick={() => this.props.onCloseAlertHandler()}>&times;</span>
          <p className='m-0 text-color'>{this.props.modelText}</p>
        </div>
      </div>
    );
  }
}

export default ErrorAlertModel;
