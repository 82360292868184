import { actionType } from '../../../../redux/modules/common/notification';

// Action creater for notification
export function showErrorActionCreater(errorMessage) {
  return { type: actionType.SHOW_NOTIFICATION, errorMessage };
}

export function hideErrorActionCreater() {
  return { type: actionType.HIDE_NOTIFICATION };
}

// Creating thunk action creaters which will be access from component
function showError() {
  return (dispatch) => {
    dispatch(showErrorActionCreater('error'));
  };
}

function hideError() {
  return (dispatch) => {
    dispatch(hideErrorActionCreater());
  };
}

function update401Error(errorMessage) {
  return dispatch => dispatch({
    type: actionType.UPDATE_401_NOTIFICATION,
    errorMessage,
  });
}

function show401Error() {
  return dispatch => dispatch({
    type: actionType.SHOW_401_NOTIFICATION,
  });
}

export default {
 showError, hideError, update401Error, show401Error,
};
