import React from 'react';
import { connect } from 'react-redux';
import setOrientationName from '../actions/orientationwrapper';

class OrientationDetected extends React.Component {
  componentDidMount() {
      // Find matches
      const mql = window.matchMedia('(orientation: portrait)');

      // If there are matches, we're in portrait
      if (mql.matches) {
        this.props.setOrientationName(mql.matches);
      } else {
        this.props.setOrientationName(mql.matches);
      }

      // Add a media query change listener
      mql.addListener((m) => {
        if (m.matches) {
          // Changed to portrait
          this.props.setOrientationName(mql.matches);
        } else {
          // Changed to landscape
          this.props.setOrientationName(mql.matches);
        }
      });
  }

  render() {
    return (
      <div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  orientationname: state.orientationwrapper.orientationname,
});

const mapDispatchToProps = {
  setOrientationName,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrientationDetected);
