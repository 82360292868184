import React from 'react';

const CommunitySvg = () => (<svg
  width="50px"
  height="50px"
  viewBox="0 0 100 100"
  enable-background="new 0 0 100 100"
>
  <path
    vector-effect="non-scaling-stroke"
    fill="#b0b0b0"
    d="M49.962,93.129L49.962,93.129c-1.885,0-3.578-1.097-4.367-2.789    l-8.888-19.257c-12.158-5.252-20.18-17.333-20.18-30.703C16.527,21.931,31.532,6.907,50,6.907S83.473,21.912,83.473,40.38    c0,13.37-8.041,25.471-20.199,30.742l-8.945,19.238C53.54,92.052,51.828,93.129,49.962,93.129z M50,16.526    c-13.158,0-23.855,10.696-23.855,23.855c0,10.023,6.329,19.045,15.756,22.431c1.212,0.442,2.193,1.347,2.732,2.501l5.348,11.562    l5.367-11.562c0.539-1.154,1.52-2.058,2.732-2.501c9.426-3.405,15.775-12.408,15.775-22.45C73.855,27.222,63.158,16.526,50,16.526    z"
  />
  <path
    fill="#b0b0b0"
    d="M70.721,40c-0.494-2.236-1.839-4.16-3.693-5.4c0.188-0.539,0.286-1.113,0.286-1.702
                c0-2.849-2.317-5.167-5.166-5.167s-5.166,2.318-5.166,5.167c0,0.253,0.021,0.503,0.057,0.748
                c-0.483-1.584-1.499-2.938-2.85-3.841c0.152-0.438,0.232-0.903,0.232-1.38c0-2.307-1.877-4.184-4.185-4.184
                c-2.308,0-4.184,1.877-4.184,4.184c0,0.477,0.079,0.942,0.231,1.379c-1.251,0.837-2.214,2.059-2.732,3.49
                c0.01-0.131,0.016-0.263,0.016-0.396c0-2.849-2.318-5.167-5.166-5.167c-2.848,0-5.166,2.318-5.166,5.167
                c0,0.589,0.098,1.163,0.286,1.702c-1.853,1.24-3.198,3.164-3.693,5.4c-0.061,0.268-0.091,0.496-0.091,0.758
                c0,1.924,1.565,3.456,3.49,3.456h10.327c-1.754,1.675-3.167,3.546-3.678,5.858c-0.074,0.323-0.111,0.775-0.111,1.091
                c0,2.329,1.894,3.938,4.222,3.938H56.51c2.329,0,4.223-1.593,4.223-3.922c0-0.307-0.035-0.711-0.108-1.043
                c-0.512-2.316-1.912-4.248-3.667-5.923h10.364c1.925,0,3.49-1.532,3.49-3.456C70.812,40.496,70.782,40.267,70.721,40z
                M67.322,42.539H56.974c-0.836,0-1.515-0.795-1.515-1.631c0-0.126,0.018-0.305,0.044-0.423c0.531-2.399,2.328-4.353,4.654-5.063
                c-0.731-0.585-1.2-1.502-1.2-2.51c0-1.762,1.429-3.198,3.191-3.198c1.762,0,3.19,1.426,3.19,3.188c0,1.008-0.469,1.907-1.2,2.492
                c2.326,0.71,4.123,2.634,4.653,5.034c0.026,0.117,0.045,0.355,0.045,0.48C68.837,41.744,68.158,42.539,67.322,42.539z
                M56.852,53.008h-13.24c-1.069,0-1.936-0.75-1.936-1.819c0-0.162,0.022-0.257,0.057-0.408c0.679-3.069,2.977-5.505,5.953-6.413
                c-0.936-0.747-1.535-1.886-1.535-3.177c0-2.255,1.827-4.076,4.082-4.076c2.254,0,4.082,1.833,4.082,4.088
                c0,1.29-0.6,2.444-1.535,3.193c2.977,0.909,5.275,3.374,5.954,6.443c0.033,0.15,0.057,0.188,0.057,0.35
                C58.789,52.258,57.921,53.008,56.852,53.008z M31.712,40.908c0-0.126,0.018-0.305,0.045-0.423
                c0.53-2.399,2.327-4.353,4.653-5.063c-0.732-0.585-1.2-1.502-1.2-2.51c0-1.762,1.428-3.198,3.191-3.198s3.191,1.426,3.191,3.188
                c0,1.008-0.469,1.907-1.2,2.492c2.326,0.71,4.122,2.634,4.654,5.034c0.026,0.117,0.044,0.355,0.044,0.48
                c0,0.836-0.679,1.631-1.514,1.631H33.226C32.391,42.539,31.712,41.744,31.712,40.908z M48.624,30.446
                c-0.593-0.475-0.971-1.204-0.971-2.021c0-1.427,1.156-2.584,2.584-2.584c1.428,0,2.585,1.157,2.585,2.584
                c0,0.817-0.38,1.546-0.972,2.021c1.884,0.574,3.34,2.135,3.77,4.078c0.021,0.095,0.036,0.299,0.036,0.401
                c0,0.676-0.55,1.333-1.227,1.333h-8.382c-0.677,0-1.227-0.657-1.227-1.333c0-0.102,0.015-0.253,0.036-0.347
                C45.284,32.633,46.74,31.02,48.624,30.446z"
  />
</svg>
);

export default CommunitySvg;
