import axios from 'axios';
import project from '../constants/project';

class projectService {
  static getProjects() {
    return axios.get(`${__CONFIG__.apiEndPoint}${project.GET_PROJECTS}`);
  }

  static getProjectDashboardData(selectedProject) {
    return axios.get(
      `${__CONFIG__.apiEndPoint}${project.PROJECT_DASHBOARD_INFO}/${selectedProject}`,
    );
  }

  static getAllProjectsDashboardData() {
    return axios.get(`${__CONFIG__.apiEndPoint}${project.ALL_PROJECT_DASHBOARD_INFO}`);
  }

  static getUserQuestions(selectedProjectId) {
    return axios.get(`${__CONFIG__.apiEndPoint}${project.GET_USER_QUESTIONS}/?selectedProject=${selectedProjectId || null}`, {
      selectedProject: selectedProjectId,
    });
  }

  static isProjectsHasUnasweredQuestions(projectInfo) {
    return axios.get(
      `${__CONFIG__.apiEndPoint}${project.PROJECT_HAS_ANSWERED_QUESTIONS}/?projectInfo=${projectInfo || null}`,
      projectInfo,
    );
  }

  static respondQuestions(response) {
    return axios({
      method: 'post',
      url: `${__CONFIG__.apiEndPoint}${project.RESPOND_QUESTIONS}`,
      data: response,
    });
  }

  static skipQuestion(response) {
    return axios({
      method: 'post',
      url: `${__CONFIG__.apiEndPoint}${project.SKIP_QUESTION}`,
      data: response,
    });
  }

  static undoResponse(questionInfo) {
    return axios.put(`${__CONFIG__.apiEndPoint}${project.UNDO_RESPONSE}`, questionInfo);
  }

  static checkDeepLinkUser(token) {
    const params = {
      token,
    };
    return axios.post(`${__CONFIG__.apiEndPoint}${project.CHECK_IF_SAME_USER}`, params);
  }

  static checkFirstTimeUser() {
    return axios.get(`${__CONFIG__.apiEndPoint}${project.CHECK_FIRST_TIME_USER}`);
  }

  static updateFirstTimeUser() {
    return axios.post(`${__CONFIG__.apiEndPoint}${project.UPDATE_FIRST_TIME_USER}`);
  }

  static checkUserAbleToChangeModeOptions() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${project.CHECK_USER_ABLE_TO_CHANGE_MODE_OPTIONS}`)
      .then(res => res)
      .catch(err => err);
  }
}

export default projectService;
