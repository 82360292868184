import React from 'react';
import {
  Route, Switch, withRouter, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { PageNotFound } from './ui-components/PageContent';
import Questionnaire from './modules/questionaire/containers/questionnaire.jsx';
import BrandWrapper from './modules/BrandedWrapper/component/brandedwrapper';
import Notification from './ui-components/Notification/Notification';
import Orientationdetected from './modules/OrientationWrapper/component/OrientationDetected';
import PrivateRoute from './PrivateRoute.jsx';
// import SignIn from './modules/questionaire/containers/SignIn';
import PersonalDashboard from './modules/questionaire/containers/PersonalDashboard';
import AccountActivation from './modules/questionaire/containers/AccountActivation.jsx';
import RestrictedUser from './modules/account/containers/RestrictedUser';
import { Login, IamSignIn } from './modules/account';
import authService from './redux/services/authService';
import accountService from './redux/services/accountService';
import constant from './modules/account/actions/constant';
import AllTopics from './modules/questionaire/containers/AllTopics';
import Notifications from './modules/questionaire/containers/Notifications';
import TermsOfUse from './modules/account/containers/TermsOfUse';
import PrivacyPolicy from './modules/account/containers/PrivacyPolicy';
import WrapperHomeComponent from './modules/questionaire/dashboard/WrapperHomeComponent';

const routes = ({ redirectUrl }) => (
  <React.Fragment>
    <Orientationdetected />
    <Notification />
    <BrandWrapper />
    <Switch>
      <Route path="/question" component={Questionnaire} />
      <Route path="/sign-in" component={Login} />
      <Route path="/account-activation" component={AccountActivation} />
      <Route path="/user-restricted" component={RestrictedUser} />
      <Route exact path="/iam-sign-in" component={IamSignIn} />
      <Route path="/terms-of-use" component={TermsOfUse} />
      <Route path="/information-collection-statement" component={PrivacyPolicy} />
      <PrivateRoute path='/personal-dashboard' component={PersonalDashboard} />
      <PrivateRoute path='/all-topics' component={AllTopics} />
      <PrivateRoute path='/notification-setting' component={Notifications} />
      <PrivateRoute path='/home-screen/:projectId' component={WrapperHomeComponent} />
      <Route exact path="/" render={() => (<Redirect to="/sign-in" />)}/>
      <Route component={PageNotFound} />
    </Switch>
    <IdleTimer
      element={document}
      onIdle={() => {
        if (authService.isAuthenticated()) {
          const { email, user_id: userId } = authService.getUserInfo();
          authService.setSignoutUserEmail(email);

          accountService
            .logUserSignOut(userId, constant.logSignOutMessages.inactivityThresholdMessage)
            .then(() => {
              authService.clearAllStorage();
              // eslint-disable-next-line no-undef
              window.location = `${__CONFIG__.iamSignoutUri}${redirectUrl}`;
            });
        }
      }}
      debounce={250}
      timeout={15 * 60 * 1000}
    />
  </React.Fragment>
);

const mapStateToProps = state => ({
  redirectUrl: state.brandwrapper.redirectUrl,
});
export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(routes),
);
// export default routes;
