import actionType from './actionType';

// Configured initial state for Brand
const initialState = {
  brandName: 'Project Perspectives',
  playStoreUrl: 'https://play.google.com/store/apps/details?id=com.mobile.perspectives.pwc',
  appStoreUrl: 'https://apps.apple.com/au/app/project-perspectives/id1487774417',
  baseUrl: 'projectperspectives.pwc.com',
  redirectUrl: 'https://projectperspectives.pwc.com/sign-in',
};

// Creating project reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
  case actionType.SET_BRAND_INFO:
    return {
      ...state,
      brandName: action.data.brandName,
      playStoreUrl: action.data.playStoreUrl,
      appStoreUrl: action.data.appStoreUrl,
      baseUrl: action.data.baseUrl,
      redirectUrl: action.data.redirectUrl,
    };
  default:
    return state;
  }
};
