import axios from 'axios';
import questionnaire from '../constants/questionnaire';

class questionnaireService {
  static getUserProjectQuestions(guid) {
    const params = {
      token: guid,
    };
    return axios
      .get(`${__CONFIG__.apiEndPoint}${questionnaire.USER_PROJECT_QUESTIONS}`, { params })
      .then(res => res)
      .catch(error => error.response);
  }

  static respondUserProjectQuestion(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${questionnaire.RESPOND_USER_PROJECT_QUESTION}`, data)
      .then(res => res)
      .catch(error => error.response);
  }

  static skipUserProjectQuestion(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${questionnaire.SKIP_USER_PROJECT_QUESTION}`, data)
      .then(res => res)
      .catch(error => error.response);
  }

  static undoResponseQuestion(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${questionnaire.UNDO_RESPONSE_QUESTION}`, data)
      .then(res => res)
      .catch(error => error.response);
  }

  static setFirstTimeUserFalse(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${questionnaire.SET_FIRST_TIME_USER_FALSE}`, data)
      .then(res => res)
      .catch(error => error.response);
  }

  static fetchCsrfToken() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${questionnaire.FETCH_CSRF_TOKEN}`)
      .then(res => res)
      .catch(error => error.response);
  }
}
export default questionnaireService;
