import React, { useEffect } from 'react';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

export default function CheckSoftKeyBoard(props) {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const isMobile = navigator.userAgent.toLowerCase().match(/mobile/i)
&& !navigator.userAgent.toLowerCase().match(/ipad/i)
&& !navigator.userAgent.toLowerCase().match(/tablet/i);

  useEffect(() => {
    if (isKeyboardOpen && isMobile) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [isKeyboardOpen]);
  if (isMobile) {
    return (<>
      {isKeyboardOpen ? <></> : props.children}
    </>
    );
  }
  return (<>{props.children}</>);
}
