import React from 'react';
import './OverlayBackdrop.css';
import { connect } from 'react-redux';
import { showHideOverlay } from '../../overlay/actions/overlay';

class OverlayBackdrop extends React.Component {
  render() {
    return (
      <div className="overlay-backdrop" style={{ display: this.props.showOverlay ? 'block' : 'none' }}></div>
    );
  }
}

const mapStateToProps = state => ({
  showOverlay: state.overlay.showOverlay,

});

const mapDispatchToProps = {
  showHideOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayBackdrop);
