const SELECT_PROJECT = 'SELECT_PROJECT';
const SET_PROJECT_INFO = 'SET_PROJECT_INFO';
const SET_IS_SKIPPED_ALL_QUESTIONS = 'SET_IS_SKIPPED_ALL_QUESTIONS';
const SET_SELECTED_PROJECT_TITLE = 'SET_SELECTED_PROJECT_TITLE';
const SET_SIDE_DRAWER_PROJECT_LIST = 'SET_SIDE_DRAWER_PROJECT_LIST';
const USER_ACCESSIBLE_ROLE = 'USER_ACCESSIBLE_ROLE';

export default {
  SELECT_PROJECT,
  SET_PROJECT_INFO,
  SET_IS_SKIPPED_ALL_QUESTIONS,
  SET_SELECTED_PROJECT_TITLE,
  SET_SIDE_DRAWER_PROJECT_LIST,
  USER_ACCESSIBLE_ROLE,
};
