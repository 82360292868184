// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* The Modal (background) */
.error-alert-modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 3;
  /* Sit on top */
  padding-top: 10px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.error-alert-modal-content {
  background-color: #C62B12;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
  margin-right: 20px;
}

/* The Close Button */
.error-alert-close {
  color: #fff;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -9px;
}

.text-color {
  color: #fff;
}

.error-alert-close:hover,
.error-alert-close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/ui-components/ErrorAlertModel/ErrorAlertModel.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,cAAc;EACd,sBAAsB;EACtB,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,OAAO;EACP,MAAM;EACN,WAAW;EACX,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,4BAA4B;EAC5B,8BAA8B;EAC9B,mBAAmB;EACnB,oCAAoC;EACpC,qBAAqB;AACvB;;AAEA,kBAAkB;AAClB;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,kBAAkB;AACpB;;AAEA,qBAAqB;AACrB;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;;EAEE,WAAW;EACX,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":["/* The Modal (background) */\n.error-alert-modal {\n  display: block;\n  /* Hidden by default */\n  position: fixed;\n  /* Stay in place */\n  z-index: 3;\n  /* Sit on top */\n  padding-top: 10px;\n  /* Location of the box */\n  left: 0;\n  top: 0;\n  width: 100%;\n  /* Full width */\n  height: 100%;\n  /* Full height */\n  overflow: auto;\n  /* Enable scroll if needed */\n  background-color: rgb(0, 0, 0);\n  /* Fallback color */\n  background-color: rgba(0, 0, 0, 0.4);\n  /* Black w/ opacity */\n}\n\n/* Modal Content */\n.error-alert-modal-content {\n  background-color: #C62B12;\n  margin: auto;\n  padding: 20px;\n  border: 1px solid #888;\n  width: 60%;\n  margin-right: 20px;\n}\n\n/* The Close Button */\n.error-alert-close {\n  color: #fff;\n  float: right;\n  font-size: 28px;\n  font-weight: bold;\n  margin-top: -9px;\n}\n\n.text-color {\n  color: #fff;\n}\n\n.error-alert-close:hover,\n.error-alert-close:focus {\n  color: #fff;\n  text-decoration: none;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
