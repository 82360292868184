import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FuturePicImage from '../../../assets/images/future-pic-image.png';
import InsightsDisable from '../../../assets/images/insightsDisable.png';
import authService from '../../redux/services/authService';
import projectService from '../../redux/services/projectService';
import projectConstant from '../../modules/project/constant/constant';
import accountConstant from '../../modules/account/actions/constant';
import accountService from '../../redux/services/accountService';
import AlertModel from '../AlertModel/AlertModel.jsx';
import ErrorAlertModel from '../ErrorAlertModel/ErrorAlertModel.jsx';

class SuccessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completionMessage: props.CompletionMessage,
      isMobileDashboardEnabled: props.isMobileDashboardEnabled,
      isTablet: props.isTablet,
      measuringMessage: props.measuringMessage,
      isWebDashboardEnabled: props.isWebDashboardEnabled,
      isInsightAvailable: false,
      showInvalidUserAlert: false,
      showErrorAlert: false,
    };
  }

  checkIfAlreadyLoggedIn = async () => {
    if (authService.isAuthenticated(true)) {
      if (this.props.guid && this.props.guid.length) {
        const deeplinkResponse = await projectService.checkDeepLinkUser(this.props.guid);
        if (deeplinkResponse && deeplinkResponse.data && deeplinkResponse.data.isUserStakeholder) {
          if (deeplinkResponse
            && deeplinkResponse.data
            && !deeplinkResponse.data.sameDeepLinkUser) {
            this.setState({ showInvalidUserAlert: true });
          } else if (sessionStorage.getItem(projectConstant.selectedProjectId)) {
            this.props.history.push(accountConstant.personalDashboard);
          } else {
            this.props.history.push(accountConstant.homePage);
          }
        } else {
          this.setState({ showErrorAlert: true });
        }
      } else {
        this.onCloseInValidUserAlertHandler();
      }
    } else {
      this.props.history.push(accountConstant.signInPage);
    }
  }

  onCloseInValidUserAlertHandler = () => {
    this.setState({ showInvalidUserAlert: false });
    if (authService.isAuthenticated(true)) {
      this.logoutUser();
      // this.props.history.push(accountConstant.homePage);
    }
  }

  logoutUser() {
    if (authService.isAuthenticated()) {
      const { email, user_id: userId } = authService.getUserInfo();
      authService.setSignoutUserEmail(email);
      accountService
        .logUserSignOut(userId, accountConstant.logSignOutMessages.inactivityThresholdMessage)
        .then(() => {
          authService.clearAllStorage();
          // const uriParams = QueryString.stringify({ invalidUser: true });
          const redirectUri = `${this.props.redirectUrl}`;
          // eslint-disable-next-line no-undef
          window.location = `${__CONFIG__.iamSignoutUri}${redirectUri}`;
        });
    }
  }

  onCloseErrorAlertHandler = () => {
    this.setState({ showErrorAlert: false });
  }

  render() {
    const {
      completionMessage, isMobileDashboardEnabled,
    } = this.state;
    const {
      isUserAbleToLogin,
    } = this.props;
    return (
      <React.Fragment>
        {this.state.showInvalidUserAlert
          && <AlertModel onCloseAlertHandler={this.onCloseInValidUserAlertHandler}
            modelText={accountConstant.INVAILD_DEEPLINK_AUTH} />}
        {this.state.showErrorAlert
          && <ErrorAlertModel onCloseAlertHandler={this.onCloseErrorAlertHandler}
            modelText={accountConstant.USER_STAKEHOLDER_ERROR} />}
        <div class="d-block success-content">
          <div class="bg-orange-img">
            <span class="bg-title">Great work - you’ve answered all of your questions!</span>
            {/* <span className='sp-label'>Prior Year Check</span> */}
          </div>
          <div className = 'unauth-completion-msg'>
            {completionMessage && completionMessage.length > 0 && <div class="info-title box-title">{completionMessage}</div>}
          </div>
          <div className='text-center'>
            <div className='content'>
              {(isMobileDashboardEnabled && isUserAbleToLogin) && (<React.Fragment>
                <p>Sign in to view insights</p>
                <img src={FuturePicImage} style={{ marginBottom: '20px' }}></img>
              </React.Fragment>)}
              {(!isMobileDashboardEnabled || !isUserAbleToLogin)
                && <React.Fragment>
                    <div className='insight-info'>
                      <img src={InsightsDisable}></img>
                      <h3>Thank you for sharing your insights!</h3>
                    </div>
                </React.Fragment>}

              {isUserAbleToLogin && (<div class="">
                <button type="button" className="a-btn a-btn-primary a-btn-lg" onClick={() => this.checkIfAlreadyLoggedIn()}>Sign In</button>
              </div>)}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
  playStoreUrl: state.brandwrapper.playStoreUrl,
  appStoreUrl: state.brandwrapper.appStoreUrl,
  baseUrl: state.brandwrapper.baseUrl,
  completionMessage: state.questionnaire.completionMessage,
  isMobileDashboardEnabled: state.questionnaire.isMobileDashboardEnabled,
  isUserAbleToLogin: state.questionnaire.userResponse?.isUserAbleToLogin,
  guid: state.questionnaire.guid,
  redirectUrl: state.brandwrapper.redirectUrl,
});


export default connect(
  mapStateToProps,
  null,
)(withRouter(SuccessPage));
