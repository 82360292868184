import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import UserSvg from '../../../../../assets/images/svg/user.svg';
import GroupUserSvg from '../../../../../assets/images/svg/groupUser.svg';
import ChevronUpIcon from '../../../../../assets/images/svg/chevron-up-icon.svg';
import ChevronDownIcon from '../../../../../assets/images/svg/chevron-down-icon.svg';
import CommunitySvg from './svg/CommunitySvg';
import UserIconSvg from './svg/UserIconSvg';

class SatisfactionComparesActive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accoIndex: 0,
      showAllDomains: true,
    };

    this.showAccordian = this.showAccordian.bind(this);
  }

  showAccordian(index, domainDescription) {
    if (this.state.accoIndex === index) {
      this.setState({ accoIndex: 0 });
    } else {
      this.setState({ accoIndex: domainDescription ? index : 0 });
    }
  }

  toggleAllDomainScore = (showAllDomains) => {
    this.setState({ showAllDomains });
  }

  render() {
    const { accoIndex, showAllDomains } = this.state;
    const {
      selectedProjectMeasuring,
      overAllScore,
      userScore,
      userScoreColor,
      domainConfidenceSummary,
      trendStartDate,
    } = this.props.projectData;
    return (
      <React.Fragment>
        <div className='content'>
          <h2 className='name'>How does your {selectedProjectMeasuring} compare? </h2>
          <span className='date'>Since {trendStartDate}</span>
          <div className='options'>
            <a className={`butn mr-2 ${this.state.showAllDomains ? 'butn-theme' : ''}`} style={{ cursor: 'pointer' }} onClick={() => this.toggleAllDomainScore(true)}>All Focus Areas</a>
            <a className={`butn ${!this.state.showAllDomains ? 'butn-theme' : ''}`} style={{ cursor: 'pointer' }} onClick={() => this.toggleAllDomainScore(false)}>Individual Focus Areas</a>
          </div>

          {showAllDomains && (
            <div>
              <div className='compareBarIconContainer'>
                <div className='compareBaroverAllIcon'
                  style={{
                    left: `${overAllScore || 0}%`,
                  }}
                >
                  <CommunitySvg />
                </div>
                <div className='compareBarPersonalIcon'
                  style={{ left: `${userScore || 0}%` }}
                >
                  <UserIconSvg userScoreColor={userScoreColor} />
                </div>
              </div>
              <div className='compareBarContainer'>
                <div className='compareBarLow'>
                  <p className='compareBarText'>0-50</p>
                </div>
                <div className='compareBarModerate'>
                  <p className='compareBarText'>51-79</p>
                </div>
                <div className='compareBarHigh'>
                  <p className='compareBarText'>80-100</p>
                </div>
              </div>

              <div className='compareBarContainer'>
                <p className='compareBarLowText'>LOW</p>

                <p className='compareBarModerateText'>
                          MODERATE
                </p>

                <p className='compareBarHightText'>HIGH</p>
              </div>
            </div>
          )}
          {!showAllDomains && (
            <div className='accordian-wrap'>

              <div className='icons-list'>
                <img src={UserSvg} alt="icon" />
                <img src={GroupUserSvg} alt="icon" />
              </div>
              {domainConfidenceSummary.map((domain, index) => (<React.Fragment>
                <div className="custom-accordion" onClick={() => this.showAccordian(index + 1, domain && domain.description)}>
                  <span className='domain-name d-flex align-items-center'>
                    {domain && domain.description && <img src={accoIndex === index + 1 ? ChevronUpIcon : ChevronDownIcon} alt="icon" />}
                    <span className='name'>{domain.title}</span>
                  </span>
                  <div className='values'>
                    <span style = {{ width: '55px', border: `1px solid ${domain.personalScoreColor}`, color: domain.personalScoreColor }}>{domain.personalScoreDisplayValue}</span>
                    <span style = {{ width: '55px', border: `1px solid ${domain.overallScoreColor}`, color: domain.overallScoreColor }}>{domain.overallScoreDisplayValue}</span>
                  </div>
                </div>
                <div className={`panel ${accoIndex === index + 1 ? 'd-block' : ''}`}>
                  <p>{(domain && domain.description) ? domain.description
                    : 'No Description Provided'}</p>
                </div>
              </React.Fragment>))}
            </div>)}
        </div>
      </React.Fragment>

    );
  }
}

export default SatisfactionComparesActive;
