import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './ProgressCard.css';


class ProgressCard extends React.Component {
  render() {
    const {
 title, percentage, psDashboardShow, isQuestionAvailable, showInsightScreen,
} = this.props;
    return (
      <React.Fragment>
        <div className='row' style={{
          width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center',
        }}>
          <div className='column' style={{ width: 'calc(100% - 80px)' }}>
            <div className="card-title">{title}
            </div>
            {
              psDashboardShow === 0 && !isQuestionAvailable && !showInsightScreen
                ? <div className="card-status" style={{ background: '#EAF1FE' }}>

                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 0H1C0.448334 0 0 0.448334 0 1V11C0 11.5517 0.448334 12 1 12H11C11.5517 12 12 11.5517 12 11V1C12 0.448334 11.5517 0 11 0ZM11.25 11.25H7L6 9.5H0.746665V0.75H11.25V11.25Z" fill="#003DAB" />
                      <path d="M3.5 6H2.5V8H3.5V6Z" fill="#003DAB" />
                      <path d="M5.5 4H4.5V8H5.5V4Z" fill="#003DAB" />
                      <path d="M7.5 5H6.5V8H7.5V5Z" fill="#003DAB" />
                      <path d="M9.5 3H8.5V8H9.5V3Z" fill="#003DAB" />
                    </svg>
                    <spam style={{ color: '#003DAB' }}> RESULTS AVAILABLE</spam>

                  </div>
                : <> {isQuestionAvailable
                  ? <div className="card-status"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_4957_2706)">
                    <path d="M6.50238 0.5C4.14471 0.501255 2.00618 1.88208 1.03586 4.02968C0.0655286 6.17727 0.443177 8.69376 2.0012 10.4623L2.53134 10.9922C4.79434 12.9985 8.20041 12.9985 10.4634 10.9922L11.0036 10.4623C12.5616 8.69376 12.9392 6.17727 11.9689 4.02968C10.9986 1.88208 8.86005 0.501255 6.50238 0.5ZM6.50238 1.2498C9.40263 1.2498 11.7538 3.59967 11.7538 6.49839C11.751 9.39596 9.40149 11.7442 6.50238 11.747C3.60213 11.747 1.25101 9.39711 1.25101 6.49839C1.25101 3.59967 3.60213 1.2498 6.50238 1.2498ZM6.50085 8.24847C6.22494 8.24847 6.00085 8.47256 6.00085 8.74847C6.00085 9.02491 6.22494 9.24847 6.50085 9.24847C6.77677 9.24847 7.00085 9.02491 7.00085 8.74847C7.00085 8.47256 6.77677 8.24847 6.50085 8.24847ZM6.50085 3.24847C6.22471 3.24847 6.00085 3.47499 6.00085 3.74699V6.74996C6.00085 7.02528 6.22279 7.24847 6.50085 7.24847C6.777 7.24847 7.00085 7.02196 7.00085 6.74996V3.74699C7.00085 3.47167 6.77892 3.24847 6.50085 3.24847Z" fill="#7D7D7D" />
                  </g>
                  <defs>
                    <clipPath id="clip0_4957_2706">
                      <rect width="12" height="12" fill="white" transform="translate(0.5 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
                  IN PROGRESS
                </div>
                  : <div className="card-status" style={{ background: '#E9EFEA' }}>
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 0C9.814 0 12.5 2.6865 12.5 6C12.5 9.3135 9.814 12 6.5 12C3.1865 12 0.5 9.3135 0.5 6C0.5 2.6865 3.1865 0 6.5 0ZM6.5 11.25C9.395 11.25 11.75 8.895 11.75 6C11.75 3.105 9.395 0.75 6.5 0.75C3.605 0.75 1.25 3.105 1.25 6C1.25 8.895 3.605 11.25 6.5 11.25ZM5.75505 6.9719L8.74905 3.9669L9.28005 4.4964L5.75555 8.0334L4.02605 6.3034L4.55605 5.7734L5.75505 6.9719Z" fill="#175C2C" />
                  </svg>
                  <spam style={{ color: '#175C2C' }}> COMPLETED</spam>
                </div>
                }</>
            }

          </div>
          <div className="progress-bar-card-container">
            {percentage <= 50
              ? <CircularProgressbar
                styles={buildStyles({
                  pathColor: '#c52a1a',
                  textColor: '#c52a1a',
                  // trailColor: 'transparent',
                  trailColor: '#d6d6d6',
                  backgroundColor: '#3e98c7',
                })}
                value={percentage === -1 ? 0 : percentage}
                minValue={0}
                text={ percentage > 1 && `${percentage}%`} />
              : <>
                {percentage <= 79
                  ? <CircularProgressbar
                    styles={buildStyles({
                      textColor: '#FFBF1F',
                      pathColor: '#FFBF1F',
                      // trailColor: 'transparent',
                      trailColor: '#d6d6d6',
                      backgroundColor: '#3e98c7',
                    })}
                    value={percentage === -1 ? 0 : percentage}
                    minValue={0}
                    text={ percentage > 1 && `${percentage}%`} />
                  : <CircularProgressbar
                    styles={buildStyles({
                      textColor: '#22992E',
                      pathColor: '#22992E',
                      // trailColor: 'transparent',
                      trailColor: '#d6d6d6',
                      backgroundColor: '#3e98c7',
                    })}
                    value={percentage === -1 ? 0 : percentage}
                    minValue={0}
                    text={ percentage > 1 && `${percentage}%`} />
                }</>
            }

          </div>
        </div>
      </React.Fragment>

    );
  }
}

export default ProgressCard;
