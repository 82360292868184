import React from 'react';

const UserIconSvg = (props) => {
  const { userScoreColor } = props;
  return (
    <svg
      width="50px"
      height="50"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
    >
      <path
        vector-effect="non-scaling-stroke"
        fill={userScoreColor}
        d="M49.962,93.129L49.962,93.129c-1.885,0-3.578-1.097-4.367-2.789    l-8.888-19.257c-12.158-5.252-20.18-17.333-20.18-30.703C16.527,21.931,31.532,6.907,50,6.907S83.473,21.912,83.473,40.38    c0,13.37-8.041,25.471-20.199,30.742l-8.945,19.238C53.54,92.052,51.828,93.129,49.962,93.129z M50,16.526    c-13.158,0-23.855,10.696-23.855,23.855c0,10.023,6.329,19.045,15.756,22.431c1.212,0.442,2.193,1.347,2.732,2.501l5.348,11.562    l5.367-11.562c0.539-1.154,1.52-2.058,2.732-2.501c9.426-3.405,15.775-12.408,15.775-22.45C73.855,27.222,63.158,16.526,50,16.526    z"
      />
      <path
        fill={userScoreColor}
        d="M63.846,43.107c-2.23-3.812-6.113-4.172-6.113-4.172H54.46c-1.331,0.755-2.841,1.223-4.495,1.223    c-1.654,0-3.165-0.432-4.495-1.223h-3.272c0,0-3.884,0.36-6.113,4.172c-2.158,3.812-1.762,7.624-1.762,7.624    s3.416,3.416,15.715,3.416s15.643-3.416,15.643-3.416S66.075,46.919,63.846,43.107z M50.001,36.886    c4.064,0,7.336-3.272,7.336-7.336s-3.272-7.336-7.336-7.336s-7.336,3.308-7.336,7.336C42.665,33.614,45.937,36.886,50.001,36.886z    "
      />
    </svg>
  );
};

export default UserIconSvg;
