import { actionType } from '../../../../redux/modules/common/overlay';

// Action creater for overlay
export function showHideOverlay(overlayFlag) {
  return { type: actionType.SHOW_OVERLAY_FLAG, overlayFlag };
}

export default {
  showHideOverlay,
};
