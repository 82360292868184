import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import accountService from '../../../../redux/services/accountService';
import accountConstant from '../../../account/actions/constant';
import authService from '../../../../redux/services/authService';
import {
  changeSelectedProject, getProjects, setSelectedProjectTitle, setSideDrawerProjectList,
  isUserAbleToChangeAppModeOptions,
} from '../../../project/actions/project';
import projectConstant from '../../../project/constant/constant';
import { showHideOverlay } from '../../../common/overlay/actions/overlay';
import { deleteMyAccount } from '../../../account/actions/account';
import constant from '../../actions/constants';
import StackLayersSvg from './svg/StackLayersSvg';
import CrossIconSvg from './svg/CrossIconSvg';
import DeleteAccountConfirmation from './DeleteAccountConfirmation.jsx';
import RenderIf from '../../../../ui-components/Common';
import CircleWarning from '../../../../../assets/images/circle-checkmark-outline.png';
import CircleCheck from '../../../../../assets/images/circle-warning-outline.png';
import Circlepath from '../../../../../assets/images/Clip-path-group.png';
import switchModeSvg from '../../../../../assets/images/svg/switch-mode.svg';


class SideDrawer extends React.Component {
  constructor(props) {
    super(props);
    const { firstName, lastName, user_id: userId } = authService.getUserInfo();
    this.state = {
      firstName: firstName.charAt(0),
      lastName: lastName.charAt(0),
      fullName: `${firstName} ${lastName}`,
      userId,
      selectedLiIndex: null,
      selectedProjectName: '',
      projectColor: '',
      projects: [],
      selectedProjectId: parseInt(sessionStorage.getItem(projectConstant.selectedProjectId), 10),
      showDeleteModal: false,
    };
    this.signOut = this.signOut.bind(this);
  }

  componentDidMount() {
    this.getProjects();
    this.props.isUserAbleToChangeAppModeOptions();
  }

  handleLiClick = (index) => {
    this.setState({ selectedLiIndex: index });
  };

  deleteModal = () => {
    this.setState({ showDeleteModal: true });
  }

  cancelDelete = () => {
    this.setState({ showDeleteModal: false });
  }

  deleteUser = () => this.props
    .deleteMyAccount()
    .then((res) => {
      this.signOut();
      return res;
    })

  getProjects = () => {
    this.props.getProjects()
      .then((response) => {
        if (response && response.data) {
          const selectedProjectData = response.data.filter(
            data => this.state.selectedProjectId === data.ProjectId,
          );
          const selectedProjectName = selectedProjectData.length
            ? selectedProjectData[0].ProjectName : 'All Topics';
          this.props.setSelectedProjectTitle(selectedProjectName);
          this.props.setSideDrawerProjectList(response.data);
          this.setState({ projects: response.data, isLoading: false });
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }


  onProjectSelect = async (project) => {
    this.props.showHideOverlay(false);
    const selectedProjectId = project ? project.ProjectId : null;
    const selectedProjectName = project ? project.OriginalProjectName : '';
    const projectColor = project && project.Config ? project.Config.color : '#000';
    this.setState({
      selectedProjectId,
      isLoading: true,
      isNotificationPreferenceSelected: false,
      selectedProjectName,
      projectColor,
    });
    sessionStorage.setItem(projectConstant.selectedProjectId, selectedProjectId);
    await this.props.changeSelectedProject({
      selectedProjectId,
      selectedProjectName,
      projectColor,
    });
    this.props.history.push(accountConstant.personalDashboard);
    if (this.props.location.pathname === accountConstant.personalDashboard) {
      this.props.getProjectDashboardData(selectedProjectId);
    }
  }

  signOut() {
    accountService.logUserSignOut(
      this.state.userId,
      accountConstant.logSignOutMessages.manualSignOutMessage,
    ).then(() => {
      document.cookie = 'ppid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      authService.clearAllStorage();
      // eslint-disable-next-line no-undef
      window.location = `${__CONFIG__.iamSignoutUri}${this.props.redirectUrl}`;
    });
  }

  Notification() {
    this.props.showHideOverlay(false);
    this.props.history.push(constant.notification);
  }

  navigateToPerspectiveApp() {
    window.location.replace(`${__CONFIG__.frontendRedirectUrl}getting-started?back=true`);
  }

  render() {
    const { isOpen, isUserAbleToChangeMode } = this.props;
    const {
      selectedProjectId, projects, firstName, lastName, fullName, showDeleteModal,
    } = this.state;
    return (
      <React.Fragment>
        <RenderIf showComponent={showDeleteModal}>
          <DeleteAccountConfirmation
            title={constant.deleteModel.title}
            message={constant.deleteModel.message}
            confirmationClick={this.deleteUser}
            cancelClick={this.cancelDelete}
          />
        </RenderIf>
        <div className={`side-drawer ${isOpen ? 'open' : ''}`}>
          <div className="profile">
            {/* <img className="profile-image" src={profileImge} alt="Profile" /> */}
            <div className="profileImage">
              {firstName.toUpperCase()}
              {lastName.toUpperCase()}
            </div>
            <h3 className="profile-name">{fullName}</h3>
            {isOpen && <span onClick={() => this.props.showHideOverlay(false)} id='closeicon'><CrossIconSvg /></span>}
          </div>
          <div className="data-scroller-container content" >
            <p className='all-topics'><Link to="/all-topics"><span><StackLayersSvg /></span> All Topics</Link></p>
            <ul style={{ listStyleType: 'none' }}>
              {projects?.map((project, index) => (
                <li key={`${index}-${project.ProjectId}`} className={selectedProjectId === project.ProjectId ? 'selected active' : ''}
                  onClick={() => this.onProjectSelect(project)} style={{ 'color::before': project.Config ? project.Config.color : '#000' }}>
                  <div className="li-text-container">
                    <div className='text-container'>
                      {
                        project.isShowSelectedProjectDashboardMobile === 0
                          && !project.isQuestionAvailable && project.isAnonymityCriteriaMet
                          ? < img src={Circlepath} style={{ marginBottom: '0px' }}></img>
                          : <> {project.isQuestionAvailable
                            ? < img src={CircleCheck} style={{ marginBottom: '0px' }}></img>
                            : < img src={CircleWarning} style={{ marginBottom: '0px' }}></img>
                          }</>
                      }

                      <span className='li-text'>{project.ProjectName}{' '}</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className='bottom-div'>
            {isUserAbleToChangeMode && <div className="footer-item">
              {/* <a onClick={()=> this.Notification()} style={{ cursor: 'pointer' }}> */}
              <img src={switchModeSvg} />
              <span className='bottom-span' style={{ color: '#7d7d7d', cursor: 'pointer' }} onClick={() => this.navigateToPerspectiveApp()} >Switch Mode</span>
              {/* </a> */}
            </div>}
            <div className="footer-item">
              {/* <a onClick={()=> this.Notification()} style={{ cursor: 'pointer' }}> */}
              <i class="fa fa-cog" aria-hidden="true" id='icon'></i>
              <span className='bottom-span'><Link to="/notification-setting" style={{ color: '#7d7d7d' }}>Notification Preferences</Link></span>
              {/* </a> */}
            </div>
            <div className="footer-item">
              <a onClick={this.deleteModal} style={{ cursor: 'pointer' }}>
                <i class="fa fa-user-times" aria-hidden="true" id='icon'></i>
                <span className='bottom-span'>Delete Account</span>
              </a>
            </div>
            <div className="footer-item">
              <a onClick={this.signOut} style={{ cursor: 'pointer' }}>
                <i class="fa fa-cog" aria-hidden="true" id='icon'></i>
                <span className='bottom-span'>Logout</span>
              </a>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  redirectUrl: state.brandwrapper.redirectUrl,
  isUserAbleToChangeMode: state.project.isUserAbleToChangeMode,
});

const mapDispatchToProps = {
  changeSelectedProject,
  getProjects,
  showHideOverlay,
  setSelectedProjectTitle,
  deleteMyAccount,
  setSideDrawerProjectList,
  isUserAbleToChangeAppModeOptions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SideDrawer));
