const SET_USER_PROJECT_QUESTIONNAIRE = 'SET_USER_PROJECT_QUESTIONNAIRE';
const RESPOND_USER_PROJECT_QUESTIONNAIRE = 'RESPOND_USER_PROJECT_QUESTIONNAIRE';
const SET_FIRST_TIME_USER_FALSE = 'SET_FIRST_TIME_USER_FALSE';
const UNDO_RESPONSE_QUESTION = 'UNDO_RESPONSE_QUESTION';
const UPDATE_LAST_QUESTION_CARD_ID = 'UPDATE_LAST_QUESTION_CARD_ID';

export default {
  SET_USER_PROJECT_QUESTIONNAIRE,
  RESPOND_USER_PROJECT_QUESTIONNAIRE,
  SET_FIRST_TIME_USER_FALSE,
  UNDO_RESPONSE_QUESTION,
  UPDATE_LAST_QUESTION_CARD_ID,
};
