import Cryptr from 'cryptr';
import constant from '../../modules/account/actions/constant';

const cryptr = new Cryptr(__CONFIG__.encryptSecret);

class authService {
  static isAuthenticated() {
    if (this.getUserInfo()) {
      return true;
    }
    return false;
  }

  static getUserInfo() {
    const authCookie = document.cookie.match('ppid');
    if (authCookie !== 'null' && authCookie !== undefined && authCookie !== null) {
      const allAuthCookies = authCookie.input;
      if (allAuthCookies !== 'null' && allAuthCookies !== undefined && allAuthCookies !== null) {
        const authCookieArray = allAuthCookies.split(';');
        let authCookieEncryptedValue = '';
        authCookieArray.map((item) => {
          const itemValue = item.split('=');
          const [title, ppid] = itemValue;
          if (itemValue && title.trim() === 'ppid') {
            authCookieEncryptedValue = ppid;
          }
          return item;
        });
        if (authCookieEncryptedValue !== null && authCookieEncryptedValue !== undefined && authCookieEncryptedValue !== '') {
          const authCookieValue = cryptr.decrypt(authCookieEncryptedValue);
          if (authCookieValue !== 'null' && authCookieValue !== undefined && authCookieValue !== null) {
            const user = JSON.parse(authCookieValue);
            return user;
          }
        }
      }
    }
    return false;
  }

  static setSignoutUserEmail(email) {
    localStorage.setItem(constant.signoutUserEmailContainer, email);
  }

  static removeSignoutUserEmail() {
    localStorage.removeItem(constant.signoutUserEmailContainer);
  }

  static getSignoutUserEmail() {
    return localStorage.getItem(constant.signoutUserEmailContainer);
  }

  static setRedirectUrl(url) {
    localStorage.setItem(constant.redirectUrl, url);
  }

  static removeRedirectUrl() {
    localStorage.removeItem(constant.redirectUrl);
  }

  static getRedirectUrl() {
    return localStorage.getItem(constant.redirectUrl);
  }

  static setRedirectUserEmail(email) {
    localStorage.setItem(constant.redirectUserEmail, email);
  }

  static removeRedirectUserEmail() {
    localStorage.removeItem(constant.redirectUserEmail);
  }

  static getRedirectUserEmail() {
    return localStorage.getItem(constant.redirectUserEmail);
  }

  static clearAllStorage() {
    return localStorage.clear();
  }
}

export default authService;
