import actionType from './actionType';

// Configured initial state for axios
const initialState = {
  showOverlay: false,
  isDrawerOpen: false,
};

// Creating overlay reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
  case actionType.SHOW_OVERLAY_FLAG:
    return {
      ...state,
      showOverlay: action.overlayFlag,
      isDrawerOpen: action.overlayFlag,
    };
  default:
    return state;
  }
};
