import React, { Component } from 'react';

class Spinner extends Component {
  render() {
    const { isLoading } = this.props;

    return (
      <React.Fragment>
        {isLoading && (
          <button className="a-btn a-btn-transparent a-btn-lg a-no-interaction theme-loader">
            <i className="a-loading-one a-primary" />
          </button>
        )}
      </React.Fragment>
    );
  }
}

export default Spinner;
