import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FooterContent } from '../../../ui-components/PageContent';
import SideDrawer from './components/SideDrawer';
import { showHideOverlay } from '../../common/overlay/actions/overlay';
import HamburgerSvg from './components/svg/HamburgerSvg';
import OverlayBackdrop from '../../common/containers/OverlayBackdrop/OverlayBackdrop';
import {
  getAllProjectsDashboardData,
  changeSelectedProject,
} from '../../project/actions/project';
import accountConstant from '../../account/actions/constant';
import projectConstant from '../../project/constant/constant';
import accountService from '../../../redux/services/accountService';

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDarkMode: 'false',
      email_new_projects: 'false',
      notifyToggle: {},
      data: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    this.getAllProjectsDashboardData();
    this.props.showHideOverlay(false);
  }

    handleDrawerToggle = () => {
      this.props.showHideOverlay(true);
    };

    getAllProjectsDashboardData = () => {
      accountService.getLoggedInUser().then((res) => {
        this.setState({
          notifyToggle: res.data && res.data[0] ? res.data[0] : {},
          data: res.data && res.data[0],
          isLoading: false,
        });
      });
    }

    onSelectedTiles = async (cardDetails) => {
      sessionStorage.setItem(projectConstant.selectedProjectId, cardDetails.projectId);
      await this.props.changeSelectedProject({
        selectedProjectId: cardDetails.projectId,
        selectedProjectName: cardDetails.projectName,
        projectColor: cardDetails.userScoreColor,
      });
      this.props.history.push(accountConstant.personalDashboard);
    }

    function() {
      this.setState({ isDarkMode: 'true' });
    }

    changeProjectIsActive() {
    }

    async setNewProjectEmailNotification(isEmailNewProject) {
      const toggleInfo = this.state.notifyToggle;
      toggleInfo.email_new_projects = isEmailNewProject;
      this.setState({ notifyToggle: toggleInfo });
      accountService.toggleEmailNotification(toggleInfo);
    }

    async setNewQuestionEmailNotification(isEmailNewQuestion) {
      const toggleInfo = this.state.notifyToggle;
      toggleInfo.email_new_questions = isEmailNewQuestion;
      this.setState({ notifyToggle: toggleInfo });
      accountService.toggleEmailNotification(toggleInfo);
    }

    async setOverdueQuestionEmailNotification(isEmailOverdueQuestion) {
      const toggleInfo = this.state.notifyToggle;
      toggleInfo.email_overdue_questions = isEmailOverdueQuestion;
      this.setState({ notifyToggle: toggleInfo });
      accountService.toggleEmailNotification(toggleInfo);
    }

    render() {
      const { isDrawerOpen } = this.props;
      const { notifyToggle } = this.state;

      return (
        <React.Fragment>
          <OverlayBackdrop />
          <SideDrawer
            isOpen={isDrawerOpen}
          />
          <div class="d-block overflow-hidden">
            <div class="bg-orange-img">
              <div className="drawer">
                <span className={`hamburger ${isDrawerOpen ? 'open' : ''}`} onClick={this.handleDrawerToggle}>
                  <HamburgerSvg />
                </span>
              </div>
              <span class="bg-title">Email Notification Preferences</span>

            </div>
            <React.Fragment>
              <div>
                <div className='content-wrap main-content'>
                  <div className='container'>
                    {notifyToggle ? <div className=''>
                      <div>

                        <div className="form-group d-flex align-items-center notify-toggle">
                          <label className="a-toggle a-m-5 font-normal">
                            <input
                              type="checkbox"
                              name="togle1"
                              checked={notifyToggle.email_new_projects}
                              onChange={this.changeProjectIsActive
                                .bind(this)}
                              onClick={() => {
                                const toggleInfo = this.state.notifyToggle;
                                const emailNewProject = !toggleInfo.email_new_projects;
                                this.setNewProjectEmailNotification(emailNewProject);
                              }}
                            />
                            <div className="a-toggle-mark">
                              <span className="on">Yes</span>
                              <span className="off">No</span>
                              <span className="switch" />
                            </div>
                          </label>
                          <div>
                            <span className="a-toggle-text">New Topics</span>
                            <p>
                                Notify me when I am assigned as a stakeholder of new topic
                            </p>

                          </div>
                        </div>


                        <div className="form-group d-flex align-items-center notify-toggle">
                          <label className="a-toggle a-m-5 font-normal">
                            <input
                              type="checkbox"
                              name="toggle1"
                              checked={notifyToggle.email_new_questions}
                              onClick={() => {
                                const toggleInfo = this.state.notifyToggle;
                                const emailNewQuestion = !toggleInfo.email_new_questions;
                                this.setNewQuestionEmailNotification(emailNewQuestion);
                              }}

                            />
                            <div className="a-toggle-mark">
                              <span className="on">Yes</span>
                              <span className="off">No</span>
                              <span className="switch" />
                            </div>
                          </label>
                          <div>
                            <span className="a-toggle-text">New Questions</span>
                            <p> Notify me when new
                            questions are added to my backlog that require my response.</p>

                          </div>
                        </div>


                        <div className="form-group d-flex align-items-center notify-toggle">
                          <label className="a-toggle a-m-5 font-normal">
                            <input
                            // disabled={showProcessingRecords}
                              type="checkbox"
                              name="toggle1"
                              checked={notifyToggle.email_overdue_questions}
                              // onChange={this.changeProjectIsActive
                              //   .bind(this, values, setFieldValue)}
                              onClick={() => {
                                const toggleInfo = this.state.notifyToggle;
                                const emailOverdueQuestions = !toggleInfo.email_overdue_questions;
                                this.setOverdueQuestionEmailNotification(emailOverdueQuestions);
                              }}
                            />
                            <div className="a-toggle-mark">
                              <span className="on">Yes</span>
                              <span className="off">No</span>
                              <span className="switch" />
                            </div>
                          </label>
                          <div>
                            <span className="a-toggle-text">Overdue Questions</span>
                            <p>Notify me when I
                        have overdue questions in my backlog that I still need to respond to.</p>

                          </div>
                        </div>
                      </div>
                    </div> : null}

                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
          <footer class="footer-btm">
            <FooterContent></FooterContent>
          </footer>
        </React.Fragment>
      );
    }
}

const mapStateToProps = state => ({
  showOverlay: state.overlay.showOverlay,
  isDrawerOpen: state.overlay.isDrawerOpen,
  selectedProject: state.project.selectedProject,
});

const mapDispatchToProps = {
  showHideOverlay,
  getAllProjectsDashboardData,
  changeSelectedProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notifications));
