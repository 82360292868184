const USER_PROJECT_QUESTIONS = 'questionnaire/get-user-project-questions';
const RESPOND_USER_PROJECT_QUESTION = 'questionnaire/respond-question/';
const SKIP_USER_PROJECT_QUESTION = 'questionnaire/skip-question/';
const SET_FIRST_TIME_USER_FALSE = 'questionnaire/update-new-user/';
const FETCH_CSRF_TOKEN = 'initiate-csrf-token';
const UNDO_RESPONSE_QUESTION = 'questionnaire/undo-response/';

export default {
  USER_PROJECT_QUESTIONS,
  RESPOND_USER_PROJECT_QUESTION,
  SKIP_USER_PROJECT_QUESTION,
  SET_FIRST_TIME_USER_FALSE,
  FETCH_CSRF_TOKEN,
  UNDO_RESPONSE_QUESTION,
};
