import axios from 'axios';
import iam from '../constants/iam';

class iamService {
  static validateUserByEmail(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.VALIDATE_USER_BY_EMAIL}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static validateOnDemandUserByEmail(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.VALIDATE_ON_DEMAND_USER_BY_EMAIL}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static checkIfUserRestrictedToLogin(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.CHECK_USER_RESTRICTED_TO_LOGIN}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static resendAccountActivationEmail(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.RESEND_ACCOUNT_ACTIVATION_EMAIL}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static createNewUser(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.CREATE_NEW_USER}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static registerUser(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.REGISTER_USER}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static isUserAlreadyRegistered(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.IS_USER_ALREADY_REGISTERED}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static updateMobileNumber(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.UPDATE_MOBILE_NUMBER}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static updateUserName(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.UPDATE_USER_NAME}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static changeMobileNumber(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${iam.CHANGE_MOBILE_NUMBER}`, data)
      .then(res => res)
      .catch(err => err);
  }
}

export default iamService;
