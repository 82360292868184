import iamService from '../../../redux/services/iamService';

// Creating thunk action creaters which will be access from component
function validateUserByEmail(userData) {
  return () => iamService
    .validateUserByEmail(userData)
    .then(response => response)
    .catch(error => error);
}

function validateOnDemandUserByEmail(userData) {
  return () => iamService
    .validateOnDemandUserByEmail(userData)
    .then(response => response)
    .catch(error => error);
}

function checkIfUserRestrictedToLogin(userData) {
  return () => iamService
    .checkIfUserRestrictedToLogin(userData)
    .then(response => response)
    .catch(error => error);
}

function createNewUser(userData) {
  return () => iamService
    .createNewUser(userData)
    .then(response => response)
    .catch(error => error);
}

function resendAccountActivationEmail(userData) {
  return () => iamService
    .resendAccountActivationEmail(userData)
    .then(response => response)
    .catch(error => error);
}


export {
  validateUserByEmail,
  createNewUser,
  validateOnDemandUserByEmail,
  resendAccountActivationEmail,
  checkIfUserRestrictedToLogin,
};
