
/* eslint-disable max-len */

const isTouchDevice = () => (('ontouchstart' in window)
     || (navigator.maxTouchPoints > 0)
     || (navigator.msMaxTouchPoints > 0));

const checkDeviceType = () => window.navigator.userAgent.match(/Android/i)
     || window.navigator.userAgent.match(/webOS/i)
     || window.navigator.userAgent.match(/iPhone/i)
     || window.navigator.userAgent.match(/iPad/i)
     || window.navigator.userAgent.match(/iPod/i)
     || window.navigator.userAgent.match(/BlackBerry/i)
     || window.navigator.userAgent.match(/Windows Phone/i) || isTouchDevice();

export default checkDeviceType;
