import React from 'react';

class CrossIconSvg extends React.Component {
  render() {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1815 0L8.0005 6.181L1.819 0L0 1.819L6.1815 8L0.0005 14.181L1.8195 15.9995L8.0005 9.819L14.181 15.9995L15.9995 14.181L9.819 8L16.0005 1.819L14.1815 0Z" fill="white"/>
      </svg>
    );
  }
}

export default CrossIconSvg;
