import React from 'react';
import MaterialIcon from 'material-icons-react';

class InstructionalOverlayHelp extends React.Component {
  render() {
    return (
    <div className='cursor-pointer help-icon' onClick={() => this.props.toggleInstructionOverlay(true)}>
      <div className='d-flex'>
        <MaterialIcon icon="help_outline" size={25} color={'#ffffff'}/>
        <h5> Help </h5>
      </div>
    </div>
    );
  }
}

export default InstructionalOverlayHelp;
