import React from 'react';

class SatisfactionComparesInActive extends React.Component {
  render() {
    const { selectedProjectMeasuring } = this.props.projectData;
    return (
      <div className='content'>
        <h2 className='name'>How does your {selectedProjectMeasuring} compare? </h2>
        <span className='date'>Thanks for being one of the first stakeholders to respond!</span>
        <p className='desc'>
          We haven't received enough responses from other
          stakeholders within this period to provide you with a
          meaningful comparison. We'll let you know once this is
          available.
        </p>
      </div>
    );
  }
}

export default SatisfactionComparesInActive;
