const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const accountConstant = {
  requestType: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
  },
  httpStatus: {
    Ok: 200,
    NotAuthenticated: 401,
    Forbidden: 403,
    NotFound: 404,
  },
  authStatus: {
    OK: 'OK',
    IAM_AUTH_ERROR: 'AUTHENTICATION_ERROR',
    PERSPECTIVE_AUTH_ERROR: 'INVALID_USER_ERROR',
    SUCCESS: 'SUCCESS',
    USER_REGISTRATION_ERROR: 'USER_REGISTRATION_ERROR',
  },
  authMessage: {
    IAM_AUTH_FAIL: 'We were unable to sign you in using the information you provided.',
    USER_REGISTRATION_FAIL: 'Please complete the account registration process before signing in.',
    PERSPECTIVE_AUTH_FAIL: 'Your Perspectives account does not currently have access to view results for an active topic. Please reach out to your PwC contact if you believe this is an error.',
  },
  deleteModel: {
    title: 'DELETE USER',
    message:
      'By continuing, you will lose access to all projects. Are you sure you want to continue?',
  },
  signInPage: '/sign-in',
  firstSplash: '/first-splash',
  personalDashboard: '/personal-dashboard',
  homePage: '/all-topics',
  notification: '/notification-setting',
  accountActivationPage: '/account-activation',
  userRestrictedPage: '/user-restricted',
  accountConfirmationPage: '/account-confirmation',
  tokenContainer: 'auth-token',
  userContainer: 'user-container',
  emailContainer: 'email-container',
  signoutUserEmailContainer: 'signout-user-email-container',
  redirectUrl: 'redirectUrl',
  redirectUserEmail: 'redirectUserEmail',
  tokenType: 'Bearer',
  currentYear,
  logSignOutMessages: {
    inactivityThresholdMessage: 'Web app session expired due to inactivity threshold',
    maxSessionAgeMessage: 'Web app session expired due to maximum session duration',
    manualSignOutMessage: 'Web app session expired due to manual sign out',
    accountDeleteMessage: 'Web app session expired due to account deletion',
  },
  requestFor: 'insight',
  INVAILD_DEEPLINK_AUTH: 'Sorry, this link is associated with a different user. Please open a link associated with your account.',
  USER_STAKEHOLDER_ERROR: 'Only stakeholders can sign-in to the Project Perspectives web questionnaire app.',
};

export default accountConstant;
