import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deviceType } from 'react-device-detect';
import RenderIf from '../../../ui-components/Common';
import { MobileViewLoader } from '../../../ui-components/Loader';
import {
  SuccessPage, ErrorPage, GettingStarted, FooterContent, OrientationError,
} from '../../../ui-components/PageContent';
import {
  getUserProjectQuestionnaire,
  respondUserProjectQuestionnaire,
  setFirstTimeUserFalse,
  undoResponseQuestion,
} from '../actions/questionnaire';
import '../styles/questionaire.scss';
import questionnaireConstant from '../actions/constants';
import getGUIDFromURL from '../../../helpers/getGuidHelper';
import CardComponent from './CardComponent';
import setOrientationName from '../../OrientationWrapper/actions/orientationwrapper';

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responseText: null,
      isTablet: false,
      currentCardId: 0,
      showErrorMessage: false,
      isSpinnerLoading: true,
      clickedButton: '',
      isKeyPress: false,
      isOpenTextSubmiting: false,
      showOptionalComment: true,
      isQuestionPolarity: false,
      undoResponseClicked: true,
      timer: null,
      token: null,
      isNewUserState: '',
      potrait: 'true',
    };
    this.getUserProjectQuestionnaire = this.getUserProjectQuestionnaire.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.setFirstTimeUserFalse = this.setFirstTimeUserFalse.bind(this);
    this.stopKeyboardPress = this.stopKeyboardPress.bind(this);
  }

  componentDidMount() {
    if (deviceType === 'browser') {
      const orientation = 'true';
      this.props.setOrientationName(orientation);
    } else if (window.innerHeight > window.innerWidth) {
        const orientation = 'true';
        this.props.setOrientationName(orientation);
    } else {
      const orientation = 'false';
      this.props.setOrientationName(orientation);
    }
    const token = getGUIDFromURL(window.location.href);
    const isTablet = !!(navigator.userAgent.toLowerCase().match(/ipad/i) || navigator.userAgent.toLowerCase().match(/x11/i) || navigator.userAgent.toLowerCase().match(/android/i));
    this.setState({ token, isTablet });
    this.getUserProjectQuestionnaire(token);
  }

  getUserProjectQuestionnaire(token) {
    this.props.getUserProjectQuestionnaire(token).then(() => {
      setTimeout(() => {
        this.setState({ isSpinnerLoading: false });
      }, 2000);
    });
  }

  stopKeyboardPress() {
    this.setState({ isKeyPress: true });
  }

  setFirstTimeUserFalse() {
    const { token } = this.state;
    const data = {
      token,
    };
    this.setState({ isNewUserState: this.props.questionnaire.isNewUser });
    this.props.setFirstTimeUserFalse(data);
  }

  handleEnter(event) {
    const { responseText } = this.state;
    if (event.target.value.length > questionnaireConstant.OPTIONAL_COMMENT_LIMIT) {
      event.preventDefault();
    } else {
      if (event.key === 'Enter' && responseText !== null && responseText !== undefined && responseText.trim() !== '') {
        this.submitResponse();
      }
      if (event.key === 'Enter' && (responseText === null || responseText === undefined || responseText.trim() === '')) {
        this.setState({ showErrorMessage: true });
        setTimeout(() => {
          this.setState({ showErrorMessage: false });
        }, 1500);
      }
    }
  }


  cardRotateAnimationHandler = (responseBool) => {
    if (responseBool === true) {
      const el = document.querySelectorAll('.active-card.fade-in.c-card');
      el[el.length - 1].style.cssText = 'transform: rotate(30deg) translateY(-80px);';
    } else if (responseBool === false) {
      const el = document.querySelectorAll('.active-card.fade-in.c-card');
      el[el.length - 1].style.cssText = 'transform: rotate(-30deg) translateY(-80px);';
    }
  }

  updateUndoResponseClickHandler = (clickFlag) => {
    this.setState({ undoResponseClicked: clickFlag });
  }

  async undoResponse(type) {
    clearInterval(this.state.timer);
    const { lastCard, lastQuestionType, lastCardId } = this.props.questionnaire;
    this.setState({
      isKeyPress: false,
      showOptionalComment: false,
    });
    if (type === 'undo') {
      this.setState({
        clickedButton: type,
        undoResponseClicked: true,
      });
    }
    this.setState({
      currentCardId: lastCardId,
    });
    await this.props.undoResponseQuestion(
      {
        questionUserId: lastCardId,
        token: this.state.token,
      },
      this.props.questionnaire,
      lastCard,
      lastQuestionType,
    ).then(() => {
      this.setState({
        isQuestionPolarity: false,
        isOpenTextSubmiting: false,
        showOptionalComment: true,
        responseText: null,
        undoResponseClicked: true,
      });
    });
  }


  render() {
    const {
      userQuestionsData, isValidGuid, successfulClearBacklog, isNewUser,
      completionMessage, isMobileDashboardEnabled, measuringMessage,
      isWebDashboardEnabled,
    } = this.props.questionnaire;

    const { isSpinnerLoading, isTablet, token } = this.state;
    if ((this.props.orientationname === 'false' || this.props.orientationname === false) && (!successfulClearBacklog && !isSpinnerLoading)) {
      return <OrientationError />;
    }
    return (
      <React.Fragment>
        <MobileViewLoader isLoading={isSpinnerLoading}></MobileViewLoader>
        <div style={{ display: isSpinnerLoading ? 'none' : 'block' }}>

          <RenderIf showComponent={
            isValidGuid && !isNewUser && !successfulClearBacklog
            && userQuestionsData.length > 0}>
            <CardComponent token={token} isTablet={isTablet}
              updateUndoResponseClickHandler={this.updateUndoResponseClickHandler}
              isNewUserState={this.state.isNewUserState}
            />
          </RenderIf>
        </div>
        <RenderIf showComponent={isNewUser && !isSpinnerLoading}>
          <GettingStarted isTablet={isTablet} gettingStarted={() => this.setFirstTimeUserFalse()}>
          </GettingStarted>
        </RenderIf>
        <RenderIf showComponent={isValidGuid && !isNewUser
          && successfulClearBacklog && !isSpinnerLoading}>
          <>
            <SuccessPage
              isTablet={isTablet}
              CompletionMessage={completionMessage}
              isMobileDashboardEnabled={isMobileDashboardEnabled}
              isWebDashboardEnabled={isWebDashboardEnabled}
              measuringMessage={measuringMessage}
            />
            {
              !this.state.undoResponseClicked && (
                <div className="position-fixed w-100 undo-btn">
                  <div className="row justify-content-center top-0">
                    <div class="mx-auto offset-md-2">
                      <button
                        onClick={() => this.undoResponse(questionnaireConstant.BUTTON_TYPE.UNDO)}
                        onKeyPress={this.stopKeyboardPress} className="undo-response-button"
                      >
                        <small className="t-btn-text btn-undo">
                          <span className="arrowUndo">↻</span> Undo Response
                        </small>
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          </>
        </RenderIf>
        <RenderIf showComponent={(!isValidGuid && !isSpinnerLoading)}>
          <ErrorPage>
          </ErrorPage>
        </RenderIf>
        <RenderIf showComponent={(isNewUser || !isValidGuid || successfulClearBacklog)
          && !isSpinnerLoading}>
          <footer class="footer-btm">
            <FooterContent></FooterContent>
          </footer>
        </RenderIf>

      </React.Fragment >
    );
  }
}

const mapDispatchToProps = {
  getUserProjectQuestionnaire,
  respondUserProjectQuestionnaire,
  setFirstTimeUserFalse,
  undoResponseQuestion,
  setOrientationName,
};

const mapStateToProps = state => ({
  questionnaire: state.questionnaire,
  orientationname: state.orientationwrapper.orientationname,

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Questionnaire);
