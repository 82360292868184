import { actionType } from '../../../redux/modules/account';
import csrfActionType from '../../../redux/modules/csrftokenwrapper/actionType';
import accountService from '../../../redux/services/accountService';
import iamService from '../../../redux/services/iamService';

// Creating thunk action creaters which will be access from component
function login(authCode) {
  return () => accountService
    .signIn(authCode)
    .then(response => response)
    .catch(error => error);
}

function fetchCsrfToken() {
  return () => accountService
    .fetchCsrfToken()
    .then(response => response)
    .catch(error => error);
}

function registerUser(userInfo) {
  return () => iamService
    .registerUser(userInfo)
    .then(response => response)
    .catch(error => error);
}

function isUserAlreadyRegistered(email) {
  return () => iamService.isUserAlreadyRegistered(email)
    .then(response => response)
    .catch(error => error);
}

export const isLoggedInUserAdmin = isAdmin => (dispatch) => {
  dispatch({ type: actionType.IS_LOGGED_IN_USER_ADMIN, data: isAdmin });
};

function updateMobileNumber(userInfo) {
  return () => iamService
    .updateMobileNumber(userInfo)
    .then(response => response)
    .catch(error => error);
}

function updateUserName(userInfo) {
  return () => iamService
    .updateUserName(userInfo)
    .then(response => response)
    .catch(error => error);
}

function changeUserMobileNumber(userInfo) {
  return () => iamService
    .changeMobileNumber(userInfo)
    .then(response => response)
    .catch(error => error);
}

function showAccountRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.SHOW_ACCOUNT_RIGHTPANEL });
  };
}

function hideAccountRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.HIDE_ACCOUNT_RIGHTPANEL });
  };
}

function showChangePhoneNumberRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.SHOW_CHANGE_PHONE_NUMBER_RIGHTPANEL });
  };
}

function hideChangePhoneNumberRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.HIDE_CHANGE_PHONE_NUMBER_RIGHTPANEL });
  };
}

function setCsrfToken(token) {
  return (dispatch) => {
    dispatch({ type: csrfActionType.SET_CSRF_TOKEN, data: token });
  };
}

function setUserEmail(email) {
  return (dispatch) => {
    dispatch({ type: actionType.SET_USER_EMAIL, data: email });
  };
}

function isFirstTimeLogin(firstTimelogin) {
  return (dispatch) => {
    dispatch({ type: actionType.IS_FIRST_TIME_LOGIN, data: firstTimelogin });
  };
}

export {
  login, fetchCsrfToken, registerUser, isUserAlreadyRegistered, updateMobileNumber,
  updateUserName,
  showAccountRightPanel,
  hideAccountRightPanel,
  showChangePhoneNumberRightPanel,
  hideChangePhoneNumberRightPanel,
  changeUserMobileNumber,
  setCsrfToken,
  setUserEmail,
  isFirstTimeLogin,
};
