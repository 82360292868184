import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import QueryString from 'query-string';
import { Spinner } from '../../../ui-components/Loader';
import 'bootstrap/dist/css/bootstrap.css';
import '@pwc/appkit-react/style/appkit-react.default.css';
import constant from '../actions/constant';
import RenderIf from '../../../ui-components/Common';
import authService from '../../../redux/services/authService';

class IamSignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRedirectingToSignIn: true,
    };
  }

  componentWillMount() {
    if (authService.isAuthenticated()) {
      this.props.push(constant.homePage);
    } else {
      this.redirectToIAM();
    }
  }

  redirectToIAM() {
    const query = QueryString.stringify({
      response_type: 'code',
      // eslint-disable-next-line no-undef
      client_id: __CONFIG__.appIamClientId,
      redirect_uri: this.props.redirectUrl,
      scope: 'openid',
    });

    // eslint-disable-next-line no-undef
    const iamUrl = `${__CONFIG__.appIamEndpoint}?${query}`;

    window.location = iamUrl;
    this.setState({ isRedirectingToSignIn: false });
  }

  render() {
    const { isRedirectingToSignIn } = this.state;

    if (isRedirectingToSignIn) {
      return (
        <RenderIf showComponent={isRedirectingToSignIn}>
          <Spinner isLoading={isRedirectingToSignIn} />
        </RenderIf>
      );
    }

    return <div />;
  }
}

const mapStateToProps = state => ({
  redirectUrl: state.brandwrapper.redirectUrl,
});

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IamSignIn);
