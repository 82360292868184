/* eslint-disable max-len */

import React from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import '@pwc/appkit-react/style/appkit-react.default.css';

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePoint: null,
      activePosition: null,
      text: 'Welcome to perspectives',
      tabIndex: 0,
      selectedTab: 'Australia',
    };
  }

  changeTab(tabName) {
    this.setState({
      selectedTab: tabName,
    });
  }

  render() {
    // const selectedTab = this.state;
    return (
      <React.Fragment>

        <div className="public-container">
          <div style={{ background: '#fff' }}>
            <div className="public-header-privacy">
              <div className="a-pwc-logo-grid" />
              <div class="font-weight-medium a-nowrap app-title">
                {this.props.brandName}
              </div>

            </div>
          </div>
          <div className="privacy-content">
            <div className="container" style={{ maxWidth: '100%' }}>
              <div className="col-md-12">

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style={{ justifyContent: 'center' }}>
                  <li class="nav-item" role="presentation">
                    <div
                      style={this.state.selectedTab === 'Australia' ? {
                        backgroundColor: 'transparent', color: '#D04A02', fontWeight: '500', padding: '0.5rem 0px 5px', borderBottom: '2px solid #D04A02', borderRadius: '0px', fontSize: '14px', fontFamily: 'PwC Helvetica Neue',
                      }
                        : {
                          backgroundColor: 'transparent', color: 'black', fontWeight: '500', padding: '0.5rem 0px 5px', borderBottom: '2px solid black', borderRadius: '0px', fontSize: '14px', fontFamily: 'PwC Helvetica Neue',
                        }}
                      onClick={() => this.changeTab('Australia')}
                      class={
                        this.state.selectedTab === 'Australia' ? 'nav-link active' : 'nav-link'
                      } id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="div" role="tab" aria-controls="pills-home" aria-selected="true">AUSTRALIA</div>
                  </li>
                  {/* the code for differnt zone commented code */}
                  {/* <li class="nav-item"
                    role="presentation">
                    <button onClick={() => this.changeTab("Singapore")}
                     style={this.state.selectedTab === "Singapore" ? { backgroundColor: 'transparent', color: '#e8613a' , borderBottom: '2px solid #e8613a' } : { backgroundColor: 'transparent', color: 'black' }}
                      class={
                        this.state.selectedTab === "Singapore" ? "nav-link active" : "nav-link"
                      } id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Singapore</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button onClick={() => this.changeTab("Malaysia")}
                     style={this.state.selectedTab === "Malaysia" ? { backgroundColor: 'transparent', color: '#e8613a' , borderBottom: '2px solid #e8613a' } : { backgroundColor: 'transparent', color: 'black' }}
                      class={
                        this.state.selectedTab === "Malaysia" ? "nav-link active" : "nav-link"
                      } id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Malaysia</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button onClick={() => this.changeTab("US")}
                     style={this.state.selectedTab === "US" ? { backgroundColor: 'transparent', color: '#e8613a' , borderBottom: '2px solid #e8613a' } : { backgroundColor: 'transparent', color: 'black' }}
                      class={
                        this.state.selectedTab === "US" ? "nav-link active" : "nav-link"
                      } id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">US</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      onClick={() => this.changeTab("UK")}
                     style={this.state.selectedTab === "UK" ? { backgroundColor: 'transparent', color: '#e8613a' , borderBottom: '2px solid #e8613a' } : { backgroundColor: 'transparent', color: 'black' }}
                      class={
                        this.state.selectedTab === "UK" ? "nav-link active" : "nav-link"
                      } id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">UK</button>
                  </li> */}
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class={
                      this.state.selectedTab === 'Australia'
                        ? 'tab-pane fade show active'
                        : 'tab-pane fade'
                    } id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="privacy-content-container">
                      <h5 className="mt-2 mb-4"><b>Perspectives Platform (“Perspectives”) Terms of Use (“Terms”) </b></h5>
                      <p>Perspectives is a PwC-developed sentiment analysis platform that is used by our clients and their stakeholders to give people a voice and facilitate real-time insights into risks and issues.</p>
                      <p>
                        PwC has licensed Perspectives for use by the PwC Client for the purpose of requesting, responding to or viewing sentiment insights and feedback in connection with the Services that PwC is providing to the PwC Client
                        (“<strong>Purpose</strong>”).
                      </p>
                      <p>
                        By accessing Perspectives or by creating an Account, you agree to be bound by these Terms, which constitute a legally binding agreement between you and PwC. These Terms may change over time to reflect changes to Perspectives. By
                        continuing to use Perspectives after PwC makes changes to these Terms of Use, you accept those changes.
                      </p>
                      <p>BY USING PERSPECTIVES YOU CONFIRM THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS AND THE PERSPECTIVES INFORMATION COLLECTION STATEMENT AND THAT YOU AGREE TO THEM AS A CONDITION OF YOUR ACCESS AND USE OF PERSPECTIVES.</p>
                      <p>&nbsp;</p>
                      <div className="terms-of-use-item">
                        <p><strong>1. &nbsp;The Platform</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>1.1</strong>&nbsp;Your use of Perspectives is to support the Purpose and not for any other purpose.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>1.2</strong>&nbsp;The availability of Perspectives at any given time is not guaranteed. PwC may suspend or withdraw your access to Perspectives or material on it, at any time, without notice and regardless of whether
                            you comply with these Terms. Perspectives may also be affected from time to time, including being unavailable or unresponsive, due to upgrades, repairs or any other maintenance.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>1.3</strong>&nbsp;We reserve the right to amend or change the Terms at any time without notice. We encourage you to periodically review these Terms to ensure you are in compliance.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>2. &nbsp;Managing Your Account</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.1</strong>&nbsp;When you create an Account, you must provide information that is complete, accurate and current. Failure to do so is a breach of these Terms, which may result in the termination of your Account.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.2</strong>&nbsp;You must always maintain control of your Account. We may, in our absolute discretion, cancel or suspend or modify any existing Account including if we reasonably form the view that a User's conduct
                            (including a breach of these Terms of Use) is detrimental to the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.3</strong>&nbsp;You are responsible for safeguarding the password you use to access Perspectives, and you agree to preserve the confidentiality of your password, prevent unauthorised access to or use of
                            Perspectives or any device you use to access Perspectives, and prevent disclosure of your password to any third party. You must immediately notify PwC at <a href="mailto:au_pwc_perspectives@pwc.com">au_pwc_perspectives@pwc.com</a> if anyone else obtains your Perspectives access credentials so that we can disable them and provide you with new credentials.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>3. &nbsp;Using Perspectives</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>3.1</strong>&nbsp;Your ability to use Perspectives may depend on:</p>
                          <div>
                            <p className="term-pd-2">
                              3.1.1&nbsp;
                              <span>
                                the use of web cookies or other facilitating tools or software (see PwC’s Privacy Policy available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a>);
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.1.2&nbsp;your access to compatible equipment and internet connectivity or other services, which PwC is not responsible for providing.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>3.2</strong>&nbsp;When using Perspectives, you must comply with these Terms and with applicable laws and regulations. You may use Perspectives only to upload and input information that is relevant to interacting with
                            Perspectives and that you have received as an employee, stakeholder or customer of the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.3</strong>&nbsp;You agree to use Perspectives only for the Purpose and in accordance with these Terms.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.4</strong>&nbsp;You agree to:</p>
                          <div>
                            <p className="term-pd-2">3.4.1&nbsp;keep the personal information you provide to PwC in connection with your use of Perspectives accurate, complete and up-to-date at all times;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.2&nbsp;keep your login credentials (e.g. user ID and password) secure, and notify PwC promptly if you know of or suspect any security breach or other suspicious activity;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.3&nbsp;comply with any additional reasonable responsibilities PwC communicates to you;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.4&nbsp;ensure that any Perspectives Information you upload to Perspectives is accurate and is not misleading or deceptive; and</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.5&nbsp;not access Perspectives if you are no longer employed or engaged by the PwC Client.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.5</strong>&nbsp;In using Perspectives, you may not:</p>
                          <div>
                            <p className="term-pd-2">3.5.1&nbsp;use Perspectives in a way prohibited by law, regulation, governmental order or decree, or for any purpose other than the Purpose;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.2&nbsp;infringe any third party’s, PwC’s or its licensor’s intellectual property rights;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.3&nbsp;decompile, decipher, disassemble, reverse engineer or otherwise attempt to discover any source code of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.4&nbsp;use Perspectives to provide services to a third party or allow any third party to either obtain a copy of Perspectives or to access or use Perspectives in any way not expressly agreed in
                              writing by PwC in advance;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.5&nbsp;copy the whole or any part of Perspectives, or frame, scrape, store, publish, transmit or distribute Perspectives, in a manner that is inconsistent with these Terms;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.6&nbsp;modify, translate, abridge or create any derivative work based on Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.7&nbsp;sell, assign, sublicense, or otherwise transfer any rights in relation to Perspectives, without PwC’s prior written consent;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.8&nbsp;use or refer to Perspectives in developing any goods or services that compete with the goods or services provided by any PwC Member Firm;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.9&nbsp;use Perspectives to spam or distribute malware, for any offensive, harassing, fraudulent or defamatory purpose, or to violate the rights of others;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.10&nbsp;post any content from Perspectives, other than PwC Client materials, on any external social networking sites;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.11&nbsp;use Perspectives to try to gain unauthorised access to or disrupt any service, device, data, account or network, or in a way that could harm Perspectives or impair anyone else’s use of them;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.12&nbsp;breach or attempt to bypass any security measures of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.13&nbsp;remove, obscure or modify any copyright notices or disclaimers as they appear in Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.14&nbsp;disrupt or interfere with Perspectives, the systems and infrastructure PwC uses to provide Perspectives, or any other users of Perspectives; or</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.15&nbsp;use Perspectives in any application or situation where failure of Perspectives could lead to the death or serious bodily injury of any person, or to severe physical or environmental damage.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.16&nbsp;integrate or inter-operate Perspectives with other software, hardware or data used or licensed by PwC Client or Perspectives without obtaining any necessary permits, consents or licences
                              required.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>4. &nbsp;Intellectual Property</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.1</strong>&nbsp;Perspectives is owned by PwC and has been licensed to the PwC Client for the Purpose. The visual interfaces, graphics, design, compilation, computer code, information, products, software (including
                            any downloadable software), services, and all other elements of Perspectives are provided by PwC and are protected by intellectual property laws and other applicable laws. PwC is the owner of, or has the rights to, its
                            trademarks, service marks and tradenames made available on Perspectives.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.2</strong>&nbsp;You are authorised under these Terms to use Perspectives for the Purpose. Use of Perspectives for any other purpose is prohibited. Your licence to use Perspectives can be discontinued at any time,
                            without notice and with or without cause.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.3</strong>&nbsp;Before you submit or share any Perspectives Information via Perspectives, you must first ensure that you have obtained any necessary rights or permissions from third parties to do so. Your use of
                            Perspectives does not give you any rights in Perspectives or any information or material made available on Perspectives. To the extent that there are any intellectual property rights in any of your Perspectives Information,
                            you grant us a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the
                            Perspectives Information throughout the world in any medium for the purposes of providing the Services, and you grant the PwC Client a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence
                            to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the Perspectives Information for the purposes for which we have collected the Perspectives Information from you.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>5. &nbsp;Liability</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.1</strong>&nbsp;PwC’s total liability arising out of, or in connection with, these Terms or from the use of or inability to use Perspectives shall not exceed one hundred dollars ($100.00). Nothing in these Terms
                            excludes or limits either party’s liability for (i) fraud or wilful misconduct; (ii) breach of either party’s intellectual property rights; or (iii) any liability which cannot be excluded or limited by law.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.2</strong>&nbsp;To the extent permitted by law, no party to these Terms shall be liable for loss of profit, loss of goodwill, loss of business opportunity, loss of reputation, loss of anticipated savings or
                            benefits, loss of revenue, and loss of contracts however occurring, or indirect or consequential loss or damages.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.3</strong>&nbsp;Perspectives is not intended to be used as a system of record. Users must keep their own back-ups of its data and update them regularly. To the extent permitted by law, the PwC excludes all
                            liability for loss or corruption of data.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.4</strong>&nbsp;PwC accepts no liability or responsibility to any third party in connection with Perspectives. You agree to indemnify PwC for any liability (including reasonable legal costs) PwC may incur in
                            connection with any claim by a third party arising from your breach of these Terms.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.5</strong>&nbsp;The form in which PwC presents any Perspectives Information or material derived from any Perspectives Information to a PwC Client is a matter for PwC to determine in accordance with its agreement
                            with the PwC Client. PwC does not warrant or represent to you that it will present Perspectives Information to a PwC Client in a particular form or at all.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>6. &nbsp;No Warranties and Disclaimers</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>6.1</strong>&nbsp;PwC provides access to Perspectives on an “As Is” and “As Available” basis. To the extent permitted by law:</p>
                          <div>
                            <p className="term-pd-2">
                              6.1.1&nbsp;PwC makes no warranty of any kind, express or implied, in relation to Perspectives or related services provided to support the Purpose, including, but not limited to, warranties of
                              merchantability and non-infringement.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">6.1.2&nbsp;Perspectives does not warrant</p>
                            <p className="term-pd-3">a)&nbsp;the accuracy, completeness, currency, correctness or fitness for a particular purpose of Perspectives or related services; or</p>
                            <p className="term-pd-3">
                              b)&nbsp;that use of Perspectives or the related services will be timely, secure, uninterrupted or error free or operate in combination with any other software, hardware, data or system.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-1">
                              <strong>6.2</strong>&nbsp;If any guarantee, warranty, term or condition is implied or imposed in relation to these Terms under the Australian Consumer Law or any other applicable legislation and cannot be excluded (a <strong>Non-Excludable Provision</strong>), and PwC is able to limit the User’s remedy for a breach of the Non-Excludable Provision, then the liability of PwC for breach of the Non-Excludable Provision is limited to one
                              or more of the following at PwC’s option:
                              <p className="term-pd-2">
                                6.2.1 &nbsp;in the case of goods, the replacement of the goods or the supply of equivalent goods, the repair of the goods, the payment of the cost of replacing the goods or of acquiring equivalent
                                goods, or the payment of the cost of having the goods repaired; or
                              </p>
                              <p className="term-pd-2">6.2.2 &nbsp;in the case of services, the supplying of the services again, or the payment of the cost of having the services supplied again.</p>
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>6.3</strong>&nbsp;Any support we provide to Users in relation to use of Perspectives is provided at our discretion.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>7. &nbsp;Compliance and Conflicts of Interest</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>7.1</strong>&nbsp;The parties shall ensure that their activities in connection with these Terms will (i) be in compliance with all applicable laws and regulations relating to international corruption, fraud and
                            anti-bribery and the Modern Slavery Act 2018 (or applicable international equivalents); and (ii) PwC will comply with PwC’s respective policies which relates to the subject matter above.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>8. &nbsp;Privacy and confidentiality </strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.1</strong>&nbsp;By using Perspectives, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a> and the Perspectives Information Collection Statement which is available at <a href="https://projectperspectives.pwc.com/information-collection-statement" target="_blank">projectperspectives.pwc.com/information-collection-statement</a>.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.2</strong>&nbsp;You agree to keep confidential the information that you may access via Perspectives, and not to share the information with third parties. You hereby consent to PwC sharing your personal information
                            with PwC Client for the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.3</strong>&nbsp;Your employer or our Client may include or incorporate their own terms or privacy policy that apply to your use of Perspectives and in addition to these Terms. This creates a separate agreement
                            between you and the PwC Client. We are not a party to any such agreements and any questions should be raised with the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.4</strong>&nbsp;PwC uses other PwC firms within the PwC global network (PwC Member Firms), third party contractors and suppliers such as hosting and security service providers located in various countries to assist
                            us with performing internal functions necessary to operate our business and to process information on our behalf. Users consent to Perspectives Information provided by Users (including personal information and Users’
                            confidential information) being transferred to those contractors and suppliers and to other PwC Member Firms and our subcontractors, so long as they are bound by reasonable confidentiality obligations.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.5</strong>&nbsp;We take care to ensure that PwC Member Firms and other third parties outside Australia to whom we disclose personal information are subject to appropriate restrictions on their handling of that
                            personal information. Due to differences in foreign laws and the global nature of some arrangements in place for the PwC global network, however, these restrictions may not be substantially similar to those required under
                            the Australian Privacy Principles, and the Privacy Act (including mechanisms entitling you to seek redress) may not apply. Any such disclosure of personal information does not change any of our commitments to safeguard your
                            privacy, and the information remains subject to any existing confidentiality obligations.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>9. &nbsp;Monitoring and moderation</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>9.1</strong>&nbsp;PwC may monitor your use of Perspectives from time to time but does not commit to doing so. Where PwC monitors, it will do so in accordance with applicable law. Perspectives is not moderated
                            (although some PwC Clients may moderate their instance of Perspectives), consequently PwC is not responsible for inappropriate or unlawful content which may appear on Perspectives and you should treat content with caution
                            and common sense. We, or the relevant PwC Client, have the right to promptly remove, edit or to disable access to, any contribution which is deemed to be potentially unlawful, in breach of any third-party rights or in breach
                            of these Terms.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>10. &nbsp;Data Hosting</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.1</strong>&nbsp;PwC may use third party cloud hosting providers to deliver Perspectives. You acknowledge and agree that any information that you or any other person input into Perspectives (including any personal
                            information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties) (together, the <strong>Data Host</strong>).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.2</strong>&nbsp;Perspectives uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately
                            managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject
                            to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an
                            overseas authority.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>10.3</strong>&nbsp;With the exception of Clauses 8.4 and 10.2, no other Perspectives Information will be stored or processed outside of Australia in the regular course of business.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>11. &nbsp;Term and Termination.</strong></p>
                        <div>
                          <p className="term-pd-1">PwC may in its absolute discretion terminate access to Perspectives under these Terms without notice.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>12. &nbsp;General</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>12.1</strong>&nbsp;Nothing in these Terms creates a joint venture, employment, partnership, agency, fiduciary or other relationship between the parties.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.2</strong>&nbsp;Neither party may assign any of its rights or obligations under these Terms, whether by operation of law or otherwise, without the other party’s prior written consent (not to be unreasonably
                            withheld).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.3</strong>&nbsp;The law applying to these Terms is the law in force in New South Wales. Both parties submit to the exclusive jurisdiction of the courts of that state and waive any right either of us may have to
                            claim that those courts do not have jurisdiction or are an inconvenient forum.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.4</strong>&nbsp;If you do not comply with these Terms and PwC does not take action immediately, this does not mean PwC is giving up any rights PwC may have (such as taking action in the future). These terms
                            contain the whole agreement between you and PwC in respect of Perspectives. Other than section 3, they do not create any third party beneficiary rights. Your use of Perspectives does not give rise to any relationship, right
                            or obligation between you and PwC that isn't agreed in these Terms. If a particular term is found to be unenforceable, this will not affect any other terms. Any term of these Terms which is wholly or partially void or
                            unenforceable is severed to the extent that it is void or unenforceable. The validity or enforceability of the remainder of these Terms is not affected.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.5</strong>&nbsp;Upon any termination, discontinuation or cancellation of Perspectives or your Registration, all provisions of these Terms which by their nature should survive will survive, including, without
                            limitation, ownership provisions, warranty disclaimers, limitations of liability, confidentiality and dispute resolution provisions.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>13. &nbsp;Definitions</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>13.1</strong>&nbsp;<strong>Perspectives</strong> means the software application or tools that together comprise the Platform which are available at the URL.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.2</strong>&nbsp;<strong>Perspectives Information</strong> means the data that you upload to or access through Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>13.3</strong>&nbsp;<strong>PwC</strong> or <strong>we</strong> means the Australian firm of PricewaterhouseCoopers, a partnership formed in Australia, which is a member firm of the PwC network of firms. Each member
                            firm in the PwC network (PwC Member Firm) is a separate legal entity. For further details, please see https://www.pwc.com/gx/en/about/corporate-governance/network-structure.html.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.4</strong>&nbsp;<strong>PwC Client</strong> may be a PwC Member Firm, or a client or clients that engaged PwC or a PwC Member Firm to provide the Services.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.5</strong>&nbsp;<strong>Services</strong> means the services provided by PwC to the PwC Client in connection with the use of Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.6</strong>&nbsp;<strong>User</strong> means any user of Perspectives.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class={
                    this.state.selectedTab === 'Singapore'
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  } id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className="privacy-content-container">
                      <h5 className="mt-2 mb-4"><b>Perspectives Platform (“Perspectives”) Terms of Use (“Terms”) </b></h5>
                      <p>Perspectives is a PwC-developed sentiment analysis platform that is used by our clients and their stakeholders to give people a voice and facilitate real-time insights into risks and issues.</p>
                      <p>
                        PwC has licensed Perspectives for use by the PwC Client for the purpose of requesting, responding to or viewing sentiment insights and feedback in connection with the Services that PwC is providing to the PwC Client
                        (“<strong>Purpose</strong>”).
                      </p>
                      <p>
                        By accessing Perspectives or by creating an Account, you agree to be bound by these Terms, which constitute a legally binding agreement between you and PwC. These Terms may change over time to reflect changes to Perspectives. By
                        continuing to use Perspectives after PwC makes changes to these Terms of Use, you accept those changes.
                      </p>
                      <p>BY USING PERSPECTIVES YOU CONFIRM THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS AND THE PERSPECTIVES INFORMATION COLLECTION STATEMENT AND THAT YOU AGREE TO THEM AS A CONDITION OF YOUR ACCESS AND USE OF PERSPECTIVES.</p>
                      <p>&nbsp;</p>
                      <div className="terms-of-use-item">
                        <p><strong>1. &nbsp;The Platform</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>1.1</strong>&nbsp;Your use of Perspectives is to support the Purpose and not for any other purpose.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>1.2</strong>&nbsp;The availability of Perspectives at any given time is not guaranteed. PwC may suspend or withdraw your access to Perspectives or material on it, at any time, without notice and regardless of whether
                            you comply with these Terms. Perspectives may also be affected from time to time, including being unavailable or unresponsive, due to upgrades, repairs or any other maintenance.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>1.3</strong>&nbsp;We reserve the right to amend or change the Terms at any time without notice. We encourage you to periodically review these Terms to ensure you are in compliance.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>2. &nbsp;Managing Your Account</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.1</strong>&nbsp;When you create an Account, you must provide information that is complete, accurate and current. Failure to do so is a breach of these Terms, which may result in the termination of your Account.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.2</strong>&nbsp;You must always maintain control of your Account. We may, in our absolute discretion, cancel or suspend or modify any existing Account including if we reasonably form the view that a User's conduct
                            (including a breach of these Terms of Use) is detrimental to the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.3</strong>&nbsp;You are responsible for safeguarding the password you use to access Perspectives, and you agree to preserve the confidentiality of your password, prevent unauthorised access to or use of
                            Perspectives or any device you use to access Perspectives, and prevent disclosure of your password to any third party. You must immediately notify PwC at <a href="mailto:au_pwc_perspectives@pwc.com">au_pwc_perspectives@pwc.com</a> if anyone else obtains your Perspectives access credentials so that we can disable them and provide you with new credentials.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>3. &nbsp;Using Perspectives</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>3.1</strong>&nbsp;Your ability to use Perspectives may depend on:</p>
                          <div>
                            <p className="term-pd-2">
                              3.1.1&nbsp;
                              <span>
                                the use of web cookies or other facilitating tools or software (see PwC’s Privacy Policy available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a>);
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.1.2&nbsp;your access to compatible equipment and internet connectivity or other services, which PwC is not responsible for providing.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>3.2</strong>&nbsp;When using Perspectives, you must comply with these Terms and with applicable laws and regulations. You may use Perspectives only to upload and input information that is relevant to interacting with
                            Perspectives and that you have received as an employee, stakeholder or customer of the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.3</strong>&nbsp;You agree to use Perspectives only for the Purpose and in accordance with these Terms.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.4</strong>&nbsp;You agree to:</p>
                          <div>
                            <p className="term-pd-2">3.4.1&nbsp;keep the personal information you provide to PwC in connection with your use of Perspectives accurate, complete and up-to-date at all times;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.2&nbsp;keep your login credentials (e.g. user ID and password) secure, and notify PwC promptly if you know of or suspect any security breach or other suspicious activity;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.3&nbsp;comply with any additional reasonable responsibilities PwC communicates to you;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.4&nbsp;ensure that any Perspectives Information you upload to Perspectives is accurate and is not misleading or deceptive; and</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.5&nbsp;not access Perspectives if you are no longer employed or engaged by the PwC Client.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.5</strong>&nbsp;In using Perspectives, you may not:</p>
                          <div>
                            <p className="term-pd-2">3.5.1&nbsp;use Perspectives in a way prohibited by law, regulation, governmental order or decree, or for any purpose other than the Purpose;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.2&nbsp;infringe any third party’s, PwC’s or its licensor’s intellectual property rights;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.3&nbsp;decompile, decipher, disassemble, reverse engineer or otherwise attempt to discover any source code of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.4&nbsp;use Perspectives to provide services to a third party or allow any third party to either obtain a copy of Perspectives or to access or use Perspectives in any way not expressly agreed in
                              writing by PwC in advance;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.5&nbsp;copy the whole or any part of Perspectives, or frame, scrape, store, publish, transmit or distribute Perspectives, in a manner that is inconsistent with these Terms;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.6&nbsp;modify, translate, abridge or create any derivative work based on Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.7&nbsp;sell, assign, sublicense, or otherwise transfer any rights in relation to Perspectives, without PwC’s prior written consent;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.8&nbsp;use or refer to Perspectives in developing any goods or services that compete with the goods or services provided by any PwC Member Firm;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.9&nbsp;use Perspectives to spam or distribute malware, for any offensive, harassing, fraudulent or defamatory purpose, or to violate the rights of others;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.10&nbsp;post any content from Perspectives, other than PwC Client materials, on any external social networking sites;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.11&nbsp;use Perspectives to try to gain unauthorised access to or disrupt any service, device, data, account or network, or in a way that could harm Perspectives or impair anyone else’s use of them;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.12&nbsp;breach or attempt to bypass any security measures of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.13&nbsp;remove, obscure or modify any copyright notices or disclaimers as they appear in Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.14&nbsp;disrupt or interfere with Perspectives, the systems and infrastructure PwC uses to provide Perspectives, or any other users of Perspectives; or</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.15&nbsp;use Perspectives in any application or situation where failure of Perspectives could lead to the death or serious bodily injury of any person, or to severe physical or environmental damage.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.16&nbsp;integrate or inter-operate Perspectives with other software, hardware or data used or licensed by PwC Client or Perspectives without obtaining any necessary permits, consents or licences
                              required.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>4. &nbsp;Intellectual Property</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.1</strong>&nbsp;Perspectives is owned by PwC and has been licensed to the PwC Client for the Purpose. The visual interfaces, graphics, design, compilation, computer code, information, products, software (including
                            any downloadable software), services, and all other elements of Perspectives are provided by PwC and are protected by intellectual property laws and other applicable laws. PwC is the owner of, or has the rights to, its
                            trademarks, service marks and tradenames made available on Perspectives.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.2</strong>&nbsp;You are authorised under these Terms to use Perspectives for the Purpose. Use of Perspectives for any other purpose is prohibited. Your licence to use Perspectives can be discontinued at any time,
                            without notice and with or without cause.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.3</strong>&nbsp;Before you submit or share any Perspectives Information via Perspectives, you must first ensure that you have obtained any necessary rights or permissions from third parties to do so. Your use of
                            Perspectives does not give you any rights in Perspectives or any information or material made available on Perspectives. To the extent that there are any intellectual property rights in any of your Perspectives Information,
                            you grant us a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the
                            Perspectives Information throughout the world in any medium for the purposes of providing the Services, and you grant the PwC Client a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence
                            to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the Perspectives Information for the purposes for which we have collected the Perspectives Information from you.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>5. &nbsp;Liability</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.1</strong>&nbsp;PwC’s total liability arising out of, or in connection with, these Terms or from the use of or inability to use Perspectives shall not exceed one hundred dollars ($100.00). Nothing in these Terms
                            excludes or limits either party’s liability for (i) fraud or wilful misconduct; (ii) breach of either party’s intellectual property rights; or (iii) any liability which cannot be excluded or limited by law.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.2</strong>&nbsp;To the extent permitted by law, no party to these Terms shall be liable for loss of profit, loss of goodwill, loss of business opportunity, loss of reputation, loss of anticipated savings or
                            benefits, loss of revenue, and loss of contracts however occurring, or indirect or consequential loss or damages.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.3</strong>&nbsp;Perspectives is not intended to be used as a system of record. Users must keep their own back-ups of its data and update them regularly. To the extent permitted by law, the PwC excludes all
                            liability for loss or corruption of data.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.4</strong>&nbsp;PwC accepts no liability or responsibility to any third party in connection with Perspectives. You agree to indemnify PwC for any liability (including reasonable legal costs) PwC may incur in
                            connection with any claim by a third party arising from your breach of these Terms.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.5</strong>&nbsp;The form in which PwC presents any Perspectives Information or material derived from any Perspectives Information to a PwC Client is a matter for PwC to determine in accordance with its agreement
                            with the PwC Client. PwC does not warrant or represent to you that it will present Perspectives Information to a PwC Client in a particular form or at all.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>6. &nbsp;No Warranties and Disclaimers</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>6.1</strong>&nbsp;PwC provides access to Perspectives on an “As Is” and “As Available” basis. To the extent permitted by law:</p>
                          <div>
                            <p className="term-pd-2">
                              6.1.1&nbsp;PwC makes no warranty of any kind, express or implied, in relation to Perspectives or related services provided to support the Purpose, including, but not limited to, warranties of
                              merchantability and non-infringement.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">6.1.2&nbsp;Perspectives does not warrant</p>
                            <p className="term-pd-3">a)&nbsp;the accuracy, completeness, currency, correctness or fitness for a particular purpose of Perspectives or related services; or</p>
                            <p className="term-pd-3">
                              b)&nbsp;that use of Perspectives or the related services will be timely, secure, uninterrupted or error free or operate in combination with any other software, hardware, data or system.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-1">
                              <strong>6.2</strong>&nbsp;If any guarantee, warranty, term or condition is implied or imposed in relation to these Terms under the Australian Consumer Law or any other applicable legislation and cannot be excluded (a <strong>Non-Excludable Provision</strong>), and PwC is able to limit the User’s remedy for a breach of the Non-Excludable Provision, then the liability of PwC for breach of the Non-Excludable Provision is limited to one
                              or more of the following at PwC’s option:
                              <p className="term-pd-2">
                                6.2.1 &nbsp;in the case of goods, the replacement of the goods or the supply of equivalent goods, the repair of the goods, the payment of the cost of replacing the goods or of acquiring equivalent
                                goods, or the payment of the cost of having the goods repaired; or
                              </p>
                              <p className="term-pd-2">6.2.2 &nbsp;in the case of services, the supplying of the services again, or the payment of the cost of having the services supplied again.</p>
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>6.3</strong>&nbsp;Any support we provide to Users in relation to use of Perspectives is provided at our discretion.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>7. &nbsp;Compliance and Conflicts of Interest</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>7.1</strong>&nbsp;The parties shall ensure that their activities in connection with these Terms will (i) be in compliance with all applicable laws and regulations relating to international corruption, fraud and
                            anti-bribery and the Modern Slavery Act 2018 (or applicable international equivalents); and (ii) PwC will comply with PwC’s respective policies which relates to the subject matter above.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>8. &nbsp;Privacy and confidentiality </strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.1</strong>&nbsp;By using Perspectives, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a> and the Perspectives Information Collection Statement which is available at <a href="https://projectperspectives.pwc.com/information-collection-statement" target="_blank">projectperspectives.pwc.com/information-collection-statement</a>.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.2</strong>&nbsp;You agree to keep confidential the information that you may access via Perspectives, and not to share the information with third parties. You hereby consent to PwC sharing your personal information
                            with PwC Client for the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.3</strong>&nbsp;Your employer or our Client may include or incorporate their own terms or privacy policy that apply to your use of Perspectives and in addition to these Terms. This creates a separate agreement
                            between you and the PwC Client. We are not a party to any such agreements and any questions should be raised with the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.4</strong>&nbsp;PwC uses other PwC firms within the PwC global network (PwC Member Firms), third party contractors and suppliers such as hosting and security service providers located in various countries to assist
                            us with performing internal functions necessary to operate our business and to process information on our behalf. Users consent to Perspectives Information provided by Users (including personal information and Users’
                            confidential information) being transferred to those contractors and suppliers and to other PwC Member Firms and our subcontractors, so long as they are bound by reasonable confidentiality obligations.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.5</strong>&nbsp;We take care to ensure that PwC Member Firms and other third parties outside Australia to whom we disclose personal information are subject to appropriate restrictions on their handling of that
                            personal information. Due to differences in foreign laws and the global nature of some arrangements in place for the PwC global network, however, these restrictions may not be substantially similar to those required under
                            the Australian Privacy Principles, and the Privacy Act (including mechanisms entitling you to seek redress) may not apply. Any such disclosure of personal information does not change any of our commitments to safeguard your
                            privacy, and the information remains subject to any existing confidentiality obligations.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>9. &nbsp;Monitoring and moderation</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>9.1</strong>&nbsp;PwC may monitor your use of Perspectives from time to time but does not commit to doing so. Where PwC monitors, it will do so in accordance with applicable law. Perspectives is not moderated
                            (although some PwC Clients may moderate their instance of Perspectives), consequently PwC is not responsible for inappropriate or unlawful content which may appear on Perspectives and you should treat content with caution
                            and common sense. We, or the relevant PwC Client, have the right to promptly remove, edit or to disable access to, any contribution which is deemed to be potentially unlawful, in breach of any third-party rights or in breach
                            of these Terms.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>10. &nbsp;Data Hosting</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.1</strong>&nbsp;PwC may use third party cloud hosting providers to deliver Perspectives. You acknowledge and agree that any information that you or any other person input into Perspectives (including any personal
                            information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties) (together, the <strong>Data Host</strong>).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.2</strong>&nbsp;Perspectives uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately
                            managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject
                            to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an
                            overseas authority.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>10.3</strong>&nbsp;With the exception of Clauses 8.4 and 10.2, no other Perspectives Information will be stored or processed outside of Australia in the regular course of business.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>11. &nbsp;Term and Termination.</strong></p>
                        <div>
                          <p className="term-pd-1">PwC may in its absolute discretion terminate access to Perspectives under these Terms without notice.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>12. &nbsp;General</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>12.1</strong>&nbsp;Nothing in these Terms creates a joint venture, employment, partnership, agency, fiduciary or other relationship between the parties.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.2</strong>&nbsp;Neither party may assign any of its rights or obligations under these Terms, whether by operation of law or otherwise, without the other party’s prior written consent (not to be unreasonably
                            withheld).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.3</strong>&nbsp;The law applying to these Terms is the law in force in New South Wales. Both parties submit to the exclusive jurisdiction of the courts of that state and waive any right either of us may have to
                            claim that those courts do not have jurisdiction or are an inconvenient forum.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.4</strong>&nbsp;If you do not comply with these Terms and PwC does not take action immediately, this does not mean PwC is giving up any rights PwC may have (such as taking action in the future). These terms
                            contain the whole agreement between you and PwC in respect of Perspectives. Other than section 3, they do not create any third party beneficiary rights. Your use of Perspectives does not give rise to any relationship, right
                            or obligation between you and PwC that isn't agreed in these Terms. If a particular term is found to be unenforceable, this will not affect any other terms. Any term of these Terms which is wholly or partially void or
                            unenforceable is severed to the extent that it is void or unenforceable. The validity or enforceability of the remainder of these Terms is not affected.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.5</strong>&nbsp;Upon any termination, discontinuation or cancellation of Perspectives or your Registration, all provisions of these Terms which by their nature should survive will survive, including, without
                            limitation, ownership provisions, warranty disclaimers, limitations of liability, confidentiality and dispute resolution provisions.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>13. &nbsp;Definitions</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>13.1</strong>&nbsp;<strong>Perspectives</strong> means the software application or tools that together comprise the Platform which are available at the URL.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.2</strong>&nbsp;<strong>Perspectives Information</strong> means the data that you upload to or access through Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>13.3</strong>&nbsp;<strong>PwC</strong> or <strong>we</strong> means the Australian firm of PricewaterhouseCoopers, a partnership formed in Australia, which is a member firm of the PwC network of firms. Each member
                            firm in the PwC network (PwC Member Firm) is a separate legal entity. For further details, please see https://www.pwc.com/gx/en/about/corporate-governance/network-structure.html.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.4</strong>&nbsp;<strong>PwC Client</strong> may be a PwC Member Firm, or a client or clients that engaged PwC or a PwC Member Firm to provide the Services.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.5</strong>&nbsp;<strong>Services</strong> means the services provided by PwC to the PwC Client in connection with the use of Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.6</strong>&nbsp;<strong>User</strong> means any user of Perspectives.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class={
                    this.state.selectedTab === 'Malaysia'
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  } id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div className="privacy-content-container">
                      <h5 className="mt-2 mb-4"><b>Perspectives Platform (“Perspectives”) Terms of Use (“Terms”) </b></h5>
                      <p>Perspectives is a PwC-developed sentiment analysis platform that is used by our clients and their stakeholders to give people a voice and facilitate real-time insights into risks and issues.</p>
                      <p>
                        PwC has licensed Perspectives for use by the PwC Client for the purpose of requesting, responding to or viewing sentiment insights and feedback in connection with the Services that PwC is providing to the PwC Client
                        (“<strong>Purpose</strong>”).
                      </p>
                      <p>
                        By accessing Perspectives or by creating an Account, you agree to be bound by these Terms, which constitute a legally binding agreement between you and PwC. These Terms may change over time to reflect changes to Perspectives. By
                        continuing to use Perspectives after PwC makes changes to these Terms of Use, you accept those changes.
                      </p>
                      <p>BY USING PERSPECTIVES YOU CONFIRM THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS AND THE PERSPECTIVES INFORMATION COLLECTION STATEMENT AND THAT YOU AGREE TO THEM AS A CONDITION OF YOUR ACCESS AND USE OF PERSPECTIVES.</p>
                      <p>&nbsp;</p>
                      <div className="terms-of-use-item">
                        <p><strong>1. &nbsp;The Platform</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>1.1</strong>&nbsp;Your use of Perspectives is to support the Purpose and not for any other purpose.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>1.2</strong>&nbsp;The availability of Perspectives at any given time is not guaranteed. PwC may suspend or withdraw your access to Perspectives or material on it, at any time, without notice and regardless of whether
                            you comply with these Terms. Perspectives may also be affected from time to time, including being unavailable or unresponsive, due to upgrades, repairs or any other maintenance.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>1.3</strong>&nbsp;We reserve the right to amend or change the Terms at any time without notice. We encourage you to periodically review these Terms to ensure you are in compliance.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>2. &nbsp;Managing Your Account</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.1</strong>&nbsp;When you create an Account, you must provide information that is complete, accurate and current. Failure to do so is a breach of these Terms, which may result in the termination of your Account.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.2</strong>&nbsp;You must always maintain control of your Account. We may, in our absolute discretion, cancel or suspend or modify any existing Account including if we reasonably form the view that a User's conduct
                            (including a breach of these Terms of Use) is detrimental to the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.3</strong>&nbsp;You are responsible for safeguarding the password you use to access Perspectives, and you agree to preserve the confidentiality of your password, prevent unauthorised access to or use of
                            Perspectives or any device you use to access Perspectives, and prevent disclosure of your password to any third party. You must immediately notify PwC at <a href="mailto:au_pwc_perspectives@pwc.com">au_pwc_perspectives@pwc.com</a> if anyone else obtains your Perspectives access credentials so that we can disable them and provide you with new credentials.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>3. &nbsp;Using Perspectives</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>3.1</strong>&nbsp;Your ability to use Perspectives may depend on:</p>
                          <div>
                            <p className="term-pd-2">
                              3.1.1&nbsp;
                              <span>
                                the use of web cookies or other facilitating tools or software (see PwC’s Privacy Policy available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a>);
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.1.2&nbsp;your access to compatible equipment and internet connectivity or other services, which PwC is not responsible for providing.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>3.2</strong>&nbsp;When using Perspectives, you must comply with these Terms and with applicable laws and regulations. You may use Perspectives only to upload and input information that is relevant to interacting with
                            Perspectives and that you have received as an employee, stakeholder or customer of the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.3</strong>&nbsp;You agree to use Perspectives only for the Purpose and in accordance with these Terms.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.4</strong>&nbsp;You agree to:</p>
                          <div>
                            <p className="term-pd-2">3.4.1&nbsp;keep the personal information you provide to PwC in connection with your use of Perspectives accurate, complete and up-to-date at all times;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.2&nbsp;keep your login credentials (e.g. user ID and password) secure, and notify PwC promptly if you know of or suspect any security breach or other suspicious activity;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.3&nbsp;comply with any additional reasonable responsibilities PwC communicates to you;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.4&nbsp;ensure that any Perspectives Information you upload to Perspectives is accurate and is not misleading or deceptive; and</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.5&nbsp;not access Perspectives if you are no longer employed or engaged by the PwC Client.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.5</strong>&nbsp;In using Perspectives, you may not:</p>
                          <div>
                            <p className="term-pd-2">3.5.1&nbsp;use Perspectives in a way prohibited by law, regulation, governmental order or decree, or for any purpose other than the Purpose;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.2&nbsp;infringe any third party’s, PwC’s or its licensor’s intellectual property rights;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.3&nbsp;decompile, decipher, disassemble, reverse engineer or otherwise attempt to discover any source code of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.4&nbsp;use Perspectives to provide services to a third party or allow any third party to either obtain a copy of Perspectives or to access or use Perspectives in any way not expressly agreed in
                              writing by PwC in advance;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.5&nbsp;copy the whole or any part of Perspectives, or frame, scrape, store, publish, transmit or distribute Perspectives, in a manner that is inconsistent with these Terms;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.6&nbsp;modify, translate, abridge or create any derivative work based on Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.7&nbsp;sell, assign, sublicense, or otherwise transfer any rights in relation to Perspectives, without PwC’s prior written consent;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.8&nbsp;use or refer to Perspectives in developing any goods or services that compete with the goods or services provided by any PwC Member Firm;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.9&nbsp;use Perspectives to spam or distribute malware, for any offensive, harassing, fraudulent or defamatory purpose, or to violate the rights of others;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.10&nbsp;post any content from Perspectives, other than PwC Client materials, on any external social networking sites;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.11&nbsp;use Perspectives to try to gain unauthorised access to or disrupt any service, device, data, account or network, or in a way that could harm Perspectives or impair anyone else’s use of them;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.12&nbsp;breach or attempt to bypass any security measures of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.13&nbsp;remove, obscure or modify any copyright notices or disclaimers as they appear in Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.14&nbsp;disrupt or interfere with Perspectives, the systems and infrastructure PwC uses to provide Perspectives, or any other users of Perspectives; or</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.15&nbsp;use Perspectives in any application or situation where failure of Perspectives could lead to the death or serious bodily injury of any person, or to severe physical or environmental damage.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.16&nbsp;integrate or inter-operate Perspectives with other software, hardware or data used or licensed by PwC Client or Perspectives without obtaining any necessary permits, consents or licences
                              required.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>4. &nbsp;Intellectual Property</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.1</strong>&nbsp;Perspectives is owned by PwC and has been licensed to the PwC Client for the Purpose. The visual interfaces, graphics, design, compilation, computer code, information, products, software (including
                            any downloadable software), services, and all other elements of Perspectives are provided by PwC and are protected by intellectual property laws and other applicable laws. PwC is the owner of, or has the rights to, its
                            trademarks, service marks and tradenames made available on Perspectives.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.2</strong>&nbsp;You are authorised under these Terms to use Perspectives for the Purpose. Use of Perspectives for any other purpose is prohibited. Your licence to use Perspectives can be discontinued at any time,
                            without notice and with or without cause.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.3</strong>&nbsp;Before you submit or share any Perspectives Information via Perspectives, you must first ensure that you have obtained any necessary rights or permissions from third parties to do so. Your use of
                            Perspectives does not give you any rights in Perspectives or any information or material made available on Perspectives. To the extent that there are any intellectual property rights in any of your Perspectives Information,
                            you grant us a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the
                            Perspectives Information throughout the world in any medium for the purposes of providing the Services, and you grant the PwC Client a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence
                            to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the Perspectives Information for the purposes for which we have collected the Perspectives Information from you.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>5. &nbsp;Liability</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.1</strong>&nbsp;PwC’s total liability arising out of, or in connection with, these Terms or from the use of or inability to use Perspectives shall not exceed one hundred dollars ($100.00). Nothing in these Terms
                            excludes or limits either party’s liability for (i) fraud or wilful misconduct; (ii) breach of either party’s intellectual property rights; or (iii) any liability which cannot be excluded or limited by law.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.2</strong>&nbsp;To the extent permitted by law, no party to these Terms shall be liable for loss of profit, loss of goodwill, loss of business opportunity, loss of reputation, loss of anticipated savings or
                            benefits, loss of revenue, and loss of contracts however occurring, or indirect or consequential loss or damages.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.3</strong>&nbsp;Perspectives is not intended to be used as a system of record. Users must keep their own back-ups of its data and update them regularly. To the extent permitted by law, the PwC excludes all
                            liability for loss or corruption of data.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.4</strong>&nbsp;PwC accepts no liability or responsibility to any third party in connection with Perspectives. You agree to indemnify PwC for any liability (including reasonable legal costs) PwC may incur in
                            connection with any claim by a third party arising from your breach of these Terms.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.5</strong>&nbsp;The form in which PwC presents any Perspectives Information or material derived from any Perspectives Information to a PwC Client is a matter for PwC to determine in accordance with its agreement
                            with the PwC Client. PwC does not warrant or represent to you that it will present Perspectives Information to a PwC Client in a particular form or at all.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>6. &nbsp;No Warranties and Disclaimers</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>6.1</strong>&nbsp;PwC provides access to Perspectives on an “As Is” and “As Available” basis. To the extent permitted by law:</p>
                          <div>
                            <p className="term-pd-2">
                              6.1.1&nbsp;PwC makes no warranty of any kind, express or implied, in relation to Perspectives or related services provided to support the Purpose, including, but not limited to, warranties of
                              merchantability and non-infringement.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">6.1.2&nbsp;Perspectives does not warrant</p>
                            <p className="term-pd-3">a)&nbsp;the accuracy, completeness, currency, correctness or fitness for a particular purpose of Perspectives or related services; or</p>
                            <p className="term-pd-3">
                              b)&nbsp;that use of Perspectives or the related services will be timely, secure, uninterrupted or error free or operate in combination with any other software, hardware, data or system.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-1">
                              <strong>6.2</strong>&nbsp;If any guarantee, warranty, term or condition is implied or imposed in relation to these Terms under the Australian Consumer Law or any other applicable legislation and cannot be excluded (a <strong>Non-Excludable Provision</strong>), and PwC is able to limit the User’s remedy for a breach of the Non-Excludable Provision, then the liability of PwC for breach of the Non-Excludable Provision is limited to one
                              or more of the following at PwC’s option:
                              <p className="term-pd-2">
                                6.2.1 &nbsp;in the case of goods, the replacement of the goods or the supply of equivalent goods, the repair of the goods, the payment of the cost of replacing the goods or of acquiring equivalent
                                goods, or the payment of the cost of having the goods repaired; or
                              </p>
                              <p className="term-pd-2">6.2.2 &nbsp;in the case of services, the supplying of the services again, or the payment of the cost of having the services supplied again.</p>
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>6.3</strong>&nbsp;Any support we provide to Users in relation to use of Perspectives is provided at our discretion.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>7. &nbsp;Compliance and Conflicts of Interest</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>7.1</strong>&nbsp;The parties shall ensure that their activities in connection with these Terms will (i) be in compliance with all applicable laws and regulations relating to international corruption, fraud and
                            anti-bribery and the Modern Slavery Act 2018 (or applicable international equivalents); and (ii) PwC will comply with PwC’s respective policies which relates to the subject matter above.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>8. &nbsp;Privacy and confidentiality </strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.1</strong>&nbsp;By using Perspectives, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a> and the Perspectives Information Collection Statement which is available at <a href="https://projectperspectives.pwc.com/information-collection-statement" target="_blank">projectperspectives.pwc.com/information-collection-statement</a>.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.2</strong>&nbsp;You agree to keep confidential the information that you may access via Perspectives, and not to share the information with third parties. You hereby consent to PwC sharing your personal information
                            with PwC Client for the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.3</strong>&nbsp;Your employer or our Client may include or incorporate their own terms or privacy policy that apply to your use of Perspectives and in addition to these Terms. This creates a separate agreement
                            between you and the PwC Client. We are not a party to any such agreements and any questions should be raised with the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.4</strong>&nbsp;PwC uses other PwC firms within the PwC global network (PwC Member Firms), third party contractors and suppliers such as hosting and security service providers located in various countries to assist
                            us with performing internal functions necessary to operate our business and to process information on our behalf. Users consent to Perspectives Information provided by Users (including personal information and Users’
                            confidential information) being transferred to those contractors and suppliers and to other PwC Member Firms and our subcontractors, so long as they are bound by reasonable confidentiality obligations.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.5</strong>&nbsp;We take care to ensure that PwC Member Firms and other third parties outside Australia to whom we disclose personal information are subject to appropriate restrictions on their handling of that
                            personal information. Due to differences in foreign laws and the global nature of some arrangements in place for the PwC global network, however, these restrictions may not be substantially similar to those required under
                            the Australian Privacy Principles, and the Privacy Act (including mechanisms entitling you to seek redress) may not apply. Any such disclosure of personal information does not change any of our commitments to safeguard your
                            privacy, and the information remains subject to any existing confidentiality obligations.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>9. &nbsp;Monitoring and moderation</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>9.1</strong>&nbsp;PwC may monitor your use of Perspectives from time to time but does not commit to doing so. Where PwC monitors, it will do so in accordance with applicable law. Perspectives is not moderated
                            (although some PwC Clients may moderate their instance of Perspectives), consequently PwC is not responsible for inappropriate or unlawful content which may appear on Perspectives and you should treat content with caution
                            and common sense. We, or the relevant PwC Client, have the right to promptly remove, edit or to disable access to, any contribution which is deemed to be potentially unlawful, in breach of any third-party rights or in breach
                            of these Terms.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>10. &nbsp;Data Hosting</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.1</strong>&nbsp;PwC may use third party cloud hosting providers to deliver Perspectives. You acknowledge and agree that any information that you or any other person input into Perspectives (including any personal
                            information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties) (together, the <strong>Data Host</strong>).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.2</strong>&nbsp;Perspectives uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately
                            managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject
                            to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an
                            overseas authority.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>10.3</strong>&nbsp;With the exception of Clauses 8.4 and 10.2, no other Perspectives Information will be stored or processed outside of Australia in the regular course of business.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>11. &nbsp;Term and Termination.</strong></p>
                        <div>
                          <p className="term-pd-1">PwC may in its absolute discretion terminate access to Perspectives under these Terms without notice.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>12. &nbsp;General</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>12.1</strong>&nbsp;Nothing in these Terms creates a joint venture, employment, partnership, agency, fiduciary or other relationship between the parties.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.2</strong>&nbsp;Neither party may assign any of its rights or obligations under these Terms, whether by operation of law or otherwise, without the other party’s prior written consent (not to be unreasonably
                            withheld).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.3</strong>&nbsp;The law applying to these Terms is the law in force in New South Wales. Both parties submit to the exclusive jurisdiction of the courts of that state and waive any right either of us may have to
                            claim that those courts do not have jurisdiction or are an inconvenient forum.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.4</strong>&nbsp;If you do not comply with these Terms and PwC does not take action immediately, this does not mean PwC is giving up any rights PwC may have (such as taking action in the future). These terms
                            contain the whole agreement between you and PwC in respect of Perspectives. Other than section 3, they do not create any third party beneficiary rights. Your use of Perspectives does not give rise to any relationship, right
                            or obligation between you and PwC that isn't agreed in these Terms. If a particular term is found to be unenforceable, this will not affect any other terms. Any term of these Terms which is wholly or partially void or
                            unenforceable is severed to the extent that it is void or unenforceable. The validity or enforceability of the remainder of these Terms is not affected.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.5</strong>&nbsp;Upon any termination, discontinuation or cancellation of Perspectives or your Registration, all provisions of these Terms which by their nature should survive will survive, including, without
                            limitation, ownership provisions, warranty disclaimers, limitations of liability, confidentiality and dispute resolution provisions.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>13. &nbsp;Definitions</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>13.1</strong>&nbsp;<strong>Perspectives</strong> means the software application or tools that together comprise the Platform which are available at the URL.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.2</strong>&nbsp;<strong>Perspectives Information</strong> means the data that you upload to or access through Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>13.3</strong>&nbsp;<strong>PwC</strong> or <strong>we</strong> means the Australian firm of PricewaterhouseCoopers, a partnership formed in Australia, which is a member firm of the PwC network of firms. Each member
                            firm in the PwC network (PwC Member Firm) is a separate legal entity. For further details, please see https://www.pwc.com/gx/en/about/corporate-governance/network-structure.html.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.4</strong>&nbsp;<strong>PwC Client</strong> may be a PwC Member Firm, or a client or clients that engaged PwC or a PwC Member Firm to provide the Services.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.5</strong>&nbsp;<strong>Services</strong> means the services provided by PwC to the PwC Client in connection with the use of Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.6</strong>&nbsp;<strong>User</strong> means any user of Perspectives.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class={
                    this.state.selectedTab === 'US'
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  } id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div className="privacy-content-container">
                      <h5 className="mt-2 mb-4"><b>Perspectives Platform (“Perspectives”) Terms of Use (“Terms”) </b></h5>
                      <p>Perspectives is a PwC-developed sentiment analysis platform that is used by our clients and their stakeholders to give people a voice and facilitate real-time insights into risks and issues.</p>
                      <p>
                        PwC has licensed Perspectives for use by the PwC Client for the purpose of requesting, responding to or viewing sentiment insights and feedback in connection with the Services that PwC is providing to the PwC Client
                        (“<strong>Purpose</strong>”).
                      </p>
                      <p>
                        By accessing Perspectives or by creating an Account, you agree to be bound by these Terms, which constitute a legally binding agreement between you and PwC. These Terms may change over time to reflect changes to Perspectives. By
                        continuing to use Perspectives after PwC makes changes to these Terms of Use, you accept those changes.
                      </p>
                      <p>BY USING PERSPECTIVES YOU CONFIRM THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS AND THE PERSPECTIVES INFORMATION COLLECTION STATEMENT AND THAT YOU AGREE TO THEM AS A CONDITION OF YOUR ACCESS AND USE OF PERSPECTIVES.</p>
                      <p>&nbsp;</p>
                      <div className="terms-of-use-item">
                        <p><strong>1. &nbsp;The Platform</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>1.1</strong>&nbsp;Your use of Perspectives is to support the Purpose and not for any other purpose.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>1.2</strong>&nbsp;The availability of Perspectives at any given time is not guaranteed. PwC may suspend or withdraw your access to Perspectives or material on it, at any time, without notice and regardless of whether
                            you comply with these Terms. Perspectives may also be affected from time to time, including being unavailable or unresponsive, due to upgrades, repairs or any other maintenance.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>1.3</strong>&nbsp;We reserve the right to amend or change the Terms at any time without notice. We encourage you to periodically review these Terms to ensure you are in compliance.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>2. &nbsp;Managing Your Account</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.1</strong>&nbsp;When you create an Account, you must provide information that is complete, accurate and current. Failure to do so is a breach of these Terms, which may result in the termination of your Account.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.2</strong>&nbsp;You must always maintain control of your Account. We may, in our absolute discretion, cancel or suspend or modify any existing Account including if we reasonably form the view that a User's conduct
                            (including a breach of these Terms of Use) is detrimental to the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.3</strong>&nbsp;You are responsible for safeguarding the password you use to access Perspectives, and you agree to preserve the confidentiality of your password, prevent unauthorised access to or use of
                            Perspectives or any device you use to access Perspectives, and prevent disclosure of your password to any third party. You must immediately notify PwC at <a href="mailto:au_pwc_perspectives@pwc.com">au_pwc_perspectives@pwc.com</a> if anyone else obtains your Perspectives access credentials so that we can disable them and provide you with new credentials.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>3. &nbsp;Using Perspectives</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>3.1</strong>&nbsp;Your ability to use Perspectives may depend on:</p>
                          <div>
                            <p className="term-pd-2">
                              3.1.1&nbsp;
                              <span>
                                the use of web cookies or other facilitating tools or software (see PwC’s Privacy Policy available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a>);
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.1.2&nbsp;your access to compatible equipment and internet connectivity or other services, which PwC is not responsible for providing.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>3.2</strong>&nbsp;When using Perspectives, you must comply with these Terms and with applicable laws and regulations. You may use Perspectives only to upload and input information that is relevant to interacting with
                            Perspectives and that you have received as an employee, stakeholder or customer of the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.3</strong>&nbsp;You agree to use Perspectives only for the Purpose and in accordance with these Terms.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.4</strong>&nbsp;You agree to:</p>
                          <div>
                            <p className="term-pd-2">3.4.1&nbsp;keep the personal information you provide to PwC in connection with your use of Perspectives accurate, complete and up-to-date at all times;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.2&nbsp;keep your login credentials (e.g. user ID and password) secure, and notify PwC promptly if you know of or suspect any security breach or other suspicious activity;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.3&nbsp;comply with any additional reasonable responsibilities PwC communicates to you;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.4&nbsp;ensure that any Perspectives Information you upload to Perspectives is accurate and is not misleading or deceptive; and</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.5&nbsp;not access Perspectives if you are no longer employed or engaged by the PwC Client.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.5</strong>&nbsp;In using Perspectives, you may not:</p>
                          <div>
                            <p className="term-pd-2">3.5.1&nbsp;use Perspectives in a way prohibited by law, regulation, governmental order or decree, or for any purpose other than the Purpose;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.2&nbsp;infringe any third party’s, PwC’s or its licensor’s intellectual property rights;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.3&nbsp;decompile, decipher, disassemble, reverse engineer or otherwise attempt to discover any source code of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.4&nbsp;use Perspectives to provide services to a third party or allow any third party to either obtain a copy of Perspectives or to access or use Perspectives in any way not expressly agreed in
                              writing by PwC in advance;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.5&nbsp;copy the whole or any part of Perspectives, or frame, scrape, store, publish, transmit or distribute Perspectives, in a manner that is inconsistent with these Terms;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.6&nbsp;modify, translate, abridge or create any derivative work based on Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.7&nbsp;sell, assign, sublicense, or otherwise transfer any rights in relation to Perspectives, without PwC’s prior written consent;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.8&nbsp;use or refer to Perspectives in developing any goods or services that compete with the goods or services provided by any PwC Member Firm;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.9&nbsp;use Perspectives to spam or distribute malware, for any offensive, harassing, fraudulent or defamatory purpose, or to violate the rights of others;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.10&nbsp;post any content from Perspectives, other than PwC Client materials, on any external social networking sites;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.11&nbsp;use Perspectives to try to gain unauthorised access to or disrupt any service, device, data, account or network, or in a way that could harm Perspectives or impair anyone else’s use of them;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.12&nbsp;breach or attempt to bypass any security measures of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.13&nbsp;remove, obscure or modify any copyright notices or disclaimers as they appear in Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.14&nbsp;disrupt or interfere with Perspectives, the systems and infrastructure PwC uses to provide Perspectives, or any other users of Perspectives; or</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.15&nbsp;use Perspectives in any application or situation where failure of Perspectives could lead to the death or serious bodily injury of any person, or to severe physical or environmental damage.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.16&nbsp;integrate or inter-operate Perspectives with other software, hardware or data used or licensed by PwC Client or Perspectives without obtaining any necessary permits, consents or licences
                              required.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>4. &nbsp;Intellectual Property</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.1</strong>&nbsp;Perspectives is owned by PwC and has been licensed to the PwC Client for the Purpose. The visual interfaces, graphics, design, compilation, computer code, information, products, software (including
                            any downloadable software), services, and all other elements of Perspectives are provided by PwC and are protected by intellectual property laws and other applicable laws. PwC is the owner of, or has the rights to, its
                            trademarks, service marks and tradenames made available on Perspectives.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.2</strong>&nbsp;You are authorised under these Terms to use Perspectives for the Purpose. Use of Perspectives for any other purpose is prohibited. Your licence to use Perspectives can be discontinued at any time,
                            without notice and with or without cause.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.3</strong>&nbsp;Before you submit or share any Perspectives Information via Perspectives, you must first ensure that you have obtained any necessary rights or permissions from third parties to do so. Your use of
                            Perspectives does not give you any rights in Perspectives or any information or material made available on Perspectives. To the extent that there are any intellectual property rights in any of your Perspectives Information,
                            you grant us a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the
                            Perspectives Information throughout the world in any medium for the purposes of providing the Services, and you grant the PwC Client a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence
                            to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the Perspectives Information for the purposes for which we have collected the Perspectives Information from you.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>5. &nbsp;Liability</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.1</strong>&nbsp;PwC’s total liability arising out of, or in connection with, these Terms or from the use of or inability to use Perspectives shall not exceed one hundred dollars ($100.00). Nothing in these Terms
                            excludes or limits either party’s liability for (i) fraud or wilful misconduct; (ii) breach of either party’s intellectual property rights; or (iii) any liability which cannot be excluded or limited by law.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.2</strong>&nbsp;To the extent permitted by law, no party to these Terms shall be liable for loss of profit, loss of goodwill, loss of business opportunity, loss of reputation, loss of anticipated savings or
                            benefits, loss of revenue, and loss of contracts however occurring, or indirect or consequential loss or damages.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.3</strong>&nbsp;Perspectives is not intended to be used as a system of record. Users must keep their own back-ups of its data and update them regularly. To the extent permitted by law, the PwC excludes all
                            liability for loss or corruption of data.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.4</strong>&nbsp;PwC accepts no liability or responsibility to any third party in connection with Perspectives. You agree to indemnify PwC for any liability (including reasonable legal costs) PwC may incur in
                            connection with any claim by a third party arising from your breach of these Terms.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.5</strong>&nbsp;The form in which PwC presents any Perspectives Information or material derived from any Perspectives Information to a PwC Client is a matter for PwC to determine in accordance with its agreement
                            with the PwC Client. PwC does not warrant or represent to you that it will present Perspectives Information to a PwC Client in a particular form or at all.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>6. &nbsp;No Warranties and Disclaimers</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>6.1</strong>&nbsp;PwC provides access to Perspectives on an “As Is” and “As Available” basis. To the extent permitted by law:</p>
                          <div>
                            <p className="term-pd-2">
                              6.1.1&nbsp;PwC makes no warranty of any kind, express or implied, in relation to Perspectives or related services provided to support the Purpose, including, but not limited to, warranties of
                              merchantability and non-infringement.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">6.1.2&nbsp;Perspectives does not warrant</p>
                            <p className="term-pd-3">a)&nbsp;the accuracy, completeness, currency, correctness or fitness for a particular purpose of Perspectives or related services; or</p>
                            <p className="term-pd-3">
                              b)&nbsp;that use of Perspectives or the related services will be timely, secure, uninterrupted or error free or operate in combination with any other software, hardware, data or system.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-1">
                              <strong>6.2</strong>&nbsp;If any guarantee, warranty, term or condition is implied or imposed in relation to these Terms under the Australian Consumer Law or any other applicable legislation and cannot be excluded (a <strong>Non-Excludable Provision</strong>), and PwC is able to limit the User’s remedy for a breach of the Non-Excludable Provision, then the liability of PwC for breach of the Non-Excludable Provision is limited to one
                              or more of the following at PwC’s option:
                              <p className="term-pd-2">
                                6.2.1 &nbsp;in the case of goods, the replacement of the goods or the supply of equivalent goods, the repair of the goods, the payment of the cost of replacing the goods or of acquiring equivalent
                                goods, or the payment of the cost of having the goods repaired; or
                              </p>
                              <p className="term-pd-2">6.2.2 &nbsp;in the case of services, the supplying of the services again, or the payment of the cost of having the services supplied again.</p>
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>6.3</strong>&nbsp;Any support we provide to Users in relation to use of Perspectives is provided at our discretion.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>7. &nbsp;Compliance and Conflicts of Interest</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>7.1</strong>&nbsp;The parties shall ensure that their activities in connection with these Terms will (i) be in compliance with all applicable laws and regulations relating to international corruption, fraud and
                            anti-bribery and the Modern Slavery Act 2018 (or applicable international equivalents); and (ii) PwC will comply with PwC’s respective policies which relates to the subject matter above.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>8. &nbsp;Privacy and confidentiality </strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.1</strong>&nbsp;By using Perspectives, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a> and the Perspectives Information Collection Statement which is available at <a href="https://projectperspectives.pwc.com/information-collection-statement" target="_blank">projectperspectives.pwc.com/information-collection-statement</a>.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.2</strong>&nbsp;You agree to keep confidential the information that you may access via Perspectives, and not to share the information with third parties. You hereby consent to PwC sharing your personal information
                            with PwC Client for the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.3</strong>&nbsp;Your employer or our Client may include or incorporate their own terms or privacy policy that apply to your use of Perspectives and in addition to these Terms. This creates a separate agreement
                            between you and the PwC Client. We are not a party to any such agreements and any questions should be raised with the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.4</strong>&nbsp;PwC uses other PwC firms within the PwC global network (PwC Member Firms), third party contractors and suppliers such as hosting and security service providers located in various countries to assist
                            us with performing internal functions necessary to operate our business and to process information on our behalf. Users consent to Perspectives Information provided by Users (including personal information and Users’
                            confidential information) being transferred to those contractors and suppliers and to other PwC Member Firms and our subcontractors, so long as they are bound by reasonable confidentiality obligations.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.5</strong>&nbsp;We take care to ensure that PwC Member Firms and other third parties outside Australia to whom we disclose personal information are subject to appropriate restrictions on their handling of that
                            personal information. Due to differences in foreign laws and the global nature of some arrangements in place for the PwC global network, however, these restrictions may not be substantially similar to those required under
                            the Australian Privacy Principles, and the Privacy Act (including mechanisms entitling you to seek redress) may not apply. Any such disclosure of personal information does not change any of our commitments to safeguard your
                            privacy, and the information remains subject to any existing confidentiality obligations.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>9. &nbsp;Monitoring and moderation</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>9.1</strong>&nbsp;PwC may monitor your use of Perspectives from time to time but does not commit to doing so. Where PwC monitors, it will do so in accordance with applicable law. Perspectives is not moderated
                            (although some PwC Clients may moderate their instance of Perspectives), consequently PwC is not responsible for inappropriate or unlawful content which may appear on Perspectives and you should treat content with caution
                            and common sense. We, or the relevant PwC Client, have the right to promptly remove, edit or to disable access to, any contribution which is deemed to be potentially unlawful, in breach of any third-party rights or in breach
                            of these Terms.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>10. &nbsp;Data Hosting</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.1</strong>&nbsp;PwC may use third party cloud hosting providers to deliver Perspectives. You acknowledge and agree that any information that you or any other person input into Perspectives (including any personal
                            information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties) (together, the <strong>Data Host</strong>).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.2</strong>&nbsp;Perspectives uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately
                            managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject
                            to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an
                            overseas authority.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>10.3</strong>&nbsp;With the exception of Clauses 8.4 and 10.2, no other Perspectives Information will be stored or processed outside of Australia in the regular course of business.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>11. &nbsp;Term and Termination.</strong></p>
                        <div>
                          <p className="term-pd-1">PwC may in its absolute discretion terminate access to Perspectives under these Terms without notice.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>12. &nbsp;General</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>12.1</strong>&nbsp;Nothing in these Terms creates a joint venture, employment, partnership, agency, fiduciary or other relationship between the parties.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.2</strong>&nbsp;Neither party may assign any of its rights or obligations under these Terms, whether by operation of law or otherwise, without the other party’s prior written consent (not to be unreasonably
                            withheld).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.3</strong>&nbsp;The law applying to these Terms is the law in force in New South Wales. Both parties submit to the exclusive jurisdiction of the courts of that state and waive any right either of us may have to
                            claim that those courts do not have jurisdiction or are an inconvenient forum.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.4</strong>&nbsp;If you do not comply with these Terms and PwC does not take action immediately, this does not mean PwC is giving up any rights PwC may have (such as taking action in the future). These terms
                            contain the whole agreement between you and PwC in respect of Perspectives. Other than section 3, they do not create any third party beneficiary rights. Your use of Perspectives does not give rise to any relationship, right
                            or obligation between you and PwC that isn't agreed in these Terms. If a particular term is found to be unenforceable, this will not affect any other terms. Any term of these Terms which is wholly or partially void or
                            unenforceable is severed to the extent that it is void or unenforceable. The validity or enforceability of the remainder of these Terms is not affected.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.5</strong>&nbsp;Upon any termination, discontinuation or cancellation of Perspectives or your Registration, all provisions of these Terms which by their nature should survive will survive, including, without
                            limitation, ownership provisions, warranty disclaimers, limitations of liability, confidentiality and dispute resolution provisions.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>13. &nbsp;Definitions</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>13.1</strong>&nbsp;<strong>Perspectives</strong> means the software application or tools that together comprise the Platform which are available at the URL.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.2</strong>&nbsp;<strong>Perspectives Information</strong> means the data that you upload to or access through Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>13.3</strong>&nbsp;<strong>PwC</strong> or <strong>we</strong> means the Australian firm of PricewaterhouseCoopers, a partnership formed in Australia, which is a member firm of the PwC network of firms. Each member
                            firm in the PwC network (PwC Member Firm) is a separate legal entity. For further details, please see https://www.pwc.com/gx/en/about/corporate-governance/network-structure.html.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.4</strong>&nbsp;<strong>PwC Client</strong> may be a PwC Member Firm, or a client or clients that engaged PwC or a PwC Member Firm to provide the Services.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.5</strong>&nbsp;<strong>Services</strong> means the services provided by PwC to the PwC Client in connection with the use of Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.6</strong>&nbsp;<strong>User</strong> means any user of Perspectives.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class={
                    this.state.selectedTab === 'UK'
                      ? 'tab-pane fade show active'
                      : 'tab-pane fade'
                  } id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div className="privacy-content-container">
                      <h5 className="mt-2 mb-4"><b>Perspectives Platform (“Perspectives”) Terms of Use (“Terms”) </b></h5>
                      <p>Perspectives is a PwC-developed sentiment analysis platform that is used by our clients and their stakeholders to give people a voice and facilitate real-time insights into risks and issues.</p>
                      <p>
                        PwC has licensed Perspectives for use by the PwC Client for the purpose of requesting, responding to or viewing sentiment insights and feedback in connection with the Services that PwC is providing to the PwC Client
                        (“<strong>Purpose</strong>”).
                      </p>
                      <p>
                        By accessing Perspectives or by creating an Account, you agree to be bound by these Terms, which constitute a legally binding agreement between you and PwC. These Terms may change over time to reflect changes to Perspectives. By
                        continuing to use Perspectives after PwC makes changes to these Terms of Use, you accept those changes.
                      </p>
                      <p>BY USING PERSPECTIVES YOU CONFIRM THAT YOU HAVE READ AND UNDERSTOOD THESE TERMS AND THE PERSPECTIVES INFORMATION COLLECTION STATEMENT AND THAT YOU AGREE TO THEM AS A CONDITION OF YOUR ACCESS AND USE OF PERSPECTIVES.</p>
                      <p>&nbsp;</p>
                      <div className="terms-of-use-item">
                        <p><strong>1. &nbsp;The Platform</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>1.1</strong>&nbsp;Your use of Perspectives is to support the Purpose and not for any other purpose.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>1.2</strong>&nbsp;The availability of Perspectives at any given time is not guaranteed. PwC may suspend or withdraw your access to Perspectives or material on it, at any time, without notice and regardless of whether
                            you comply with these Terms. Perspectives may also be affected from time to time, including being unavailable or unresponsive, due to upgrades, repairs or any other maintenance.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>1.3</strong>&nbsp;We reserve the right to amend or change the Terms at any time without notice. We encourage you to periodically review these Terms to ensure you are in compliance.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>2. &nbsp;Managing Your Account</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.1</strong>&nbsp;When you create an Account, you must provide information that is complete, accurate and current. Failure to do so is a breach of these Terms, which may result in the termination of your Account.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.2</strong>&nbsp;You must always maintain control of your Account. We may, in our absolute discretion, cancel or suspend or modify any existing Account including if we reasonably form the view that a User's conduct
                            (including a breach of these Terms of Use) is detrimental to the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>2.3</strong>&nbsp;You are responsible for safeguarding the password you use to access Perspectives, and you agree to preserve the confidentiality of your password, prevent unauthorised access to or use of
                            Perspectives or any device you use to access Perspectives, and prevent disclosure of your password to any third party. You must immediately notify PwC at <a href="mailto:au_pwc_perspectives@pwc.com">au_pwc_perspectives@pwc.com</a> if anyone else obtains your Perspectives access credentials so that we can disable them and provide you with new credentials.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>3. &nbsp;Using Perspectives</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>3.1</strong>&nbsp;Your ability to use Perspectives may depend on:</p>
                          <div>
                            <p className="term-pd-2">
                              3.1.1&nbsp;
                              <span>
                                the use of web cookies or other facilitating tools or software (see PwC’s Privacy Policy available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a>);
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.1.2&nbsp;your access to compatible equipment and internet connectivity or other services, which PwC is not responsible for providing.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>3.2</strong>&nbsp;When using Perspectives, you must comply with these Terms and with applicable laws and regulations. You may use Perspectives only to upload and input information that is relevant to interacting with
                            Perspectives and that you have received as an employee, stakeholder or customer of the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.3</strong>&nbsp;You agree to use Perspectives only for the Purpose and in accordance with these Terms.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.4</strong>&nbsp;You agree to:</p>
                          <div>
                            <p className="term-pd-2">3.4.1&nbsp;keep the personal information you provide to PwC in connection with your use of Perspectives accurate, complete and up-to-date at all times;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.2&nbsp;keep your login credentials (e.g. user ID and password) secure, and notify PwC promptly if you know of or suspect any security breach or other suspicious activity;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.3&nbsp;comply with any additional reasonable responsibilities PwC communicates to you;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.4&nbsp;ensure that any Perspectives Information you upload to Perspectives is accurate and is not misleading or deceptive; and</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.4.5&nbsp;not access Perspectives if you are no longer employed or engaged by the PwC Client.</p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>3.5</strong>&nbsp;In using Perspectives, you may not:</p>
                          <div>
                            <p className="term-pd-2">3.5.1&nbsp;use Perspectives in a way prohibited by law, regulation, governmental order or decree, or for any purpose other than the Purpose;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.2&nbsp;infringe any third party’s, PwC’s or its licensor’s intellectual property rights;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.3&nbsp;decompile, decipher, disassemble, reverse engineer or otherwise attempt to discover any source code of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.4&nbsp;use Perspectives to provide services to a third party or allow any third party to either obtain a copy of Perspectives or to access or use Perspectives in any way not expressly agreed in
                              writing by PwC in advance;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.5&nbsp;copy the whole or any part of Perspectives, or frame, scrape, store, publish, transmit or distribute Perspectives, in a manner that is inconsistent with these Terms;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.6&nbsp;modify, translate, abridge or create any derivative work based on Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.7&nbsp;sell, assign, sublicense, or otherwise transfer any rights in relation to Perspectives, without PwC’s prior written consent;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.8&nbsp;use or refer to Perspectives in developing any goods or services that compete with the goods or services provided by any PwC Member Firm;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.9&nbsp;use Perspectives to spam or distribute malware, for any offensive, harassing, fraudulent or defamatory purpose, or to violate the rights of others;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.10&nbsp;post any content from Perspectives, other than PwC Client materials, on any external social networking sites;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.11&nbsp;use Perspectives to try to gain unauthorised access to or disrupt any service, device, data, account or network, or in a way that could harm Perspectives or impair anyone else’s use of them;
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.12&nbsp;breach or attempt to bypass any security measures of Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.13&nbsp;remove, obscure or modify any copyright notices or disclaimers as they appear in Perspectives;</p>
                          </div>
                          <div>
                            <p className="term-pd-2">3.5.14&nbsp;disrupt or interfere with Perspectives, the systems and infrastructure PwC uses to provide Perspectives, or any other users of Perspectives; or</p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.15&nbsp;use Perspectives in any application or situation where failure of Perspectives could lead to the death or serious bodily injury of any person, or to severe physical or environmental damage.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">
                              3.5.16&nbsp;integrate or inter-operate Perspectives with other software, hardware or data used or licensed by PwC Client or Perspectives without obtaining any necessary permits, consents or licences
                              required.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>4. &nbsp;Intellectual Property</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.1</strong>&nbsp;Perspectives is owned by PwC and has been licensed to the PwC Client for the Purpose. The visual interfaces, graphics, design, compilation, computer code, information, products, software (including
                            any downloadable software), services, and all other elements of Perspectives are provided by PwC and are protected by intellectual property laws and other applicable laws. PwC is the owner of, or has the rights to, its
                            trademarks, service marks and tradenames made available on Perspectives.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.2</strong>&nbsp;You are authorised under these Terms to use Perspectives for the Purpose. Use of Perspectives for any other purpose is prohibited. Your licence to use Perspectives can be discontinued at any time,
                            without notice and with or without cause.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>4.3</strong>&nbsp;Before you submit or share any Perspectives Information via Perspectives, you must first ensure that you have obtained any necessary rights or permissions from third parties to do so. Your use of
                            Perspectives does not give you any rights in Perspectives or any information or material made available on Perspectives. To the extent that there are any intellectual property rights in any of your Perspectives Information,
                            you grant us a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the
                            Perspectives Information throughout the world in any medium for the purposes of providing the Services, and you grant the PwC Client a worldwide, non-exclusive, royalty-free, perpetual, transferable and irrevocable licence
                            to use, reproduce, modify, adapt, translate, distribute, publish, create derivative works from and display the Perspectives Information for the purposes for which we have collected the Perspectives Information from you.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>5. &nbsp;Liability</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.1</strong>&nbsp;PwC’s total liability arising out of, or in connection with, these Terms or from the use of or inability to use Perspectives shall not exceed one hundred dollars ($100.00). Nothing in these Terms
                            excludes or limits either party’s liability for (i) fraud or wilful misconduct; (ii) breach of either party’s intellectual property rights; or (iii) any liability which cannot be excluded or limited by law.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.2</strong>&nbsp;To the extent permitted by law, no party to these Terms shall be liable for loss of profit, loss of goodwill, loss of business opportunity, loss of reputation, loss of anticipated savings or
                            benefits, loss of revenue, and loss of contracts however occurring, or indirect or consequential loss or damages.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.3</strong>&nbsp;Perspectives is not intended to be used as a system of record. Users must keep their own back-ups of its data and update them regularly. To the extent permitted by law, the PwC excludes all
                            liability for loss or corruption of data.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.4</strong>&nbsp;PwC accepts no liability or responsibility to any third party in connection with Perspectives. You agree to indemnify PwC for any liability (including reasonable legal costs) PwC may incur in
                            connection with any claim by a third party arising from your breach of these Terms.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>5.5</strong>&nbsp;The form in which PwC presents any Perspectives Information or material derived from any Perspectives Information to a PwC Client is a matter for PwC to determine in accordance with its agreement
                            with the PwC Client. PwC does not warrant or represent to you that it will present Perspectives Information to a PwC Client in a particular form or at all.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>6. &nbsp;No Warranties and Disclaimers</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>6.1</strong>&nbsp;PwC provides access to Perspectives on an “As Is” and “As Available” basis. To the extent permitted by law:</p>
                          <div>
                            <p className="term-pd-2">
                              6.1.1&nbsp;PwC makes no warranty of any kind, express or implied, in relation to Perspectives or related services provided to support the Purpose, including, but not limited to, warranties of
                              merchantability and non-infringement.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-2">6.1.2&nbsp;Perspectives does not warrant</p>
                            <p className="term-pd-3">a)&nbsp;the accuracy, completeness, currency, correctness or fitness for a particular purpose of Perspectives or related services; or</p>
                            <p className="term-pd-3">
                              b)&nbsp;that use of Perspectives or the related services will be timely, secure, uninterrupted or error free or operate in combination with any other software, hardware, data or system.
                            </p>
                          </div>
                          <div>
                            <p className="term-pd-1">
                              <strong>6.2</strong>&nbsp;If any guarantee, warranty, term or condition is implied or imposed in relation to these Terms under the Australian Consumer Law or any other applicable legislation and cannot be excluded (a <strong>Non-Excludable Provision</strong>), and PwC is able to limit the User’s remedy for a breach of the Non-Excludable Provision, then the liability of PwC for breach of the Non-Excludable Provision is limited to one
                              or more of the following at PwC’s option:
                              <p className="term-pd-2">
                                6.2.1 &nbsp;in the case of goods, the replacement of the goods or the supply of equivalent goods, the repair of the goods, the payment of the cost of replacing the goods or of acquiring equivalent
                                goods, or the payment of the cost of having the goods repaired; or
                              </p>
                              <p className="term-pd-2">6.2.2 &nbsp;in the case of services, the supplying of the services again, or the payment of the cost of having the services supplied again.</p>
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>6.3</strong>&nbsp;Any support we provide to Users in relation to use of Perspectives is provided at our discretion.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>7. &nbsp;Compliance and Conflicts of Interest</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>7.1</strong>&nbsp;The parties shall ensure that their activities in connection with these Terms will (i) be in compliance with all applicable laws and regulations relating to international corruption, fraud and
                            anti-bribery and the Modern Slavery Act 2018 (or applicable international equivalents); and (ii) PwC will comply with PwC’s respective policies which relates to the subject matter above.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>8. &nbsp;Privacy and confidentiality </strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.1</strong>&nbsp;By using Perspectives, you agree that PwC may collect and use your personal information in accordance with PwC’s Privacy Policy which is available at <a href="https://www.pwc.com.au/contact-us/privacy-policy.html" target="_blank">www.pwc.com.au/privacy</a> and the Perspectives Information Collection Statement which is available at <a href="https://projectperspectives.pwc.com/information-collection-statement" target="_blank">projectperspectives.pwc.com/information-collection-statement</a>.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.2</strong>&nbsp;You agree to keep confidential the information that you may access via Perspectives, and not to share the information with third parties. You hereby consent to PwC sharing your personal information
                            with PwC Client for the Purpose.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.3</strong>&nbsp;Your employer or our Client may include or incorporate their own terms or privacy policy that apply to your use of Perspectives and in addition to these Terms. This creates a separate agreement
                            between you and the PwC Client. We are not a party to any such agreements and any questions should be raised with the PwC Client.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.4</strong>&nbsp;PwC uses other PwC firms within the PwC global network (PwC Member Firms), third party contractors and suppliers such as hosting and security service providers located in various countries to assist
                            us with performing internal functions necessary to operate our business and to process information on our behalf. Users consent to Perspectives Information provided by Users (including personal information and Users’
                            confidential information) being transferred to those contractors and suppliers and to other PwC Member Firms and our subcontractors, so long as they are bound by reasonable confidentiality obligations.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>8.5</strong>&nbsp;We take care to ensure that PwC Member Firms and other third parties outside Australia to whom we disclose personal information are subject to appropriate restrictions on their handling of that
                            personal information. Due to differences in foreign laws and the global nature of some arrangements in place for the PwC global network, however, these restrictions may not be substantially similar to those required under
                            the Australian Privacy Principles, and the Privacy Act (including mechanisms entitling you to seek redress) may not apply. Any such disclosure of personal information does not change any of our commitments to safeguard your
                            privacy, and the information remains subject to any existing confidentiality obligations.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>9. &nbsp;Monitoring and moderation</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>9.1</strong>&nbsp;PwC may monitor your use of Perspectives from time to time but does not commit to doing so. Where PwC monitors, it will do so in accordance with applicable law. Perspectives is not moderated
                            (although some PwC Clients may moderate their instance of Perspectives), consequently PwC is not responsible for inappropriate or unlawful content which may appear on Perspectives and you should treat content with caution
                            and common sense. We, or the relevant PwC Client, have the right to promptly remove, edit or to disable access to, any contribution which is deemed to be potentially unlawful, in breach of any third-party rights or in breach
                            of these Terms.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>10. &nbsp;Data Hosting</strong></p>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.1</strong>&nbsp;PwC may use third party cloud hosting providers to deliver Perspectives. You acknowledge and agree that any information that you or any other person input into Perspectives (including any personal
                            information) will therefore be transferred to the hosting provider (who may in turn provide the information to other parties) (together, the <strong>Data Host</strong>).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>10.2</strong>&nbsp;Perspectives uses an Identity and Access Management (IAM) service from PricewaterhouseCoopers IT Services Limited (PwC IT Services) for secure authentication. The IAM service is hosted in privately
                            managed data centres located in the United States, Germany and Singapore. Users may be redirected to the IAM service as part of the PwC user account registration and authentication process. PwC IT Services may not be subject
                            to privacy obligations that are similar to the Australian Privacy Principles, and may be subject to a foreign law that could compel the disclosure of information (including personal information) to a third party, such as an
                            overseas authority.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>10.3</strong>&nbsp;With the exception of Clauses 8.4 and 10.2, no other Perspectives Information will be stored or processed outside of Australia in the regular course of business.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>11. &nbsp;Term and Termination.</strong></p>
                        <div>
                          <p className="term-pd-1">PwC may in its absolute discretion terminate access to Perspectives under these Terms without notice.</p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>12. &nbsp;General</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>12.1</strong>&nbsp;Nothing in these Terms creates a joint venture, employment, partnership, agency, fiduciary or other relationship between the parties.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.2</strong>&nbsp;Neither party may assign any of its rights or obligations under these Terms, whether by operation of law or otherwise, without the other party’s prior written consent (not to be unreasonably
                            withheld).
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.3</strong>&nbsp;The law applying to these Terms is the law in force in New South Wales. Both parties submit to the exclusive jurisdiction of the courts of that state and waive any right either of us may have to
                            claim that those courts do not have jurisdiction or are an inconvenient forum.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.4</strong>&nbsp;If you do not comply with these Terms and PwC does not take action immediately, this does not mean PwC is giving up any rights PwC may have (such as taking action in the future). These terms
                            contain the whole agreement between you and PwC in respect of Perspectives. Other than section 3, they do not create any third party beneficiary rights. Your use of Perspectives does not give rise to any relationship, right
                            or obligation between you and PwC that isn't agreed in these Terms. If a particular term is found to be unenforceable, this will not affect any other terms. Any term of these Terms which is wholly or partially void or
                            unenforceable is severed to the extent that it is void or unenforceable. The validity or enforceability of the remainder of these Terms is not affected.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>12.5</strong>&nbsp;Upon any termination, discontinuation or cancellation of Perspectives or your Registration, all provisions of these Terms which by their nature should survive will survive, including, without
                            limitation, ownership provisions, warranty disclaimers, limitations of liability, confidentiality and dispute resolution provisions.
                          </p>
                        </div>
                      </div>
                      <div className="terms-of-use-item">
                        <p><strong>13. &nbsp;Definitions</strong></p>
                        <div>
                          <p className="term-pd-1"><strong>13.1</strong>&nbsp;<strong>Perspectives</strong> means the software application or tools that together comprise the Platform which are available at the URL.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.2</strong>&nbsp;<strong>Perspectives Information</strong> means the data that you upload to or access through Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1">
                            <strong>13.3</strong>&nbsp;<strong>PwC</strong> or <strong>we</strong> means the Australian firm of PricewaterhouseCoopers, a partnership formed in Australia, which is a member firm of the PwC network of firms. Each member
                            firm in the PwC network (PwC Member Firm) is a separate legal entity. For further details, please see https://www.pwc.com/gx/en/about/corporate-governance/network-structure.html.
                          </p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.4</strong>&nbsp;<strong>PwC Client</strong> may be a PwC Member Firm, or a client or clients that engaged PwC or a PwC Member Firm to provide the Services.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.5</strong>&nbsp;<strong>Services</strong> means the services provided by PwC to the PwC Client in connection with the use of Perspectives.</p>
                        </div>
                        <div>
                          <p className="term-pd-1"><strong>13.6</strong>&nbsp;<strong>User</strong> means any user of Perspectives.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


const mapDispatchToProps = {
};
const mapStateToProps = () => ({
  brandName: 'Perspectives',
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivacyPolicy);
