import DOMPurify from 'dompurify';
import questionnaireConstant from '../modules/questionaire/actions/constants';

export default class CardHelper {
  constructor({
    currentCardIndex,
    cardIndex,
    cardTitle,
    totalCard,
    currentQuestionType,
    remainingCards,
    onLike,
    onDislike,
    fontSize,
    onSwipeRight,
    onSwipeLeft,
    charExceedLimit,
    /** Real Variable */

  }) {
    this.currentCardIndex = currentCardIndex;
    this.onLike = onLike;
    this.onDislike = onDislike;
    this.cardIndex = cardIndex;
    this.cardTitle = cardTitle;
    this.fontSize = fontSize;
    this.charExceedLimit = charExceedLimit;
    this.totalCard = totalCard;
    this.currentQuestionType = currentQuestionType;
    this.remainingCards = remainingCards;
    this.onSwipeRight = onSwipeRight;
    this.onSwipeLeft = onSwipeLeft;
    this.#init();
  }

  // private properties
  #startPoint;

  #offsetX;

  #offsetY;

  #isTouchDevice = () => (('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0))

  #init = () => {
    const card = document.createElement('div');
    card.className = `question-card c-card card-stack-${(this.remainingCards - this.cardIndex)}  active-card fade-in curren-card-index${this.cardIndex}`;
    let cardDetial = `<div class="c-data-wrapper">
      <div class="c-card-number">
          <p>${DOMPurify.sanitize(this.totalCard - this.cardIndex)}<span class="c-card-divider">/</span><span class="c-card-total-number">${DOMPurify.sanitize(this.totalCard)}</span></p>
      </div>
      <div class="c-card-data" style="font-size:${this.fontSize} !important">
      ${DOMPurify.sanitize(this.cardTitle)}
      </div>


  </div>`;

    if (this.currentQuestionType
      === questionnaireConstant.OPEN_TEXT_QUESTION_TYPE) {
      cardDetial = `<div class="c-data-wrapper">
            <div class="c-card-number">
                <p>${DOMPurify.sanitize(this.totalCard - this.cardIndex)}<span class="c-card-divider">/</span><span class="c-card-total-number">${DOMPurify.sanitize(this.totalCard)}</span></p>
            </div>
            
            <div class="c-card-data" style="font-size:${this.fontSize} !important">
            ${DOMPurify.sanitize(this.cardTitle)}
            </div>
        </div>`;
    }
    card.innerHTML = cardDetial;
    this.element = card;
    if (this.currentQuestionType
      === questionnaireConstant.YES_NO_QUESTION_TYPE) {
      if (this.#isTouchDevice()) {
        this.#listenToTouchEvents();
      } else {
        this.#listenToMouseEvents();
      }
    }
  }

  #listenToTouchEvents = () => {
    this.element.addEventListener('touchstart', (e) => {
      // alert('hiii touch start')
      const touch = e.changedTouches[0];
      if (!touch) return;
      const { clientX, clientY } = touch;
      this.#startPoint = { x: clientX, y: clientY };
      document.addEventListener('touchmove', this.#handleTouchMove);
      this.element.style.transition = 'transform 0s ease';
      // this.element.setProperty('-webkit-transition', 'transform 0s');
      e.preventDefault();
    });

    document.addEventListener('touchend', this.#handleTouchEnd);
    document.addEventListener('cancel', this.#handleTouchEnd);
  }

  #listenToMouseEvents = () => {
    this.element.addEventListener('mousedown', (e) => {
      const { clientX, clientY } = e;
      this.#startPoint = { x: clientX, y: clientY };
      document.addEventListener('mousemove', this.#handleMouseMove);
      this.element.style.transition = 'transform 0s ease';
      // this.element.setProperty('-webkit-transition', 'transform 0s ease');
    });

    document.addEventListener('mouseup', this.#handleMoveUp);

    // prevent card from being dragged
    this.element.addEventListener('dragstart', (e) => {
      e.preventDefault();
    });
  }

  #handleMove = (x) => {
    if (this.charExceedLimit) return;
    this.#offsetX = x - this.#startPoint.x;
    this.#offsetY = -100; // y - this.#startPoint.y;
    const rotate = this.#offsetX * 0.1;
    this.element.style.transform = `translate(${this.#offsetX}px, ${this.#offsetY}px) rotate(${rotate}deg)`;
    // dismiss card

    if (Math.abs(this.#offsetX) > this.element.clientWidth * 0.5) {
      this.#dismiss(this.#offsetX > 0 ? 1 : -1);
    }
  }

  // mouse event handlers
  #handleMouseMove = (e) => {
    e.preventDefault();
    if (!this.#startPoint) return;
    const { clientX } = e;
    this.#handleMove(clientX);
  }

  #handleMoveUp = () => {
    this.#startPoint = null;
    document.removeEventListener('mousemove', this.#handleMouseMove);
    this.element.style.transform = '';
  }

  // touch event handlers
  #handleTouchMove = (e) => {
    if (!this.#startPoint) return;
    const touch = e.changedTouches[0];
    // alert('hiiii touch move')
    if (!touch) return;
    const { clientX, clientY } = touch;
    this.#handleMove(clientX, clientY);
  }

  #handleTouchEnd = () => {
    this.#startPoint = null;
    document.removeEventListener('touchmove', this.#handleTouchMove);
    this.element.style.transform = '';
  }

  #dismiss = (direction) => {
    this.#startPoint = null;
    document.removeEventListener('mouseup', this.#handleMoveUp);
    document.removeEventListener('mousemove', this.#handleMouseMove);
    document.removeEventListener('touchend', this.#handleTouchEnd);
    document.removeEventListener('touchmove', this.#handleTouchMove);
    this.element.style.transition = 'transform 1s ease';
    // this.element.setProperty('-webkit-transition', 'transform 1s ease');
    this.element.style.transform = `translate(${direction * window.innerWidth}px, ${this.#offsetY}px) rotate(${90 * direction}deg)`;
    this.element.classList.add('dismissing');
    setTimeout(() => {
      this.element.remove();
    }, 1000);
    if (typeof this.onDismiss === 'function') {
      this.onDismiss();
    }
    if (typeof this.onLike === 'function' && direction === 1) {
      if (typeof this.onSwipeRight === 'function') {
        this.onSwipeRight();
      }
      setTimeout(() => {
        this.onLike();
      }, 500);
    }
    if (typeof this.onDislike === 'function' && direction === -1) {
      if (typeof this.onSwipeLeft === 'function') {
        this.onSwipeLeft();
      }
      setTimeout(() => {
        this.onDislike();
      }, 500);
    }
  }
}
