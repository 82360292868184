const getGUIDFromURL = (url) => {
  let targetObj = new URLSearchParams(url);
  let guid;
  if (targetObj.has('token')) {
    guid = targetObj.get('token');
  } else {
    const targetString = url.slice(url.lastIndexOf('/') + 1);
    targetObj = new URLSearchParams(`${targetString}`);
    guid = targetObj.has('token') ? targetObj.get('token') : '';
  }
  return guid;
};

export default getGUIDFromURL;
