const GET_PROJECTS = '/projects/questionnaire/fetch-projects';
const GET_USER_QUESTIONS = '/settings/questions/questionnaire/user-questions';
const RESPOND_QUESTIONS = '/settings/questions/questionnaire/respond-question';
const SKIP_QUESTION = '/settings/questions/questionnaire/skip-question';
const PROJECT_HAS_ANSWERED_QUESTIONS = '/settings/questions/questionnaire/is-project-has-unanswered-questions';
const PROJECT_DASHBOARD_INFO = '/settings/questions/questionnaire/project-dashboard-info';
const ALL_PROJECT_DASHBOARD_INFO = '/settings/questions/questionnaire/all-projects-dashboard-info';
const UNDO_RESPONSE = '/settings/questions/questionnaire/undo-response';
const GET_ALL_NO_AUTH_QUESTIONS = '/questionnaire/get-user-project-questions';
const NO_AUTH_RESPOND_QUESTION = '/questionnaire/respond-question';
const NO_AUTH_UNDO_RESPOND = '/questionnaire/undo-response';
const NO_AUTH_SKIP_QUESTION = '/questionnaire/respond-question';
const NO_AUTH_UPDATE_NEW_USER = '/questionnaire/update-new-user';
const CHECK_IF_SAME_USER = '/settings/users/questionnaire/check-if-same-user';
const CHECK_FIRST_TIME_USER = '/settings/questions/questionnaire/check-first-time-user';
const UPDATE_FIRST_TIME_USER = '/settings/questions/questionnaire/update-first-time-user';
const CHECK_USER_ABLE_TO_CHANGE_MODE_OPTIONS = 'settings/users/check-user-able-to-change-mode-options';


export default {
  GET_PROJECTS,
  GET_USER_QUESTIONS,
  RESPOND_QUESTIONS,
  PROJECT_HAS_ANSWERED_QUESTIONS,
  PROJECT_DASHBOARD_INFO,
  ALL_PROJECT_DASHBOARD_INFO,
  UNDO_RESPONSE,
  SKIP_QUESTION,
  GET_ALL_NO_AUTH_QUESTIONS,
  NO_AUTH_RESPOND_QUESTION,
  NO_AUTH_UNDO_RESPOND,
  NO_AUTH_SKIP_QUESTION,
  NO_AUTH_UPDATE_NEW_USER,
  CHECK_IF_SAME_USER,
  CHECK_FIRST_TIME_USER,
  UPDATE_FIRST_TIME_USER,
  CHECK_USER_ABLE_TO_CHANGE_MODE_OPTIONS,
};
