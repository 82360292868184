import axios from 'axios';
import account from '../constants/account';

class accountService {
  static signIn(authCode) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${account.SIGN_IN}`, authCode)
      .then(res => res)
      .catch(error => error.response);
  }

  static fetchCsrfToken() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${account.FETCH_CSRF_TOKEN}`)
      .then(res => res)
      .catch(error => error.response);
  }

  static isLoggedInUserSystemAdministrator() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${account.IS_LOGGED_IN_USER_SYSTEM_ADMINISTRATOR}`)
      .then(res => res)
      .catch(error => error.response);
  }

  static getLoggedInUser() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${account.GET_LOGGED_IN_USER_DETAILS}`)
      .then(res => res)
      .catch(error => error.response);
  }

  static toggleEmailNotification(notificationInfo) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${account.TOGGLE_EMAIL_NOTIFICATION}`, { notificationPreferenceInfo: notificationInfo })
      .then(res => res)
      .catch(error => error.response);
  }


  static setNewProjectNotificationForUser(id, flag) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${account.SET_NEW_PROJECT_NOTIFICATION}`, { id, flag })
      .then(res => res)
      .catch(error => error.response);
  }

  static setNewQuestionNotificationForUser(id, flag) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${account.SET_NEW_QUESTION_NOTIFICATION}`, { id, flag })
      .then(res => res)
      .catch(error => error.response);
  }

  static setOverdueQuestionNotificationForUser(id, flag) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${account.SET_OVERDUE_QUESTION_NOTIFICATION}`, { id, flag })
      .then(res => res)
      .catch(error => error.response);
  }

  static logUserSignOut(id, message) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${account.LOG_USER_SIGN_OUT}`, { id, message })
      .then(res => res)
      .catch(error => error.response);
  }

  static resetUserPassword(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${account.RESET_PASSWORD}`, data)
      .then(res => res)
      .catch(error => error.response);
  }

  static deleteMyAccount() {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${account.DELETE_MY_ACCOUNT}`)
      .then(res => res)
      .catch(err => err);
  }
}

export default accountService;
