import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import axiosReducer from './axios/axiosReducer';
import { questionnaire } from './modules/questionnaire';
import { brandwrapper } from './modules/brandwrapper';
import { csrftokenwrapper } from './modules/csrftokenwrapper';
import { notificationReducer } from './modules/common/notification';
import { orientationwrapper } from './modules/orientationwrapper';
import { login } from './modules/account';
import { overlayReducer } from './modules/common/overlay';
import { project } from './modules/project';

// Combine all the application's reducers
// The key must be router for routing
const rootReducer = history => combineReducers({
  router: connectRouter(history),
  axios: axiosReducer,
  notification: notificationReducer,
  questionnaire,
  brandwrapper,
  orientationwrapper,
  csrftokenwrapper,
  overlay: overlayReducer,
  login,
  project,
});

export default rootReducer;
