const commonConstant = {
  CSRF_PERSPECTIVES: 'perspectives',
  EXTRA_CONSTANTS: 'EXTRA_CONSTANTS',
  CSRFToken: 'CSRFToken',
  errorMessage: 'An error occurred.',
  sendMailtoStackHoldersConfirmation: 'Email sent successfully.',
  appOpeningFirstTime: 'appOpeningFirstTime',
  instructionOverlayFirstTime: 'instructionOverlayFirstTime',
  pushNotificationTag: 'pushNotificationTag',
  defaultProjectColor: '#000',
  sessionExpiredMessage401: 'You have been signed out of Perspectives, please sign in again.',
  swapDirection: {
    top: 'Top',
    left: 'Left',
    right: 'Right',
    bottom: 'Bottom',
  },
  questionType: {
    yesNo: 1,
    openText: 2,
  },
  requestType: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
  },
  responseFontSize: 19,
  questionTextSize: {
    increase: 'increase',
    decrease: 'decrease',
  },
  questionStatus: {
    ok: 'OK',
    noQuestionBacklogError: 'NoQuestionBacklogError',
  },
  maxInactiveSessionLimit: 60000,
  requestCancelError: 'Cancel',
  noInternetConnectError: 'Check your internet connection.',
  serverError: `Failed to connect to ${__CONFIG__.brandName} servers. Please try again.`,
  NetworkTimeoutError: 'One or more of your responses couldn\'t be saved due to a connection issue. These question cards will be displayed to you again.',
  optionalComment: {
    skip: 'skip',
    cardIndexIncrementValue: 1,
  },
  appInsightsErrorTitles: {
    // navigation.js
    getAllProjects: 'getProjects route in navigation.js failed with error',
    getUserInfo: 'getUserInfo route in navigation.js failed with error',
    getSpecificProjectDetails: 'getSpecificProjectDetails route in navigation.js failed with error',
    resetUserPinAttempts: 'resetUserPinAttempts route in navigation.js failed with error',
    getPushNotificationTag: 'getPushNotificationTag route in navigation.js failed with error',
    removeUserPushNotificationToken: 'removeUserPushNotificationToken route in navigation.js failed with error',
    deletePushNotificationTag: 'deletePushNotificationTag route in navigation.js failed with error',
    projectsHasUnasweredQuestions: 'isProjectsHasUnasweredQuestions route in navigation.js failed with error',

    // LoginPinScreen.js
    pinRegistered: 'isPinRegistered route in LoginPinScreen.js failed with error',
    userPinAttempts: 'getUserPinAttempts route in LoginPinScreen.js failed with error',
    getProjectsLoginPinScreen: 'getProjects route in LoginPinScreen.js failed with error',
    pinScreenSignIn: 'signIn route in LoginPinScreen.js failed with error',
    pinScreenResetPinAttempts: 'resetUserPinAttempts route in LoginPinScreen.js failed with error',
    pinScreenRemoveUserPin: 'removeUserPin route in LoginPinScreen.js failed with error',
    pinScreenRefreshToken: 'getRefreshToken route in LoginPinScreen.js failed with error',
    pinScreenProjectsHasUnasweredQuestions: 'isProjectsHasUnasweredQuestions route in LoginPinScreen.js failed with error',
    pinScreenSignOut: 'signOut route in LoginPinScreen.js failed with error',
    pinScreenloginToIDAM: 'loginToIDAM route in LoginPinScreen.js failed with error',
    pinScreenOnAuthenticated: 'loginToIDAM route in LoginPinScreen.js failed with error',

    // RegisterPinScreen.js
    registerPinScreenGetProjects: 'getProjects route in RegisterPinScreen.js failed with error',
    registerPinScreenProjectsHasUnasweredQuestions: 'isProjectsHasUnasweredQuestions route in RegisterPinScreen.js failed with error',
    registerPinScreensetUserPin: 'setUserPin route in RegisterPinScreen.js failed with error',

    // SignInScreen.js
    signInGetTempEmail: 'getTempEmail route in SignInScreen.js failed with error',
    signInScreenLoginToIDAM: 'loginToIDAM route in SignInScreen.js failed with error',
    registerPinScreenProjectsHasQuestions: 'isProjectsHasUnasweredQuestions route in SignInScreen.js failed with error',

    // splashScreen.js
    splashScreenOnAuthenticate: 'onAuthenticate route in SignInScreen.js failed with error',

    // DashboardScreen.js
    allProjectsDashboardData: 'getAllProjectsDashboardData route in DashboardScreen.js failed with error',

    // HomeScreen.js
    userQuestions: 'getUserQuestions route in HomeScreen.js failed with error',
    respondQuestions: 'respondQuestions route in HomeScreen.js failed with error',
    skipQuestion: 'skipQuestion route in HomeScreen.js failed with error',
    undoResponse: 'undoResponse route in HomeScreen.js failed with error',

    // ProjectDashboardScreen.js
    projectDashboardData: 'getProjectDashboardData route in ProjectDashboardScreen.js failed with error',
  },
  optionalCommentLimit: 250,
};

export default commonConstant;
