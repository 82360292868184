import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from './rootReducers';

export const history = createBrowserHistory();

// Redux DevTools Extension for Chrome and Firefox
/* eslint-disable no-underscore-dangle */
const reduxDevTool = () => (typeof window === 'object'
  && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : f => f);
/* eslint-enable no-underscore-dangle */

// Configure thunk, routerMiddleware
// Creating store with reducer, middleware and app's initial state
export default function configureStore(initialState) {
  const middleware = applyMiddleware(thunk, routerMiddleware(history));

  const composedStoreEnhancer = compose(
    middleware,
    reduxDevTool(),
  );

  const store = createStore(
    createRootReducer(history),
    initialState,
    composedStoreEnhancer,
  );

  if (module.hot) {
    module.hot.accept('./rootReducers', () => {
      store.replaceReducer(createRootReducer);
    });
  }

  return store;
}
