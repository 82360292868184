import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FooterContent } from '../../../ui-components/PageContent';
import ProgressCard from './components/ProgressCard';
import SideDrawer from './components/SideDrawer';
import { showHideOverlay } from '../../common/overlay/actions/overlay';
import HamburgerSvg from './components/svg/HamburgerSvg';
import OverlayBackdrop from '../../common/containers/OverlayBackdrop/OverlayBackdrop';
import {
  getAllProjectsDashboardData,
  changeSelectedProject,
  isProjectsHasUnasweredQuestions,
} from '../../project/actions/project';
import accountConstant from '../../account/actions/constant';
import projectConstant from '../../project/constant/constant';
import RenderIf from '../../../ui-components/Common';
import { MobileViewLoader } from '../../../ui-components/Loader';
import notify from '../../common/notification/actions';


class AllTopics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userCardScore: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    sessionStorage.setItem(projectConstant.selectedProjectId, '');
    this.getAllProjectsDashboardData();
    this.props.changeSelectedProject({ selectedProjectId: null });
    this.props.showHideOverlay(false);
  }

  handleDrawerToggle = () => {
    this.props.showHideOverlay(true);
  };

  getAllProjectsDashboardData = () => {
          this.props.getAllProjectsDashboardData(null)
            .then((res) => {
              if (res && res.data) {
                this.setState({ isLoading: false });
                this.setState({
                  userCardScore: Array.isArray(res.data?.questionResponse?.data)
                    ? res.data?.questionResponse?.data : [],
                });
              } else if (res && res.response && res.response.status === 401) {
                this.props.show401Error();
              }
            })
            .catch(() => {
            });
        }

  onSelectedTiles = async (cardDetails) => {
    sessionStorage.setItem(projectConstant.selectedProjectId, cardDetails.projectId);
    await this.props.changeSelectedProject({
      selectedProjectId: cardDetails.projectId,
      selectedProjectName: cardDetails.projectName,
      projectColor: cardDetails.userScoreColor,
    });
    this.props.history.push(accountConstant.personalDashboard);
  }

  render() {
    const { isDrawerOpen } = this.props;
    return (
      <React.Fragment>
        <MobileViewLoader isLoading={this.state.isLoading} />
        <RenderIf showComponent={!this.state.isLoading}>
          <OverlayBackdrop />
          <SideDrawer
            isOpen={isDrawerOpen}
          />
          <div class="d-block">
            <div class="bg-orange-img">
               <div className="drawer">
                <span className={`hamburger ${isDrawerOpen ? 'open' : ''}`} onClick={this.handleDrawerToggle}>
                    <HamburgerSvg />
                </span>
              </div>
              <span class="bg-title mb-resp">All Topics</span>
            </div>
            <React.Fragment>
              <div className='main-content'>
                <div className='content-wrap' style={{ marginBottom: '150px' }}>
                  <div className='container'>
                    <div className='row justify-content-center card-topic-container' style={{}}>
                      <RenderIf showComponent={this.state.userCardScore.length > 0}>
                        {this.state.userCardScore.map(cardDetails => (
                          <>
                            <RenderIf
                              showComponent={cardDetails.userScore !== null
                              }>
                              <div className="progress-card" style={{}}
                                onClick={() => this.onSelectedTiles(cardDetails)}
                                key={`${cardDetails.projectName}_${cardDetails.projectId}`}
                              >
                                <ProgressCard title={cardDetails.projectName}
                                  percentage={cardDetails.userScore}
                                  isShowTileScore={cardDetails.isShowTileScore}
                                  isQuestionAvailable={cardDetails.isQuestionAvailable}
                                  psDashboardShow={cardDetails.psDashboardShow}
                                  showInsightScreen={!cardDetails.isAnonymityCriteriaMet}
                                />
                              </div>
                            </RenderIf>
                          </>
                        ))}
                      </RenderIf>
                      <RenderIf showComponent={this.state.userCardScore.length === 0}>
                        <div>
                          Insights are not enabled for any of your current active topics
                        </div>
                      </RenderIf>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </RenderIf>

        <footer class="footer-btm">
          <FooterContent></FooterContent>
        </footer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  showOverlay: state.overlay.showOverlay,
  isDrawerOpen: state.overlay.isDrawerOpen,
  selectedProject: state.project.selectedProject,
  show401Error: notify.show401Error,
});

const mapDispatchToProps = {
  showHideOverlay,
  getAllProjectsDashboardData,
  changeSelectedProject,
  isProjectsHasUnasweredQuestions,
  show401Error: notify.show401Error,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllTopics));
