import projectService from '../../../redux/services/projectService';
import { actionType } from '../../../redux/modules/project';
import { actionType as qustionnaireActonType } from '../../../redux/modules/questionnaire';
// import projectConstant from '../constant/constant';

export function getProjects() {
  return () => projectService
    .getProjects()
    .then(response => response)
    .catch(error => error);
}

export function setProjectInfo(projects) {
  return dispatch => dispatch({ type: actionType.SET_PROJECT_INFO, projects });
}

export function setSideDrawerProjectList(projects) {
  return dispatch => dispatch({ type: actionType.SET_SIDE_DRAWER_PROJECT_LIST, projects });
}

export function changeSelectedProject(project) {
  return dispatch => dispatch({ type: actionType.SELECT_PROJECT, project });
}

export function getProjectDashboardData(selectedProject) {
  return () => projectService
    .getProjectDashboardData(selectedProject)
    .then(response => response)
    .catch(error => error);
}

export function getAllProjectsDashboardData() {
  return () => projectService
    .getAllProjectsDashboardData()
    .then(response => response)
    .catch(error => error);
}

export function getUserQuestions(selectedProject) {
  return dispatch => projectService
    .getUserQuestions(selectedProject)
    .then((res) => {
      const questionsData = {
        allQuestions: res.data.reverse(),
        currentCardIndex: 1,
        totalCards: res.data.length,
      };
      dispatch({ type: qustionnaireActonType.SET_USER_PROJECT_QUESTIONNAIRE, questionsData });
    });
}

export function isProjectsHasUnasweredQuestions(selectedProject) {
  return () => projectService
    .isProjectsHasUnasweredQuestions(selectedProject)
    .then(response => response)
    .catch(error => error);
}

export function respondQuestions(responseData, QuestionUserId, props) {
  return dispatch => projectService
    .respondQuestions(responseData)
    .then((res) => {
      let questions = [];
      if (res.data) {
        questions = props.userQuestionsData.filter(item => item.QuestionUserId !== QuestionUserId);
        const questionsData = {
          allQuestions: questions,
          currentCardIndex: props.currentCardIndex + 1,
        };
        dispatch({ type: qustionnaireActonType.RESPOND_USER_PROJECT_QUESTIONNAIRE, questionsData });
      } else {
        window.location.reload(true);
      }
    }).catch(error => error);
}

export function skipQuestion(responseData, QuestionUserId, props) {
  return dispatch => projectService
    .skipQuestion(responseData)
    .then((res) => {
      let questions = [];
      if (res.data) {
        questions = props.userQuestionsData.filter(item => item.QuestionUserId !== QuestionUserId);
        const questionsData = {
          allQuestions: questions,
          currentCardIndex: props.currentCardIndex + 1,
        };
        dispatch({ type: qustionnaireActonType.RESPOND_USER_PROJECT_QUESTIONNAIRE, questionsData });
      } else {
        window.location.reload(true);
      }
    })
    .catch(error => error);
}

export function undoResponse(questionInfo, props, lastCard, lastQuestionType) {
  return dispatch => projectService
    .undoResponse(questionInfo)
    .then((res) => {
      let questions = [];
      if (res.data) {
        questions = props.userQuestionsData;
        const questionsData = {
          allQuestions: questions,
          currentCardIndex: props.currentCardIndex !== 1
            ? props.currentCardIndex - 1
            : props.currentCardIndex,
          lastCard,
          lastQuestionType,

        };
        dispatch({ type: qustionnaireActonType.UNDO_RESPONSE_QUESTION, questionsData });
      } else {
        window.location.reload(true);
      }
    });
}

export function setSelectedProjectTitle(selectedProjectName) {
  return dispatch => dispatch({ type: actionType.SET_SELECTED_PROJECT_TITLE, selectedProjectName });
}

export function checkDeepLinkUser(token) {
  return () => projectService
    .checkDeepLinkUser(token)
    .then(response => response)
    .catch(error => error);
}

export function checkFirstTimeUser() {
  return () => projectService
    .checkFirstTimeUser()
    .then(response => response)
    .catch(error => error);
}

export function updateFirstTimeUser() {
  return () => projectService
    .updateFirstTimeUser()
    .then(response => response)
    .catch(error => error);
}

export function isUserAbleToChangeAppModeOptions() {
  return dispatch => projectService
    .checkUserAbleToChangeModeOptions()
    .then((res) => {
      if (res.data !== undefined) {
        dispatch({ type: actionType.USER_ACCESSIBLE_ROLE, data: res.data });
      }
      return res;
    })
    .catch({});
}
