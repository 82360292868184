const csrfConstants = {
  requestType: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
  },
  httpStatus: {
    Ok: 200,
    NotAuthenticated: 401,
    Forbidden: 403,
    NotFound: 404,
  },
};

export default csrfConstants;
