import React from 'react';
import './AlertModel.css';

class AlertModel extends React.Component {
  render() {
    return (
      <div class="alert-modal">
        <div class="alert-modal-content">
          <span class="alert-close" onClick={() => this.props.onCloseAlertHandler()}>&times;</span>
          <p className='m-0'>{this.props.modelText}</p>
        </div>
      </div>
    );
  }
}

export default AlertModel;
