import React from 'react';
import moment from 'moment';
import ReactSvgLineChart from 'react-svg-line-chart';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from 'swiper';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import {
//   LineChart, Line, Tooltip,
// } from 'recharts';
import RenderIf from '../../../../ui-components/Common';
 // eslint-disable-next-line
import 'swiper/scss';
// eslint-disable-next-line
import 'swiper/scss/pagination';

import 'react-circular-progressbar/dist/styles.css';
import InsightGraph from '../../../../../assets/images/insight-graph.png';
import constant from '../../../project/constant/constant';
import './Tooltip.css';


class YourSatisfactionActive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePoint: null,
      activePosition: null,
    };
  }

  // spark trend line
  handlePointHover = (activePoint, e) => {
    if (this.props.projectData.trendData && this.props.projectData.trendData.length > 0
      && e != null && activePoint != null) {
      this.setState({
        activePoint,
        activePosition: {
          x: e.currentTarget.getAttribute('cx'),
          y: e.currentTarget.getAttribute('cy'),
          date: activePoint.date,
          score: activePoint.y,
        },
      });
    }
  }

  hideTooltip = () => {
    this.setState({
      activePosition: null,
    });
  }

  setTooltipPosition = (elId, element) => {
    const el = document.getElementById(elId);
    const winWidth = window.innerWidth;
    if (element === constant.tooltipArea.attribute) {
      const elLeftDistance = el.getBoundingClientRect().left + 240;
      const elPosition = Math.ceil(winWidth - elLeftDistance);
      // eslint-disable-next-line
      elPosition < 300
        ? this.setState({ tooltipPosition: 'left' })
        : this.setState({ tooltipPosition: 'right' });
    }
    if (element === constant.tooltipArea.domain) {
      const elLeftDistance = el.getBoundingClientRect().left + 18;
      const elPosition = Math.ceil(winWidth - elLeftDistance);
      // eslint-disable-next-line
      elPosition < 200
        ? this.setState({ tooltipPosition: 'left' })
        : this.setState({ tooltipPosition: 'right' });
    }
  }

  render() {
    const {
      trendDays,
      userScore,
      userScoreColor,
      selectedProjectMeasuring,
      userPerformanceByScore,
      showTrendLines,
      trendDataSet,
      trendData,
      showTrendDifference,
      trendPerformancePositive,
      trendDifference,
      periodLength,
      trendStartDate,
    } = this.props.projectData;
    const { activePosition } = this.state;
    // eslint-disable-next-line max-len
    // const chartData = trendData.map(tdata => ({ value: tdata.value, date: moment(tdata.date).format('dddd, D MMMM YYYY') }));
    const svgChartData = trendData.map((tdata, index) => ({
      ...tdata, x: index + 1, y: tdata.value, date: moment(tdata.date).format('dddd, D MMMM YYYY'),
    }));


    return (
      <React.Fragment>
        <div className='content graph-content'>
          <h2 className='name'>Your {selectedProjectMeasuring}</h2>

          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <React.Fragment>
                {trendDays !== undefined && trendDays === 0 && <div>
                  Today
                </div>
                }
                {trendDays !== undefined && trendDays === 1 && <div>
                  over the past {trendDays} weekday
                </div>
                }
                {trendDays !== undefined && trendDays > 1 && <div>
                  over the past {trendDays} weekdays
                </div>
                }
                {userScore >= 0 && <div className='circular-bar'>
                  <CircularProgressbar
                    value={userScore}
                    text={userScore === 0 ? '0' : userScore}
                    styles={buildStyles({

                      /* Whether to use rounded or flat corners
                      on the ends - can use 'butt' or 'round' */
                      strokeLinecap: 'butt',

                      // Text size
                      textSize: '16px',

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      pathTransition: 'none',

                      // Colors
                      pathColor: userScoreColor,
                      textColor: userScoreColor,
                      trailColor: '#ffffff',
                      backgroundColor: '#ffffff',
                    })}
                  />
                </div>}
                {userScore < 0
                    && <div className='noResults'>
                      <div className='noResultsLine'>

                      </div>
                    </div>
                }
                {userScore >= 0 && <p className='svgCircleMainValueInfo'
                  style={{ color: userScoreColor }}
                >
                  {userPerformanceByScore}
                </p>
                }
              </React.Fragment>
            </SwiperSlide>
            {showTrendLines && <SwiperSlide>
              <div className='projectsSubTitle'>
                  since {trendStartDate}
              </div>
              <div style={{
                width: '300px', height: '100px', margin: '0 auto', marginTop: '30px',
              }} className={`graph-line
                       ${!showTrendLines ? ' blur-graph' : ''}`}
              onMouseLeave={this.hideTooltip}>
                {activePosition && showTrendLines
                            && <span className="graph-tooltip"
                              style={{
                                left: `${activePosition.x - 20}px`,
                                top: `${parseFloat(activePosition.y) + 20}px`,
                              }}
                            >{Math.round(activePosition.score)}%  <br />{activePosition.date}
                            </span>
                }
                <RenderIf showComponent={trendDataSet.length >= 2}>
                  <ReactSvgLineChart
                    data={svgChartData}
                    pointsOnHover={this.handlePointHover}
                    axisWidth={0}
                    gridWidth={0}
                    labelsVisible={false}
                    pathColor={!showTrendLines ? 'grey' : '#fa5928'}
                    pathVisible={true}
                    pointsColor={!showTrendLines ? 'grey' : '#fa5928'}
                    pointsRadius={3}
                    labelsCountY={20}
                    labelsStepX={1}
                    pointsStrokeColor={!showTrendLines ? 'grey' : '#fa5928'}
                    pointsStrokeWidth={2}
                    pointsVisible={true}
                    viewBoxHeight={50}
                    viewBoxWidth={250}
                  />
                </RenderIf>
                <RenderIf showComponent={trendDataSet.length < 2}>
                  <img className="d-block w-100" src={InsightGraph} alt="First slide" />
                </RenderIf>
                <RenderIf showComponent={showTrendDifference}>
                  <div className='trendlineTextContainer'>
                    <i className={`fa fa-sharp fa-solid ${trendPerformancePositive ? 'fa-caret-up' : 'fa-caret-down'}`} style={{ color: trendPerformancePositive ? '#66bb6a' : '#e01300' }}></i>
                    <span
                      style={{
                        marginLeft: '8px',
                        color: trendPerformancePositive
                          ? '#66bb6a'
                          : '#e01300',
                        marginRight: '5px',
                      }}
                    >
                      {trendDifference}%
                    </span>
                    <span className='trendlineText'>
                        change in past {periodLength / 2} days
                    </span>
                  </div>
                </RenderIf>
              </div>
            </SwiperSlide>}
          </Swiper>
        </div>
      </React.Fragment>
    );
  }
}

export default YourSatisfactionActive;
