import actionType from './actionType';

// Configured initial state for Brand
const initialState = {
  orientationname: 'false',
};

// Creating project reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
  case actionType.SET_ORIENTATION_DATA:
    return {
      ...state,
      orientationname: action.data,
    };
  default:
    return state;
  }
};
