// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* The Modal (background) */
.alert-modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    padding-top: 10px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .alert-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    margin-right: 20px;
  }
  
  /* The Close Button */
  .alert-close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-top: -9px;
  }
  
  .alert-close:hover,
  .alert-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/app/ui-components/AlertModel/AlertModel.css"],"names":[],"mappings":";AACA,2BAA2B;AAC3B;IACI,cAAc,EAAE,sBAAsB;IACtC,eAAe,EAAE,kBAAkB;IACnC,UAAU,EAAE,eAAe;IAC3B,iBAAiB,EAAE,wBAAwB;IAC3C,OAAO;IACP,MAAM;IACN,WAAW,EAAE,eAAe;IAC5B,YAAY,EAAE,gBAAgB;IAC9B,cAAc,EAAE,4BAA4B;IAC5C,4BAA4B,EAAE,mBAAmB;IACjD,iCAAiC,EAAE,qBAAqB;EAC1D;;EAEA,kBAAkB;EAClB;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,kBAAkB;EACpB;;EAEA,qBAAqB;EACrB;IACE,cAAc;IACd,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;;IAEE,WAAW;IACX,qBAAqB;IACrB,eAAe;EACjB","sourcesContent":["\n/* The Modal (background) */\n.alert-modal {\n    display: block; /* Hidden by default */\n    position: fixed; /* Stay in place */\n    z-index: 3; /* Sit on top */\n    padding-top: 10px; /* Location of the box */\n    left: 0;\n    top: 0;\n    width: 100%; /* Full width */\n    height: 100%; /* Full height */\n    overflow: auto; /* Enable scroll if needed */\n    background-color: rgb(0,0,0); /* Fallback color */\n    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */\n  }\n  \n  /* Modal Content */\n  .alert-modal-content {\n    background-color: #fefefe;\n    margin: auto;\n    padding: 20px;\n    border: 1px solid #888;\n    width: 60%;\n    margin-right: 20px;\n  }\n  \n  /* The Close Button */\n  .alert-close {\n    color: #aaaaaa;\n    float: right;\n    font-size: 28px;\n    font-weight: bold;\n    margin-top: -9px;\n  }\n  \n  .alert-close:hover,\n  .alert-close:focus {\n    color: #000;\n    text-decoration: none;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
