import actionType from './actionType';

// Configured initial state for axios
const initialState = {
  userQuestionsData: [],
  isValidGuid: null,
  currentCardIndex: 1,
  totalCards: 0,
  currentCard: {},
  currentQuestionType: 0,
  successfulClearBacklog: false,
  isNewUser: false,
  isSpinnerLoading: true,
  completionMessage: '',
  isMobileDashboardEnabled: false,
  measuringMessage: 'Confidence',
  isWebDashboardEnabled: false,
  questionPolarity: false,
  lastCardId: 0,
  lastCard: {},
  lastQuestionType: null,
  guid: '',
};

// Creating axios reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
  case actionType.SET_USER_PROJECT_QUESTIONNAIRE: {
    return {
      ...state,
      userQuestionsData: action.questionsData.allQuestions,
      userResponse: action.questionsData.userResponse,
      isValidGuid: action.questionsData.isValidGuid,
      isNewUser: action.questionsData.isNewUser,
      currentCardIndex: action.questionsData.currentCardIndex,
      totalCards: action.questionsData.totalCards,
      currentCard: action.questionsData.allQuestions[
        (action.questionsData.allQuestions.length - 1)],
      currentQuestionType: action.questionsData.allQuestions.length > 0
        ? (action.questionsData.allQuestions[action.questionsData.allQuestions.length - 1].type
    || action.questionsData.allQuestions[action.questionsData.allQuestions.length - 1].QuestionType
        )
        : 0,
      successfulClearBacklog: action.questionsData.isValidGuid
          && action.questionsData.allQuestions.length === 0,
      successfulClearBacklogAuthMode: action.questionsData.allQuestions.length === 0,
      isSpinnerLoading: action.questionsData.isSpinnerLoading,
      completionMessage: action.questionsData.completionMessage,
      isMobileDashboardEnabled: action.questionsData.isMobileDashboardEnabled,
      isWebDashboardEnabled: action.questionsData.isWebDashboardEnabled,
      measuringMessage: action.questionsData.measuringMessage,
      questionPolarity: action.questionsData.allQuestions.length > 0
        ? (action.questionsData.allQuestions[action.questionsData.allQuestions.length - 1]
          .is_inverted
             || action.questionsData.allQuestions[action.questionsData.allQuestions.length - 1]
               .QuestionPolarity)
        : false,
      guid: action.questionsData.guid,
    };
  }
  case actionType.RESPOND_USER_PROJECT_QUESTIONNAIRE: {
    return {
      ...state,
      userQuestionsData: action.questionsData.allQuestions,
      currentCardIndex: action.questionsData.currentCardIndex,
      currentCard: action.questionsData.allQuestions[
        (action.questionsData.allQuestions.length - 1)],
      currentQuestionType: action.questionsData.allQuestions.length > 0
        ? (action.questionsData.allQuestions[action.questionsData.allQuestions.length - 1].type
    || action.questionsData.allQuestions[action.questionsData.allQuestions.length - 1].QuestionType)
        : 0,
      successfulClearBacklog: state.isValidGuid && action.questionsData.allQuestions.length === 0,
      successfulClearBacklogAuthMode: action.questionsData.allQuestions.length === 0,
      questionPolarity: action.questionsData.allQuestions.length > 0
        ? (action.questionsData.allQuestions[action.questionsData.allQuestions.length - 1]
          .is_inverted
            || action.questionsData.allQuestions[action.questionsData.allQuestions.length - 1]
              .QuestionPolarity)
        : false,
    };
  }

  case actionType.SET_FIRST_TIME_USER_FALSE: {
    return {
      ...state,
      isNewUser: action.isNewUser,
    };
  }

  case actionType.UNDO_RESPONSE_QUESTION: {
    // eslint-disable-next-line max-len
    action.questionsData.allQuestions[action.questionsData.allQuestions.length] = action.questionsData.lastCard;
    return {
      ...state,
      userQuestionsData: action.questionsData.allQuestions,
      currentCardIndex: action.questionsData.currentCardIndex,
      currentCard: action.questionsData.lastCard,
      questionPolarity: (action.questionsData.lastCard.is_inverted
           || action.questionsData.allQuestions[action.questionsData.allQuestions.length - 1]
             .QuestionPolarity),
      successfulClearBacklog: false,
      successfulClearBacklogAuthMode: false,
      currentQuestionType: action.questionsData.lastQuestionType,
    };
  }

  case actionType.UPDATE_LAST_QUESTION_CARD_ID: {
    return {
      ...state,
      lastCardId: action.lastCardId,
      lastCard: action.lastCard,
      lastQuestionType: action.lastQuestionType,
    };
  }

  default:
    return state;
  }
};
