import React, { Component } from 'react';
import { connect } from 'react-redux';
import loader from '../../../assets/images/loader.png';
import pwcLogo from '../../../assets/images/group-7.svg';

class MobileViewLoader extends Component {
  render() {
    const { isLoading, brandName } = this.props;

    return (
      <React.Fragment>
        {isLoading && (
          <div className="mobile-download-page-wrapper fade-out-mobile-loader">
            <div class="container text-center">
              <div class="loader-logo">
                <img src={pwcLogo}/>
                <div className="logo-text">{brandName}</div>
              </div>
              <div class="loader">
                <img src={loader} />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
});


export default connect(
  mapStateToProps,
  null,
)(MobileViewLoader);
