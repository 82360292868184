// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setCSRFToken, fetchCsrfToken } from '../actions/csrftokenwrapper';

const CSRFTokenWrapper = (props) => {
  useEffect(async () => {
    const { csrfToken } = props;
    if (!csrfToken) {
      await props.fetchCsrfToken().then(async (res) => {
        const { csrfToken: token } = res.data;
        if (token) {
          await props.setCSRFToken(token);
        }
      });
    }
  }, []);
  return ('');
};

const mapStateToProps = state => ({
  csrfToken: state.csrftokenwrapper.csrfToken,
});

const mapDispatchToProps = {
  setCSRFToken,
  fetchCsrfToken,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CSRFTokenWrapper);
