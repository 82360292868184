const VALIDATE_USER_BY_EMAIL = 'user/external/validate-user-by-email';
const CREATE_NEW_USER = 'user/external/create-new-user';
const CREATE_NEW_PORTFOLIO_ADMINISTRATOR = 'user/external/create-new-portfolio-administrator';
const CREATE_NEW_PORTFOLIO_STAKEHOLDER = 'user/external/create-new-portfolio-stakeholder';
const CREATE_NEW_PROJECT_ADMINISTRATOR = 'user/external/create-new-project-administrator';
const CREATE_NEW_PROJECT_STAKEHOLDER = 'user/external/create-new-project-stakeholder';
const UPDATE_USER_NAME = 'user/external/update-user-name';
const UPDATE_MOBILE_NUMBER = 'user/external/update-mobile-number';
const CHANGE_MOBILE_NUMBER = 'user/external/change-mobile-number';
const REGISTER_USER = 'user/external/register-user';
const IS_USER_ALREADY_REGISTERED = 'user/external/is-user-already-registered';
const VALIDATE_ON_DEMAND_USER_BY_EMAIL = 'user/external/validate-on-demand-user-by-email';
const RESEND_ACCOUNT_ACTIVATION_EMAIL = 'user/external/resend-account-activation-link';
const CHECK_USER_RESTRICTED_TO_LOGIN = 'user/external/check-user-restricted-to-login';

export default {
  VALIDATE_USER_BY_EMAIL,
  CREATE_NEW_USER,
  CREATE_NEW_PORTFOLIO_ADMINISTRATOR,
  CREATE_NEW_PORTFOLIO_STAKEHOLDER,
  CREATE_NEW_PROJECT_ADMINISTRATOR,
  CREATE_NEW_PROJECT_STAKEHOLDER,
  UPDATE_USER_NAME,
  UPDATE_MOBILE_NUMBER,
  CHANGE_MOBILE_NUMBER,
  REGISTER_USER,
  IS_USER_ALREADY_REGISTERED,
  VALIDATE_ON_DEMAND_USER_BY_EMAIL,
  RESEND_ACCOUNT_ACTIVATION_EMAIL,
  CHECK_USER_RESTRICTED_TO_LOGIN,
};
